import React, { useState, useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import "./Styles.css";

const Meter = (props) => {
  const Value = !isNaN(props.value) ? props.value : 0;

  return (
    <>
      {Value >= 0 && (
        <ReactSpeedometer
          needleTransitionDuration={5000}
          needleHeightRatio={0.8}
          needleTransition="easeBackOut"
          width={props.width}
          height={props.height}
          value={Value}
          minValue={0}
          maxValue={
            props.maxValue ? props.maxValue : Value > 1000 ? 50000 : 10000
          }
          maxSegmentLabels={5}
          segments={5}
          needleColor="black"
          segmentColors={["green", "#388E3C", "#66BB6A", "#E53935", "#C30000"]}
          className="speedometer"
        />
      )}
    </>
  );
};

export default Meter;
