import React from "react";

const Food = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={require('../Assets/Save Food.png')} height={500} width={500}  alt="savefood"/>
    </div>
  );
};

export default Food;
