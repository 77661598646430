import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";

import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";

import { Link } from "react-router-dom";

import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M15_METER_NAME,
  M18_METER_NAME,
  M20_METER_NAME,
  M21_METER_NAME,
  M58_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    // height: "700px",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const PaintShopReports = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);
  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=3&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for m4fr and m5fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      console.log("response--", response);
      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const getTotalProcessData = (array1, array2) => {
    const arrayLength = Math.max(array1?.length, array2?.length);
    while (array1?.length < arrayLength) {
      array1.push(array1[array1.length - 1]);
    }

    while (array2?.length < arrayLength) {
      array2.push(array2[array2.length - 1]);
    }
    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      combinedArray.push(array1[i] + array2[i]);
    }
    return combinedArray;
  };

  const getAddition = (value1, value2) => {
    console.log("value1 , value2", value1, value2);
    const v1 = isNaN(Number(value1)) ? 0 : Number(value1);
    const v2 = isNaN(Number(value2)) ? 0 : Number(value2);
    const total = (v1 + v2).toFixed();
    return total;
  };

  const customDateData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M21FR),
      volume: getCustomVolume(graphData?.M21),
      labels: getCustomLabels(volChartData?.data?.M21),
      data:
        volChartData?.data?.M21 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M21),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M15FR?.toFixed(2) + graphData?.M17FR?.toFixed(2)) / 2
      ),
      volume: getAddition(
        getCustomVolume(graphData?.M15),
        getCustomVolume(graphData?.M58)
      ),
      labels: getCustomLabels(volChartData?.data?.M15),
      data: getTotalProcessData(
        volChartData?.data?.M15
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M15)
          : [],
        volChartData?.data?.M58
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M58)
          : []
      ),
    },
    {
      meter: 21,
      title: `${TML_METER_NUMBERS.M21} : ${M21_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M21FR?.toFixed(2)),
      volume: getCustomVolume(graphData?.M21),
      labels: getCustomLabels(volChartData?.data?.M21),
      data:
        volChartData?.data?.M21 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M21),
    },
    {
      meter: 15,
      title: `${TML_METER_NUMBERS.M15} : ${M15_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M15FR),
      volume: getCustomVolume(graphData?.M15),
      labels: getCustomLabels(volChartData?.data?.M15),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M15 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M15),
      // labels: label,
      // data: volChartData?.data?.M15.map((item) => item?.M15TVDifference),
    },
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getCustomVolume(graphData?.M18),
      labels: getCustomLabels(volChartData?.data?.M18),
      data:
        volChartData?.data?.M18 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M18),
    },
    {
      meter: 58,
      title: `${TML_METER_NUMBERS.M58} : ${M58_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M58FR),
      volume: getCustomVolume(graphData?.M58),
      labels: getCustomLabels(volChartData?.data?.M58),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M58 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M58),
    },
    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getCustomVolume(graphData?.M20),
      labels: getCustomLabels(volChartData?.data?.M20),
      data:
        volChartData?.data?.M20 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M20),
    },
  ];

  const defaultData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M21FR),
      volume: getDefaultVolume(
        graphData?.M21,
        volChartData?.data?.M21,
        "M21TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M21),
      data: volChartData?.data?.M21?.map((item) => item?.M21TV),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M15FR?.toFixed(2) + graphData?.M15FR?.toFixed(2)) / 2
      ),
      volume: getAddition(
        getDefaultVolume(graphData?.M15, volChartData?.data?.M15, "M15TV"),
        getDefaultVolume(graphData?.M58, volChartData?.data?.M58, "M58TV")
      ),
      labels: getDefaultLabels(volChartData?.data?.M15),
      data: getTotalProcessData(
        volChartData?.data?.M15?.map((item) => item?.M15TV),
        volChartData?.data?.M58?.map((item) => item?.M58TV)
      ),
    },
    {
      meter: 21,
      title: `${TML_METER_NUMBERS.M21} : ${M21_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M21FR),
      volume: getDefaultVolume(
        graphData?.M21,
        volChartData?.data?.M21,
        "M21TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M21),
      data: volChartData?.data?.M21?.map((item) => item?.M21TV),
    },

    {
      meter: 15,
      title: `${TML_METER_NUMBERS.M15} : ${M15_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M15FR),
      volume: getDefaultVolume(
        graphData?.M15,
        volChartData?.data?.M15,
        "M15TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M15),
      data: volChartData?.data?.M15.map((item) => item?.M15TV),
    },
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getDefaultVolume(
        graphData?.M18,
        volChartData?.data?.M18,
        "M18TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M18),
      data: volChartData?.data?.M18.map((item) => item?.M18TV),
    },
    {
      meter: 58,
      title: `${TML_METER_NUMBERS.M58} : ${M58_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M58FR),
      volume: getDefaultVolume(
        graphData?.M58,
        volChartData?.data?.M58,
        "M58TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M58),
      data: volChartData?.data?.M58?.map((item) => item?.M58TV),
    },
    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getDefaultVolume(
        graphData?.M20,
        volChartData?.data?.M20,
        "M20TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M20),
      data: volChartData?.data?.M20?.map((item) => item?.M20TV),
    },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = Data[0]?.data;
  const array2 = Data[1]?.data;

  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength

  while (array1?.length < maxLength) {
    array1.push(0);
  }

  while (array2?.length < maxLength) {
    array2.push(0);
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const v1 = isNaN(Number(array1[i])) ? 0 : Number(array1[i]);
    const v2 = isNaN(Number(array2[i])) ? 0 : Number(array2[i]);
    const total = v1 + v2;
    combinedArray.push(total);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Paint Shop">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Paint Shop Total</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? [
                                    "00:00",
                                    "06:00",
                                    "12:00",
                                    "18:00",
                                    "23:59",
                                    "00:01",
                                  ]
                                : getCustomLabels(volChartData?.data?.M21)
                            }
                            Data={combinedArray}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {getAddition(Data[0]?.volume, Data[1]?.volume)}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>
              {Data.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={Math.abs(item.flowRate)}
                                maxValue={
                                  Math.abs(item.flowRate) > 1000 ? 50000 : 10000
                                }
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>
                              {!isNaN(Math.abs(item.flowRate))
                                ? Math.abs(item.flowRate)
                                : 0}
                            </div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item?.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>

            <AxisTrendAndTabularReport shopNo={3} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default PaintShopReports;
