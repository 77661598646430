// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    width: 100vw;
    height: 42px;
    background: #FFF;
    box-shadow: 0px -3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerText{
    color: var(--grey-01, #5B5B5B);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mFAAmF;IACnF,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;AAClC,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB","sourcesContent":[".footer{\n    width: 100vw;\n    height: 42px;\n    background: #FFF;\n    box-shadow: 0px -3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.footerText{\n    color: var(--grey-01, #5B5B5B);\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
