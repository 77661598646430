import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ToastContext from "../../Context/ToastContext";
import MultiAxisChartFilter from "./MultiAxisChartFilter";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "center",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 15,
      },
    },
    title: {
      display: false,
      text: "Multi Axis",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  layout: {
    padding: {
      bottom: 50,
      left: 10,
      top: 0,
      right: 10,
    },
  },
};

const MultiaxisPowerTrain = () => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const { toast } = useContext(ToastContext);
  const [MapData, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [dateError, setDateError] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const fetchData = useCallback(async () => {
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than curren date");
    }
    setDateError(false);
    let url = `${URL}getShopwiseMetersData?shopNo=7&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      console.log("res---", res);
      setData(res?.data);
    } catch (err) {
      if (err.response.data.message === "Unauthorized") setAuthorized(false);
      console.log(err);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    (dateFilter.startdate.length && dateFilter.enddate.length);

  const label1 =
    MapData?.data?.M27[0]?.D &&
    MapData?.data?.M27.map((item) => {
      const dateObj = new Date(item?.D);
      return format(dateObj, "dd/MM");
    });

  const label2 =
    MapData?.data?.M27[0]?.T &&
    MapData?.data?.M27.map((item) => {
      const timeString = item?.T;
      return format(new Date(`1970-01-01T${timeString}`), "HH:mm");
    });

  const customDateData = {
    labels: label1,
    datasets: [
      {
        label: "Engine Shop Drinking Water",
        data: MapData?.data?.M27?.map((item) => item?.M27TVDifference),
        borderColor: "#6B5A6E",
        backgroundColor: "#6B5A6E",
        yAxisID: "y",
      },
      {
        label: "Omega PDI Drinking",
        data: MapData?.data?.M42?.map((item) => item?.M42TVDifference),
        borderColor: "#3d872e",
        backgroundColor: "#3d872e",
        yAxisID: "y1",
      },
      {
        label: "Scanning Post Drinking Water",
        data: MapData?.data?.M63?.map((item) => item?.M63TVDifference),
        borderColor: "#d4d41c",
        backgroundColor: "#d4d41c",
        yAxisID: "y",
      },
      {
        label: "Scrap Yard Drinking",
        data: MapData?.data?.M49?.map((item) => item?.M49TVDifference),
        borderColor: "#FF0000",
        backgroundColor: "#FF0000",
        yAxisID: "y1",
      },
      {
        label: "Engine Shop Process Water ",
        data: MapData?.data?.M40?.map((item) => item?.M40TVDifference),
        borderColor: "#4AA9D9",
        backgroundColor: "#4AA9D9",
        yAxisID: "y",
      },
      {
        label: "Solar System 01 Process",
        data: MapData?.data?.M31?.map((item) => item?.M31TVDifference),
        borderColor: "#6020e8",
        backgroundColor: "#6020e8",
        yAxisID: "y",
      },
      {
        label: "Solar System 02 Process",
        data: MapData?.data?.M45?.map((item) => item?.M45TVDifference),
        borderColor: "#9e0616",
        backgroundColor: "#9e0616",
        yAxisID: "y",
      },
    ],
  };

  const defaultData = {
    labels: label2,
    datasets: [
      {
        label: "Engine Shop Drinking Water",
        data: MapData?.data?.M27?.map((item) => item?.M27TV),
        borderColor: "#6B5A6E",
        backgroundColor: "#6B5A6E",
        yAxisID: "y1",
      },
      {
        label: "Omega PDI Drinking",
        data: MapData?.data?.M42?.map((item) => item?.M42TV),
        borderColor: "#3d872e",
        backgroundColor: "#3d872e",
        yAxisID: "y1",
      },
      {
        label: "Scanning Post Drinking Water",
        data: MapData?.data?.M63?.map((item) => item?.M63TV),
        borderColor: "#d4d41c",
        backgroundColor: "#d4d41c",
        yAxisID: "y1",
      },
      {
        label: "Scrap Yard Drinking",
        data: MapData?.data?.M49?.map((item) => item?.M49TV),
        borderColor: "#FF0000",
        backgroundColor: "#FF0000",
        yAxisID: "y1",
      },
      {
        label: "Engine Shop Process Water ",
        data: MapData?.data?.M40?.map((item) => item?.M40TV),
        borderColor: "#4AA9D9",
        backgroundColor: "#4AA9D9",
        yAxisID: "y1",
      },
      {
        label: "Solar System 01 Process",
        data: MapData?.data?.M31.map((item) => item?.M31TV),
        borderColor: "#6020e8",
        backgroundColor: "#6020e8",
        yAxisID: "y1",
      },
      {
        label: "Solar System 02 Process",
        data: MapData?.data?.M45.map((item) => item?.M45TV),
        borderColor: "#9e0616",
        backgroundColor: "#9e0616",
        yAxisID: "y1",
      },
    ],
  };
  const handleCSVDownload = () => {
    if (MapData?.data) {
      const csvData = MapData.data.M27.map((item, index) => {
        let DateTime;
        if (setTrue === 0) {
          DateTime = item.T;
        } else {
          DateTime = item.D;
        }
        const rowData = {
          DateTime,
          "Engine Shop Drinking ": MapData.data.M27[index]?.M27TV || 0,
          "Omega PDI Drinking": MapData.data.M42[index]?.M42TV || 0,
          "Scanning Post Drinking Water": MapData.data.M63[index]?.M63TV || 0,
          "Scarpyard Drinking": MapData.data.M49[index]?.M49TV || 0,
          "Engine Shop Process Water": MapData.data.M33[index]?.M33TV || 0,
          "Solar System 01 Process": MapData.data.M35[index]?.M35TV || 0,
          "Solar System 02 Process": MapData.data.M45[index]?.M45TV || 0,
        };

        if (setTrue !== 0) {
          rowData["Engine Shop Drinking "] =
            MapData.data.M27[index]?.M27TVDifference || 0;
          rowData["Omega PDI Drinking"] =
            MapData.data.M42[index]?.M42TVDifference || 0;
          rowData["Scanning Post Drinking Water"] =
            MapData.data.M63[index]?.M63TVDifference || 0;
          rowData["Scarpyard Drinking"] =
            MapData.data.M49[index]?.M49TVDifference || 0;
          rowData["Engine Shop Process Water"] =
            MapData.data.M40[index]?.M40TVDifference || 0;
          rowData["Solar System 01 Process"] =
            MapData.data.M31[index]?.M31TVDifference || 0;
          rowData["Solar System 02 Process"] =
            MapData.data.M45[index]?.M45TVDifference || 0;
        }
        return rowData;
      });

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });

      saveAs(csvBlob, "Data.csv");
      toast.success("Data downloading, please wait...");
    }
  };

  const handlePDFDownload = async () => {
    const headers = [
      setTrue === 0 ? " Time      " : " Date       ",
      "Engine Shop Drinking     ",
      "Omega PDI Drinking             ",
      "Scanning Post Drinking Water         ",
      "Scrap yard Drinking           ",
      "Engine Shop Process Water           ",
      "Solar System 01 Process           ",
      "Solar System 02 Process           ",
    ];

    const pdfData = MapData?.data;

    if (pdfData?.M27 && pdfData?.M49) {
      const rows = [];

      if (setTrue === 0) {
        for (let i = 0; i < pdfData.M27.length; i++) {
          const timeWithSeconds = pdfData.M27[i]?.T;
          const time = timeWithSeconds?.split(":")?.slice(0, 2)?.join(":");
          const row = [
            time,
            pdfData.M27[i]?.M27TV?.toFixed(),
            pdfData.M42[i]?.M42TV?.toFixed(),
            pdfData.M63[i]?.M63TV?.toFixed(),
            pdfData.M49[i]?.M49TV?.toFixed(),
            pdfData.M40[i]?.M40TV?.toFixed(),
            pdfData.M31[i]?.M31TV?.toFixed(),
            pdfData.M45[i]?.M45TV?.toFixed(),
          ];

          rows.push(row);
        }
      } else {
        for (let i = 0; i < pdfData.M27.length; i++) {
          const date = format(new Date(pdfData.M27[i]?.D), "dd/MM/yyyy");
          const row = [
            date,
            pdfData.M27[i]?.M27TVDifference?.toFixed(),
            pdfData.M42[i]?.M42TVDifference?.toFixed(),
            pdfData.M63[i]?.M63TVDifference?.toFixed(),
            pdfData.M49[i]?.M49TVDifference?.toFixed(),
            pdfData.M40[i]?.M40TVDifference?.toFixed(),
            pdfData.M31[i]?.M31TVDifference?.toFixed(),
            pdfData.M45[i]?.M45TVDifference?.toFixed(),
          ];
          rows.push(row);
        }
      }

      const date = new Date().toLocaleString();
      const doc = new jsPDF("l", "pt", "letter");

      let y = 10;
      doc.setLineWidth(2);
      doc.text(350, (y += 30), "Report");

      const chart = document.getElementById("lineChart");
      chart.style.backgroundColor = "#F9F9F9";
      chart.style.paddingTop = "40px";
      chart.style.borderRadius = "10px";

      const canvas = await html2canvas(chart);
      const imgData = canvas.toDataURL("image/jpeg");
      doc.addImage(
        imgData,
        "JPEG",
        40,
        (y += 20),
        doc.internal.pageSize.getWidth() - 70,
        250
      );
      doc.setFontSize(8);
      doc.text(`Downloaded at: ${date}`, 170, 22, null, null, "right");
      const columnStyles = {};

      // Center-align content for all columns
      for (let i = 0; i < headers.length; i++) {
        columnStyles[i] = { halign: "center" };
      }

      doc.autoTable({
        head: [headers],
        body: rows,
        startY: 340,
        theme: "grid",
        tableWidth: "100%",
        headStyles: {
          fontSize: 8,
          halign: "center",
          rowHeight: 25,
          margin: { bottom: 20 },
        },
        columnStyles: columnStyles,
      });

      doc.save("Data.pdf");
      toast.success("Data downloading, please wait...");
    } else {
      console.error("pdfData.M23 or pdfData.M61 is undefined");
    }
  };

  // ... (rest of the code)

  const DataDownload = async () => {
    if (!authorized) {
      return toast.error("Unauthorized");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than current date");
    }
    if (dateError) {
      return toast.error("Data not available for download");
    }
    handleCSVDownload();
    await handlePDFDownload();
    const chart = document.getElementById("lineChart");
    chart.style.backgroundColor = "";
    chart.style.paddingTop = "";
    chart.style.borderRadius = "";
  };
  return (
    <>
      <div style={{ margin: "auto" }}>
        <MultiAxisChartFilter
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          fetchData={fetchData}
          DataDownload={DataDownload}
        />
        {MapData?.data ? (
          <Line
            id="lineChart"
            options={options}
            data={setTrue === 0 ? defaultData : customDateData}
            height={400}
            width={1350}
          />
        ) : (
          <Line
            id="lineChart"
            options={options}
            data={customDateData}
            height={400}
            width={1350}
          />
        )}
      </div>
    </>
  );
};
export default MultiaxisPowerTrain;
