import React from "react";
import { Bar, Line } from "react-chartjs-2";

const LineChart = ({ chartData, suggestedMaxProps }) => {
  const options = {
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 8,
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        display: true,
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },

        // stepSize: 2,
        //beginAtZero: true,
      },
    },
  };
  return <Line data={chartData} options={options} />;
};

export default LineChart;
