import React from "react";
import "./BillTable.css";
import {
  M18_METER_NAME,
  M20_METER_NAME,
  M29_METER_NAME,
  M38_METER_NAME,
  M43_METER_NAME,
  M55_METER_NAME,
} from "../../Reports/utils/Constants";

const getFormattedNumber = (input) => {
  return isNaN(Number(input)) || Number(input) < 0
    ? "--"
    : Math.round(Number(input));
};

const BillTable = ({ data, shopname }) => {
  console.log("shop name in table comp", shopname, data);
  return (
    <div>
      {" "}
      <table id="reportTable">
        <thead>
          {shopname === "Car Plant" && (
            <tr>
              <th>Date</th>
              <th>MIDC To PH NO-04 Drinking</th>
              <th>MIDC To PH NO-04 Process</th>
              <th>Total Fresh Water</th>
              <th>ETP | RO Transfer</th>
            </tr>
          )}
          {shopname === "Press Shop" && (
            <tr>
              <th>Date</th>
              <th>Press Shop Drinking Water</th>
              <th>Press Shop Process Water</th>
              <th>Total </th>
            </tr>
          )}

          {shopname == "Nexon BIW" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>Weld Shop Drinking Water</th>
              <th>Weld Shop 'A' Grid Process Water</th>
              <th>Weld Shop 'K' Grid Process</th>
              <th>Total</th>
            </tr>
          )}

          {shopname == "Paint Shop" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>
                Paint Shop <br /> Canteen-01
              </th>
              <th>
                Paint Shop <br /> Canteen-02
              </th>
              <th>
                Paint Shop <br /> Drinking Water <br /> Line
              </th>
              <th>
                Paint Shop <br /> 'L' Grid Process
              </th>
              <th>
                Paint Shop <br /> 'O' Grid Process
              </th>
              <th>Process Total</th>
              <th>Total</th>
            </tr>
          )}
          {shopname == "TCF1" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>
                TCF 1 Drinking <br /> Water line{" "}
              </th>
              <th>
                TCF 2 Drinking <br /> Water
              </th>
              <th>Drinking Total</th>
              <th>
                TCF 1A Process <br /> Water Line
              </th>
              <th>
                TCF 1B Process <br /> Water Line
              </th>
              <th>
                TCF 1C Process <br /> Water Line
              </th>
              <th>Process Total</th>
              <th>Total</th>
            </tr>
          )}
          {shopname == "TCF2" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>TCF 2 Drinking Water</th>
              <th>TCF 2 Process Water</th>
              <th>Total</th>
            </tr>
          )}
          {shopname == "Powertrain" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>
                Engine Shop <br />
                Drinking Water
              </th>
              <th>
                Omega
                <br /> PDI <br />
                Drinking
              </th>
              <th>
                Scanning Post <br /> Drinking Water
              </th>
              <th>Scrap Yard Drinking</th>
              <th>
                Total <br />
                Drinking
              </th>
              <th>
                Engine Shop <br /> Process Water
              </th>
              <th>Solar System 01 Process</th>
              <th>Solar System 02 Process</th>
              <th>
                Total <br />
                Process
              </th>
              <th>Total</th>
            </tr>
          )}
          {shopname == "Canteen1" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>Canteen 1 Drinking</th>
            </tr>
          )}
          {shopname == "Canteen2" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>Canteen 2 Drinking</th>
            </tr>
          )}
          {shopname == "ETP" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>ETP | RO Transfer</th>
            </tr>
          )}
          {shopname == "JLR" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>Drinking Water Supply To JLR - (L-Block)</th>
            </tr>
          )}
          {shopname == "Canteen K Block" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>{M18_METER_NAME}</th>
              <th>{M20_METER_NAME}</th>
              <th>{M29_METER_NAME}</th>
              <th>{M55_METER_NAME}</th>
              <th>{M38_METER_NAME}</th>
              <th>{M43_METER_NAME}</th>
              <th>Total</th>
            </tr>
          )}
          {shopname === "Compressor House" && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>Compressor Room Process</th>
              <th>Compressor House Process</th>
              <th>Total</th>
            </tr>
          )}
          {(shopname === "AltrozBiw" ||
            shopname === "NovaBiw" ||
            shopname === "Harrier Safari" ||
            shopname === "Canteen J Block") && (
            <tr className="FirstRow">
              <th>Date</th>
              <th>
                Drinking Water Supply <br /> To J Block
              </th>
            </tr>
          )}
        </thead>

        {/* data rows started here */}
        {shopname === "Car Plant" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["MIDC_Fresh_Water_1"])}</td>
              <td>{getFormattedNumber(item["MIDC_Fresh_Water_2"])}</td>
              <td>{getFormattedNumber(item["Total_Fresh_Water"])}</td>
              <td>{getFormattedNumber(item["RO_Transfer"])}</td>
            </tr>
          ))}
        {shopname === "Press Shop" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["Press_Shop_Drinking"])}</td>
              <td>{getFormattedNumber(item["Press_Shop_Process"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "Nexon BIW" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td> {getFormattedNumber(item["Weld_Shop_Drinking"])}</td>
              <td>{getFormattedNumber(item["A_Grid_Process"])}</td>
              <td> {getFormattedNumber(item["K_Grid_Process"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "Paint Shop" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>

              <td>{getFormattedNumber(item["Canteen1_Drinking"])}</td>
              <td>{getFormattedNumber(item["Canteen2_Drinking"])}</td>
              <td>{getFormattedNumber(item["Paint_Shop_Drinking"])}</td>
              <td>{getFormattedNumber(item["L_Grid_Process"])}</td>
              <td>{getFormattedNumber(item["O_Grid_Process"])}</td>
              <td>{getFormattedNumber(item["Process_Total"])}</td>
              <td>{getFormattedNumber(Number(item["Total"]))}</td>
            </tr>
          ))}
        {shopname === "TCF1" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["TCF_1_Drinking"])}</td>
              <td>{getFormattedNumber(item["TCF_2_Drinking"])}</td>
              <td>{getFormattedNumber(item["Drinking_Total"])}</td>
              <td>{getFormattedNumber(item["Process_1"])}</td>
              <td>{getFormattedNumber(item["Process_2"])}</td>
              <td>{getFormattedNumber(item["Process_3"])}</td>
              <td>{getFormattedNumber(item["Process_Total"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "TCF2" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["TCF_2_Drinking"])}</td>
              <td>{getFormattedNumber(item["TCF_2_Process"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "Powertrain" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["Engine_Shop_Drinking"])}</td>
              <td>{getFormattedNumber(item["Omega_PDI_Drinking"])}</td>
              <td>{getFormattedNumber(item["Parking_Area_Drinking"])}</td>
              <td>{getFormattedNumber(item["Scarpyard_Drinking"])}</td>
              <td>{getFormattedNumber(item["Total_Drinking"])}</td>
              <td>{getFormattedNumber(item["Main_Engine_Line"])}</td>
              <td>{getFormattedNumber(item["PDI_Solar_1_Process"])}</td>
              <td>{getFormattedNumber(item["PDI_Solar_2_Process"])}</td>
              <td>{getFormattedNumber(item["Total_Process"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "Canteen1" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>
                {Number(item["Canteen1_Drinking"])?.toFixed(2) > 0
                  ? Number(item["Canteen1_Drinking"])?.toFixed(2)
                  : 0}
              </td>
            </tr>
          ))}
        {shopname === "Canteen2" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>
                {Number(item["Canteen2_Drinking"])?.toFixed(2) > 0
                  ? Number(item["Canteen2_Drinking"])?.toFixed(2)
                  : 0}
              </td>
            </tr>
          ))}
        {shopname === "ETP" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["RO_Transfer"])}</td>
            </tr>
          ))}
        {shopname === "JLR" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["JLR_Block_Drinking"])}</td>
            </tr>
          ))}
        {shopname === "Canteen K Block" &&
          data?.map((item, index) => (
            <tr key={index}>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["Canteen1_Drinking"])}</td>
              <td>{getFormattedNumber(item["Canteen2_Drinking"])}</td>
              <td>{getFormattedNumber(item["OB_Canteen_MORI_Drinking"])}</td>
              <td>{getFormattedNumber(item["Omega_PDI_Canteen"])}</td>
              <td>{getFormattedNumber(item["Tcf1_Canteen_Mori_Drinking"])}</td>
              <td>{getFormattedNumber(item["JLR_Canteen_Drinking"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {shopname === "Compressor House" &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["Compressor_Room"])}</td>
              <td>{getFormattedNumber(item["Compressor_House"])}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
        {(shopname === "AltrozBiw" ||
          shopname === "NovaBiw" ||
          shopname === "Harrier Safari" ||
          shopname === "Canteen J Block") &&
          data?.map((item, index) => (
            <tr>
              <td>{item?.D}</td>
              <td>{getFormattedNumber(item["Total"])}</td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default BillTable;
