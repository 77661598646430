import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
});

const style = {
  btnUpload: {
    color: "#375EAD",
    backgroundColor: "#ffffff",
    borderColor: "#375EAD",
    fontWeight: "bold",
    borderRadius: 5,
  },
  modal: {
    borderRadius: "10px",
    height: "230px",
  },
};

const UploadFile = () => {
  const [file, setFile] = useState(null);
  const URL = process.env.REACT_APP_URL;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // setApiError(null);
    if (
      selectedFile &&
      selectedFile.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(null);
      setApiError("Please select a valid Excel file (xlsx) only.");
    } else {
      setFile(selectedFile);
      setApiError(null);
    }
  };

  const handleUpload = async () => {
    if (file) {
      setShowModal(true);
    } else {
      alert("Please select a file first.");
    }
  };

  const handleConfirm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const correctPassword = "123";

      if (formData.password === correctPassword) {
        setShowModal(false);
        setErrors({}); // Reset errors state
        const formData = new FormData();
        formData.append("file", file);

        try {
          await axios.post(`${URL}/upload`, formData);
          alert("File uploaded successfully!");
          navigate("/electricity");
        } catch (error) {
          console.log(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(
              "Server responded with an error status",
              error.response.status,
              error.response.data
            );
            // Update the state with the API error message
            setApiError(
              error.response.data.error ||
                "An error occurred while processing your request."
            );
          }
        }

        setFormData({ ...formData, password: "" });
      } else {
        setErrors({ password: "Incorrect password. Please try again." });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((e) => {
        validationErrors[e.path] = e.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleDirectToElectricity = () => {
    navigate("/electricity");
  };

  return (
    <div style={{ margin: 20, alignSelf: "center" }}>
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <input
              className={`form-control form-control-lg`}
              id="formFileLg"
              type="file"
              onChange={handleFileChange}
            />
            {/* <br></br> */}
            {apiError && (
              <div
                style={{ color: "red", marginTop: "10px", marginLeft: "156px" }}
              >
                {apiError}
              </div>
            )}
          </div>
          <div
            style={{
              marginLeft: "5%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div style={{ marginRight: "1vw" }}>
              <button
                onClick={handleUpload}
                className="btn btn-outline-primary btn-lg"
                style={style.btnUpload}
              >
                Upload
              </button>
            </div>
            <div>
              <button
                onClick={handleDirectToElectricity}
                className="btn btn-outline-primary btn-lg btn btn-backroundcolor-sucess"
                style={style.btnUpload}
              >
                Electricity dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ height: "250px", borderRadius: "20px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter The Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            placeholder="Enter the password"
            className={`form-control form-control-lg`}
            id="password"
            type="password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />{" "}
          {errors.password && (
            <div style={{ color: "red" }}>{errors.password}</div>
          )}
          <div style={{ display: "flex", float: "right", marginTop: "20px" }}>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            &nbsp;&nbsp;
            <Button variant="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadFile;
