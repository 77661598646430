import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Col, Container, Row, Button } from "react-bootstrap";

import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";

import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";
import { ReportTable1 } from "./ReportTable1";
import MultiAxisChartsPressShop from "./Components/MultiAxisChartsPressShop";
import { Link, useParams } from "react-router-dom";
import MultiAxisCommon from "./Components/MultiAxisCommon";
import { TableReports } from "./TableReports";

import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M23_METER_NAME,
  M61_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    // height: "700px",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const PressShopReports = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const { shopname } = useParams();
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=1&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  ///10-lates_press_shop

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for M23fr and M61fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr-data_press_shop?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      // console.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const customDateData = [
    {
      meter: 23,
      title: `${TML_METER_NUMBERS.M23} : ${M23_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M23FR),
      volume: getCustomVolume(graphData?.M23),
      labels: getCustomLabels(volChartData?.data?.M23),
      data:
        volChartData?.data?.M23 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M23),
    },
    {
      meter: 61,
      title: `${TML_METER_NUMBERS.M61} : ${M61_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M61FR),
      volume: getCustomVolume(graphData?.M61),
      labels: getCustomLabels(volChartData?.data?.M61),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M61 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M61),
    },
  ];

  const defaultData = [
    {
      meter: 23,
      title: `${TML_METER_NUMBERS.M23} : ${M23_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M23FR),
      volume: getDefaultVolume(
        graphData?.M23,
        volChartData?.data?.M23,
        "M23TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M23),
      data: volChartData?.data?.M23?.map((item) => item?.M23TV),
    },
    {
      meter: 61,
      title: `${TML_METER_NUMBERS.M61} : ${M61_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M61FR),
      volume: getDefaultVolume(
        graphData?.M61,
        volChartData?.data?.M61,
        "M61TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M61),
      data: volChartData?.data?.M61?.map((item) => item?.M61TV) || 0,
    },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = volChartData?.data?.M23;
  const array2 = volChartData?.data?.M61;
  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength

  while (array1?.length < maxLength) {
    array1.push({ M23TV: null, M23TVDifference: null });
  }

  while (array2?.length < maxLength) {
    array2.push({ M61TV: null, M61TVDifference: null });
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const combinedItem = {
      defaultTotal: Math.abs(array1[i].M23TV) + array2[i].M61TV,
      customTotal:
        Math.abs(array1[i].M23TVDifference) + array2[i].M61TVDifference,
    };
    combinedArray.push(combinedItem);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Press Shop">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Press Shop Total </div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />

                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? getDefaultLabels(volChartData?.data?.M23)
                                : getCustomLabels(volChartData?.data?.M23)
                            }
                            Data={combinedArray?.map((item) =>
                              setTrue === 0
                                ? item?.defaultTotal
                                : !isNaN(item?.customTotal)
                                ? item?.customTotal
                                : 0
                            )}
                          />
                          <div style={styles.totalVolDiv}>
                            <div style={styles.totalVolDiv}>
                              {setTrue === 0
                                ? defaultData
                                    .reduce((accumulator, currentItem) => {
                                      return (
                                        accumulator +
                                        (isNaN(Number(currentItem.volume)) ||
                                        Number(currentItem.volume) < 0
                                          ? 0
                                          : Number(currentItem.volume))
                                      );
                                    }, 0)
                                    .toFixed()
                                : customDateData
                                    .reduce((accumulator, currentItem) => {
                                      return (
                                        accumulator +
                                        (isNaN(Number(currentItem.volume)) ||
                                        Number(currentItem.volume) < 0
                                          ? 0
                                          : Number(currentItem.volume))
                                      );
                                    }, 0)
                                    .toFixed()}
                            </div>
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data?.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={Math.abs(item.flowRate)}
                                maxValue={
                                  Math.abs(item.flowRate) > 1000 ? 50000 : 10000
                                }
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>
                              {!isNaN(Math.abs(item.flowRate))
                                ? Math.abs(item.flowRate)
                                : 0}
                            </div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item?.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>
            <AxisTrendAndTabularReport shopNo={1} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default PressShopReports;
