import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const MultiAxisLineChart = (props) => {
  const { data, height, width, titleX, titleY, stepSize } = props;
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 10,
        },
      },
      title: {
        display: false,
        text: "Multi Axis",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          text: titleX,
          color: "#0F0F0F",
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        stacked: true,
        position: "left",
        stepSize: stepSize,
        beginAtZero: true,
        title: {
          display: true,
          text: titleY,
          color: "#0F0F0F",
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
   
    },
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },
  };
  return (
    <>
      <div style={{ margin: "auto" }}>
        {props?.data && (
          <Chart options={options} data={data} height={height} width={width} />
        )}
      </div>
    </>
  );
};

export default MultiAxisLineChart;
