// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 14px;
  
  }

  th{
    background-color: rgb(231, 231, 231);
    font-size: 18px;
  }


.FirstRow th{
border-top: none;
}

.LastRow td{
  border-bottom: none;
  }

  .dropdown-toggle {
    background-color: #fff;
    color: #000; /* Set the text color */
    border: 1px solid #ced4da; /* Set the border color */
  }`, "",{"version":3,"sources":["webpack://./src/Billing/BillingTable.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;;EAEf;;EAEA;IACE,oCAAoC;IACpC,eAAe;EACjB;;;AAGF;AACA,gBAAgB;AAChB;;AAEA;EACE,mBAAmB;EACnB;;EAEA;IACE,sBAAsB;IACtB,WAAW,EAAE,uBAAuB;IACpC,yBAAyB,EAAE,yBAAyB;EACtD","sourcesContent":["table {\n    font-family: arial, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n    height: 100%;\n  }\n  \n  td, th {\n    border: 1px solid #dddddd;\n    text-align: center;\n    padding: 14px;\n  \n  }\n\n  th{\n    background-color: rgb(231, 231, 231);\n    font-size: 18px;\n  }\n\n\n.FirstRow th{\nborder-top: none;\n}\n\n.LastRow td{\n  border-bottom: none;\n  }\n\n  .dropdown-toggle {\n    background-color: #fff;\n    color: #000; /* Set the text color */\n    border: 1px solid #ced4da; /* Set the border color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
