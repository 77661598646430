import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReportTable } from "./ReportTable";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import MultiAxisLineChart from "./Components/MultiAxisLineChart";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getAddition,
  getFormattedNumber,
} from "./utils/Lib";
import { ReportTable1 } from "./ReportTable1";
import { Link } from "react-router-dom";
import MultiAxisLineChartTCF1 from "./Components/MultiAxisLineChartTCF1";
import { TableReports } from "./TableReports";
import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M32_METER_NAME,
  M34_METER_NAME,
  M35_METER_NAME,
  M36_METER_NAME,
  M39_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const Tcf1ShopReports = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=4&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for M39fr and M39fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr-data?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      // setAvgFlowRate(response?.data?.data[0].M39FR);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error(err?.response?.data?.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const getTotalDrinkingVolume = (m39Val, m32Val) => {
    const m39FormattedNumber = isNaN(Number(m39Val)) ? 0 : Number(m39Val);
    const m32FormattedNumber = isNaN(Number(m32Val)) ? 0 : Number(m32Val);
    return (m39FormattedNumber - m32FormattedNumber).toFixed();
  };

  const getTotalDrinkingData = (m32Array, m39Array) => {
    const arrayLength = Math.max(m32Array?.length, m39Array?.length);
    while (m32Array?.length < arrayLength) {
      m32Array.push(0);
    }

    while (m39Array?.length < arrayLength) {
      m39Array.push(m39Array.length > 0 ? m39Array[m39Array.length - 1] : 0);
    }
    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      const m39FormattedNumber = isNaN(Number(m39Array[i]))
        ? 0
        : Number(m39Array[i]);
      const m32FormattedNumber = isNaN(Number(m32Array[i]))
        ? 0
        : Number(m32Array[i]);
      combinedArray.push(m39FormattedNumber - m32FormattedNumber);
    }
    // console.log("combinedArray", combinedArray);
    return combinedArray;
  };

  const getTotalProcessVolume = (m34Vol, m35Vol, m36Vol) => {
    const m34FormattedNumber = isNaN(Number(m34Vol)) ? 0 : Number(m34Vol);
    const m35FormattedNumber = isNaN(Number(m35Vol)) ? 0 : Number(m35Vol);
    const m36FormattedNumber = isNaN(Number(m36Vol)) ? 0 : Number(m36Vol);
    return (
      m34FormattedNumber +
      m35FormattedNumber +
      m36FormattedNumber
    ).toFixed();
  };

  const getTotalProcessData = (m34Array, m35Array, m36Array) => {
    const arrayLength = Math.max(
      m34Array?.length,
      m35Array?.length,
      m36Array?.length
    );
    while (m34Array?.length < arrayLength) {
      m34Array.push(m34Array.length > 0 ? m34Array[m34Array.length - 1] : 0);
    }

    while (m35Array?.length < arrayLength) {
      m35Array.push(m35Array.length > 0 ? m35Array[m35Array.length - 1] : 0);
    }
    while (m36Array?.length < arrayLength) {
      m36Array.push(m36Array.length > 0 ? m36Array[m36Array.length - 1] : 0);
    }

    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      const m34FormattedNumber = isNaN(Number(m34Array[i]))
        ? 0
        : Number(m34Array[i]);
      const m35FormattedNumber = isNaN(Number(m35Array[i]))
        ? 0
        : Number(m35Array[i]);
      const m36FormattedNumber = isNaN(Number(m36Array[i]))
        ? 0
        : Number(m36Array[i]);
      combinedArray.push(
        m34FormattedNumber + m35FormattedNumber + m36FormattedNumber
      );
    }
    // console.log("combinedArray", combinedArray);
    return combinedArray;
  };

  const customDateData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(
        (graphData?.M39FR?.toFixed(2) ||
          0 + graphData?.M39FR?.toFixed(2) ||
          0) / 2
      ),
      volume: getTotalDrinkingVolume(
        getCustomVolume(graphData?.M39),
        getCustomVolume(graphData?.M32)
      ),
      labels: getCustomLabels(volChartData?.data?.M39),
      data: getTotalDrinkingData(
        volChartData?.data?.M32
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M32)
          : [],
        volChartData?.data?.M39
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M39)
          : []
      ),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M34FR?.toFixed(2) ||
          0 + graphData?.M35FR?.toFixed(2) ||
          0 + graphData?.M36FR?.toFixed(2) ||
          0) / 3
      ),
      volume: getTotalProcessVolume(
        getCustomVolume(graphData?.M34),
        getCustomVolume(graphData?.M35),
        getCustomVolume(graphData?.M36)
      ),
      labels: getCustomLabels(volChartData?.data?.M34),
      data: getTotalProcessData(
        volChartData?.data?.M34
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M34)
          : [],
        volChartData?.data?.M35
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M35)
          : [],
        volChartData?.data?.M36
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M36)
          : []
      ),
    },
    {
      meter: 32,
      title: `${TML_METER_NUMBERS.M32} : ${M32_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M32FR),
      volume: getCustomVolume(graphData?.M32),
      labels: getCustomLabels(volChartData?.data?.M32),
      data:
        volChartData?.data?.M32 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M32),
    },
    {
      meter: 34,
      title: `${TML_METER_NUMBERS.M34} : ${M34_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M34FR),
      volume: getCustomVolume(graphData?.M34),
      labels: getCustomLabels(volChartData?.data?.M34),
      data:
        volChartData?.data?.M34 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M34),
    },
    {
      meter: 39,
      title: `${TML_METER_NUMBERS.M39} : ${M39_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M39FR),
      volume: getCustomVolume(graphData?.M39),
      labels: getCustomLabels(volChartData?.data?.M39),
      data:
        volChartData?.data?.M39 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M39),
    },
    {
      meter: 35,
      title: `${TML_METER_NUMBERS.M35} : ${M35_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M35FR),
      volume: getCustomVolume(graphData?.M35),
      labels: getCustomLabels(volChartData?.data?.M35),
      //data: volChartData?.data?.M33?.map((item) => item?.M33TVDifference),
      data:
        volChartData?.data?.M35 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M35),
    },
    null,
    {
      meter: 36,
      title: `${TML_METER_NUMBERS.M36} : ${M36_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M36FR),
      volume: getCustomVolume(graphData?.M36),
      labels: getCustomLabels(volChartData?.data?.M36),
      //data: volChartData?.data?.M33?.map((item) => item?.M33TVDifference),
      data:
        volChartData?.data?.M36 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M36),
    },
  ];

  const defaultData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(
        (graphData?.M39FR?.toFixed(2) ||
          0 + graphData?.M39FR?.toFixed(2) ||
          0) / 2
      ),
      volume: getTotalDrinkingVolume(
        getDefaultVolume(graphData?.M39),
        getDefaultVolume(graphData?.M32)
      ),
      labels: getDefaultLabels(volChartData?.data?.M39),
      data: getTotalDrinkingData(
        volChartData?.data?.M32?.map((item) => item?.M32TV),
        volChartData?.data?.M39?.map((item) => item?.M39TV)
      ),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M34FR?.toFixed(2) ||
          0 + graphData?.M35FR?.toFixed(2) ||
          0 + graphData?.M36FR?.toFixed(2) ||
          0) / 3
      ),
      volume: getTotalProcessVolume(
        getDefaultVolume(graphData?.M34),
        getDefaultVolume(graphData?.M35),
        getDefaultVolume(graphData?.M36)
      ),
      labels: getDefaultLabels(volChartData?.data?.M34),
      data: getTotalProcessData(
        volChartData?.data?.M34?.map((item) => item?.M34TV),
        volChartData?.data?.M35?.map((item) => item?.M35TV),
        volChartData?.data?.M36?.map((item) => item?.M36TV)
      ),
    },
    {
      meter: 32,
      title: `${TML_METER_NUMBERS.M32} : ${M32_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M32FR),
      volume: getDefaultVolume(
        graphData?.M32,
        volChartData?.data?.M32,
        "M32TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M32),
      data: volChartData?.data?.M32?.map((item) => item?.M32TV),
    },
    {
      meter: 34,
      title: `${TML_METER_NUMBERS.M34} : ${M34_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M34FR),
      volume: getDefaultVolume(
        graphData?.M34,
        volChartData?.data?.M34,
        "M34TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M34),
      data: volChartData?.data?.M34?.map((item) => item?.M34TV),
    },
    {
      meter: 39,
      title: `${TML_METER_NUMBERS.M39} : ${M39_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M39FR),
      volume: getDefaultVolume(
        graphData?.M39,
        volChartData?.data?.M39,
        "M39TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M39),
      data: volChartData?.data?.M39?.map((item) => item?.M39TV),
    },
    {
      meter: 35,
      title: `${TML_METER_NUMBERS.M35} : ${M35_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M35FR),
      volume: getDefaultVolume(
        graphData?.M35,
        volChartData?.data?.M35,
        "M35TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M35),
      data: volChartData?.data?.M35?.map((item) => item?.M35TV),
    },
    null,
    {
      meter: 36,
      title: `${TML_METER_NUMBERS.M36} : ${M36_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M36FR),
      volume: getDefaultVolume(
        graphData?.M36,
        volChartData?.data?.M36,
        "M36TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M36),
      data: volChartData?.data?.M36?.map((item) => item?.M36TV),
    },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = Data[0]?.data;
  const array2 = Data[1]?.data;

  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength

  while (array1?.length < maxLength) {
    array1.push(0);
  }

  while (array2?.length < maxLength) {
    array2.push(0);
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const v1 = isNaN(Number(array1[i])) ? 0 : Number(array1[i]);
    const v2 = isNaN(Number(array2[i])) ? 0 : Number(array2[i]);
    const total = v1 + v2;
    combinedArray.push(total);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/TCF1">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>TCF 1 Total</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? ["00:00", "06:00", "12:00", "18:00", "23:59"]
                                : getCustomLabels(volChartData?.data?.M39)
                            }
                            Data={combinedArray}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {getAddition(Data[0]?.volume, Data[1]?.volume)}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>
              {Data?.map((item, index) =>
                item ? (
                  <Col
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    style={styles.column}
                    key={index}
                  >
                    <div className="card" style={styles.card}>
                      <>
                        <div style={styles.titleDiv}>
                          <div style={styles.title}>{item.title}</div>
                          <div className="Box">
                            <div>
                              <Link
                                to={
                                  item?.meter
                                    ? `/live-dashboard/${item.meter}/${item.title}`
                                    : null
                                }
                              >
                                <Meter
                                  value={Math.abs(item.flowRate)}
                                  maxValue={
                                    Math.abs(item.flowRate) > 1000
                                      ? 50000
                                      : 10000
                                  }
                                  width={280}
                                  height={160}
                                />
                              </Link>
                              <div style={styles.FlowRate}>
                                {!isNaN(Math.abs(item.flowRate))
                                  ? Math.abs(item.flowRate)
                                  : 0}
                              </div>
                              <div style={styles.flowRateVol}>
                                Flow Rate (
                                <span>
                                  &nbsp;m<sup>3</sup>
                                </span>
                                /h)
                              </div>
                            </div>
                            <div>
                              <VolumeChart
                                width={280}
                                height={160}
                                Labels={item.labels}
                                Data={item.data}
                              />
                              <div style={styles.Volume}>{item.volume}</div>

                              <div style={styles.flowRateVol}>
                                Volume (
                                <span>
                                  &nbsp;m<sup>3</sup>
                                </span>
                                )
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </Col>
                ) : (
                  <Col sm={12} xs={12} md={12} lg={12} xl={6} xxl={6}></Col>
                )
              )}
            </Row>

            <AxisTrendAndTabularReport shopNo={4} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default Tcf1ShopReports;
