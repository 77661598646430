import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';

export function Layout() {
  return (
    <div>
      <NavBar/>
      
      <Footer/>
    </div>
  );
}
export default Layout




