import React from "react";
import "./styles/ReportTable.css";
import { subDays, eachDayOfInterval, format } from "date-fns";

function getDurationStartAndEnd(days, startDate, endDate) {
  const today = new Date();
  const yesterday = subDays(today, 1);
  if (days && days !== 0) {
    startDate = subDays(yesterday, Number(days));
    endDate = yesterday;
  } else if (startDate && endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  } else {
    startDate = yesterday;
    endDate = today;
  }
  // console.log(startDate, endDate);
  return { startDate, endDate };
}

function generateDatesArray(dateFilter) {
  console.log("datefilter inside getDateArray", dateFilter);
  const { startDate, endDate } = getDurationStartAndEnd(
    dateFilter.days,
    dateFilter.startdate,
    dateFilter.enddate
  );
  if (endDate < startDate) {
    return [];
  }
  const datesArray = eachDayOfInterval({ start: startDate, end: endDate });
  return datesArray?.map((item) => format(item, "yyyy-MM-dd"));
}

// function convertData(obj, setTrue, dateFilter) {
//   const result = [];
//   const timeValues = ["00:00", "06:00", "12:00", "18:00", "23:00"];
//   const datesArray = generateDatesArray(dateFilter);
//   console.log("datesArray", datesArray);

//   // Iterate over each key in the object
//   for (const key in obj) {
//     if (Object.hasOwnProperty.call(obj, key)) {
//       const array = obj[key];

//       // Iterate over array elements
//       array?.forEach((item, index) => {
//         const newItem =
//           setTrue === 0
//             ? { Timestamp: `${item.D} ${timeValues[index]}` }
//             : { Timestamp: item.D };

//         // Iterate over object properties dynamically
//         for (const prop in item) {
//           if (
//             Object.hasOwnProperty.call(item, prop) &&
//             prop !== "D" &&
//             prop !== "T"
//           ) {
//             newItem[`${prop}`] = item[prop];
//           }
//         }
//         result[index] =
//           result[index]?.Timestamp === newItem.Timestamp
//             ? Object.assign(result[index], newItem)
//             : Object.assign({}, result[index], newItem);
//       });
//     }
//   }

//   return result;
// }

function convertData(obj, setTrue, dateFilter) {
  let result = [];
  const timeValues = ["00:00", "06:00", "12:00", "18:00", "23:00", "00:01"];
  const datesArray = generateDatesArray(dateFilter);
  console.log("dateArray", datesArray);
  let tempResult = datesArray.map((item) => ({ Timestamp: item }));
  console.log(tempResult);
  // Iterate over each key in the object
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const array = obj[key];
      if (setTrue !== 0) {
        tempResult.forEach((item, index) => {
          const temp = array?.find((i) => i.D === item.Timestamp);
          tempResult[index] = Object.assign(tempResult[index], temp || {});
        });
        result = tempResult;
      } else {
        //Iterate over array elements
        array?.forEach((item, index) => {
          const newItem =
            setTrue === 0
              ? { Timestamp: `${item.D} ${timeValues[index]}` }
              : { Timestamp: item.D };

          // Iterate over object properties dynamically
          for (const prop in item) {
            if (
              Object.hasOwnProperty.call(item, prop) &&
              prop !== "D" &&
              prop !== "T"
            ) {
              newItem[`${prop}`] = item[prop];
            }
          }
          result[index] =
            result[index]?.Timestamp === newItem.Timestamp
              ? Object.assign(result[index], newItem)
              : Object.assign({}, result[index], newItem);
        });
      }
    }
  }

  return result;
}

export const TableReports = ({
  data,
  meterTypes,
  headers,
  setTrue,
  dateFilter,
}) => {
  const response = data.data;
  // console.log("response", response, meterTypes);

  const rowData = {};

  response &&
    meterTypes &&
    meterTypes.forEach((element) => {
      rowData[element] = response[element];
    });

  console.log("rowDate", rowData);
  const tempResult = convertData(rowData, setTrue, dateFilter);
  const rows =
    setTrue === 0
      ? convertData(rowData, setTrue, dateFilter)
      : tempResult.filter((item) => item.D >= "2024-03-01");
  return (
    <div style={{ marginTop: "4%" }}>
      <table id="reportTable">
        <thead>
          <tr>
            {headers &&
              headers?.map((item, index) => (
                <>
                  <th
                    colSpan={index !== 0 ? 2 : 1}
                    rowSpan={index === 0 ? 2 : 1}
                  >
                    {item}
                  </th>
                </>
              ))}
          </tr>
          <tr>
            {meterTypes?.map((item, index) => (
              <React.Fragment key={index}>
                <th style={{ color: "#9e2344" }}>FR</th>
                <th style={{ color: "#9e2344" }}>TV</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.Timestamp}</td>
              {meterTypes.map((item) => (
                <>
                  {/* <td>{Number(row[`${item}FR`]).toFixed(2)}</td>
                  <td>{row[`${item}TV`]}</td> */}
                  <td>
                    {!isNaN(row[`${item}FR`])
                      ? Number(row[`${item}FR`])?.toFixed()
                      : "--"}
                  </td>
                  <td>
                    {setTrue === 0
                      ? !isNaN(row[`${item}TV`])
                        ? row[`${item}TV`]?.toFixed()
                        : "--"
                      : !isNaN(row[`${item}TVDifference`])
                      ? row[`${item}TVDifference`]?.toFixed()
                      : "--"}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
