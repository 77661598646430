import React from "react";
import "../Styles/Footer.css";
const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footerText">
        Developed by Dhruva{" "}
        <img
          src={require("../Assets/DHRUVA-logo(Icon ONLY).png")}
          width="22"
          height="34"
          alt="logo"
        />{" "}
        | All rights reserved
      </div>{" "}
    </div>
  );
};

export default Footer;
