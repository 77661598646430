// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-navbar {

  width: 100vw;
  height: 66.66px;
  background: #FFF;
  box-shadow: 0px 3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);
}

.navText{
  padding-left: 23.33px;
  color: var(--primary-blue, #375EAD);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navText2{
  color: #43ABE0;
font-size: 16.667px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.833px;
padding-left: 13px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Navbar.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kFAAkF;AACpF;;AAEA;EACE,qBAAqB;EACrB,mCAAmC;EACnC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB,mBAAmB;AACnB,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,uBAAuB;AACvB,kBAAkB;AAClB","sourcesContent":[".custom-navbar {\n\n  width: 100vw;\n  height: 66.66px;\n  background: #FFF;\n  box-shadow: 0px 3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);\n}\n\n.navText{\n  padding-left: 23.33px;\n  color: var(--primary-blue, #375EAD);\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n.navText2{\n  color: #43ABE0;\nfont-size: 16.667px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\nletter-spacing: 0.833px;\npadding-left: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
