import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "../Layout/Tabs";
import PlantShopsBlocksTabs from "../Layout/PlantShopsBlocksTabs";

const styles = {
  MainDiv: {
    backgroundColor: "rgb(249, 249, 249)",
    width: "100vw",
    height: "100vh",

    margin: "auto",
  },
  card: {
    height: "97px",
    width: "314px",
    border: "none",
    margin: "40px",
    borderRadius: "4.361px",

    boxShadow:
      "0px 0px 7.63194465637207px 1.0902777910232544px rgba(0, 0, 0, 0.20)",
  },
  subDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
  ImgDiv: { borderRight: "solid black 3px" },
  Img: { marginRight: "20px", height: "50px", width: "50px" },
  TextDiv: {
    textAlign: "left",
    marginRight: "30px",
    color: "navy",
    width: "120px",
  },
  MainFont: { fontWeight: "bold" },
};

const cardData = [
  {
    imageSrc: require("../Assets/PVBU.png"),
    title: "K-Block",
    subtitle: "Pune",
    linkTo: "/reports/blockreport",
  },
  {
    imageSrc: require("../Assets/CVBU.png"),
    title: "J-Block",
    subtitle: "Pune",
    linkTo: "/reports/plantblocks",
  },
  {
    imageSrc: require("../Assets/office.png"),
    title: "Office Block",
    subtitle: "Pune",
    linkTo: "/reports/plantblocks",
  },
  {
    imageSrc: require("../Assets/PVBU.png"),
    title: "JLR",
    subtitle: "Pune",
    linkTo: "/reports/plantblocks",
  },
];

const PlantBlocks = () => {
  return (
    <div style={styles.MainDiv}>
      <Tabs />
      <PlantShopsBlocksTabs />
      <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Row>
          {cardData.map((card, index) => (
            <Col key={index} sm={12} xs={12} md={12} lg={6} xl={4} xxl={4}>
              <Link to={card.linkTo}>
                <div className="card" style={styles.card}>
                  <div style={styles.subDiv}>
                    <div style={styles.ImgDiv}>
                      <img
                        src={card.imageSrc}
                        style={styles.Img}
                        className="d-inline-block align-top"
                        alt="logo"
                      />
                    </div>
                    <div style={styles.TextDiv}>
                      <div style={styles.MainFont}>{card.title}</div>
                      <div>{card.subtitle}</div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default PlantBlocks;
