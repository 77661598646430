import React, { useContext, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginLogo from "../Assets/LoginLogo.png";
import ToastContext from "../Context/ToastContext";
import { useAuth } from "../Context/AuthContext";

const styles = {
  mainImage: {
    height: "100vh",
    width: "100%",
    objectFit: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${LoginLogo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingLeft: "133px",
    color: "white",
  },
  Feedback: {
    fontSize: "1rem",
    color: "#dc3545",
    marginTop: "5px",
  },
};

const LogIn = () => {
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const { isAuthenticated } = useAuth();
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("Phone number is required"),
  });
  const navigate = useNavigate();
  const initialValues = {
    phone: "",
    otp: "",
  };

  const [OTP, setOtp] = useState(false);
  const handleSubmitOTP = async (values, action) => {
    try {
      const res = await axios.post(`${URL}sent-otp`, {
        phone: values.phone,
      });
      console.log("res---", res);
      setOtp(res.status === 200 ? true : false);
      toast.success(res.data.message);

      return res;
    } catch (err) {
      err.message === "Network Error"
        ? toast.error(err.message)
        : toast.error(err.response.data.message);
    }
  };

  const handleSubmitLogin = async (values, action) => {
    try {
      const res = await axios.post(`${URL}login?phone=${values.phone}`, {
        otp: values.otp,
      });
      action.resetForm();
      localStorage.setItem("token", res.data.data);
      toast.success(res.data.message);
      isAuthenticated();
      navigate("/homepage");
    } catch (err) {
      err.message === "Network Error"
        ? toast.error(err.message)
        : toast.error(err.response.data.message);
    }
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: OTP === false ? handleSubmitOTP : handleSubmitLogin,
    });

  return (
    <Container fluid>
      <Row>
        <Col
          xs={12}
          md={12}
          style={{ margin: "auto", height: "100%", width: "500px" }}
        >
          <div
            className="login-form-container"
            style={{ padding: "50px", textAlign: "center", height: "80vh" }}
          >
            <img
              src={require("../Assets/Netp_Logo_2.10.png")}
              width="220"
              height="75"
              alt="logo"
              style={{ cursor: "pointer", marginTop: "20px" }}
            />
            <div
              style={{
                color: "#43ABE0",
                fontSize: "16.667px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.833px",
                marginBottom: "40px",
              }}
            ></div>
            <h1
              className="mb-4"
              style={{
                color: "var(--black, #0F0F0F)",
                fontSize: "18.667px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.833px",
                textAlign: "center",
              }}
            >
              LOGIN
            </h1>
            <div
              style={{
                borderTop: "2px solid #375EAD",
                background: "#FFF",
                boxShadow:
                  "0px 3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13)",
                padding: "40px",
              }}
            >
              <Form onSubmit={handleSubmit}>
                <Form.Group style={{ height: "100px" }}>
                  <Form.Label
                    style={{
                      float: "left",
                      fontWeight: "bold",
                      fontSize: "13.333px",
                      color: "#375EAD",
                    }}
                  >
                    Mobile no.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Mobile no"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.phone && errors.phone}
                    className="form-control"
                    style={{
                      height: "34.666px",
                      borderRadius: "2.667px",
                      border: "0.667px solid var(--primary-blue, #375EAD)",
                      background: "#FFF",
                    }}
                  />
                  {errors.phone && touched.phone ? (
                    <div style={styles.Feedback} type="invalid">
                      {errors.phone}
                    </div>
                  ) : null}
                </Form.Group>
                {OTP && (
                  <>
                    <Form.Group style={{ height: "100px" }}>
                      <Form.Label
                        style={{
                          float: "left",
                          fontWeight: "bold",
                          fontSize: "13.333px",
                          color: "#375EAD",
                        }}
                      >
                        OTP
                      </Form.Label>
                      <Form.Control
                        name="otp"
                        placeholder="OTP"
                        type="password"
                        value={values.otp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.otp && errors.otp}
                        className="form-control"
                        style={{
                          height: "34.666px",
                          borderRadius: "2.667px",
                          border: "0.667px solid var(--primary-blue, #375EAD)",
                          background: "#FFF",
                        }}
                      />
                      {errors.otp && touched.otp ? (
                        <div style={styles.Feedback} type="invalid">
                          {errors.otp}
                        </div>
                      ) : null}
                    </Form.Group>
                  </>
                )}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ backgroundColor: "navy", width: "183px" }}
                  >
                    {OTP ? "LOGIN" : "GET OTP"}
                  </button>
                </div>
              </Form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "20vh",
              paddingTop: "70px",
            }}
          >
            <div>Developed by </div>
            <img
              src={require("../Assets/DhruvaLogo.png")}
              width="116"
              height="45"
              alt="logo"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LogIn;
