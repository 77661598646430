import React from "react";
import { Line, Bar } from "react-chartjs-2";

const BillTrend = ({ trendData }) => {
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 10,
        },
      },
      title: {
        display: false,
        text: "Multi Axis",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Day",
          color: "#0F0F0F",
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        beginAtZero: true,
        title: {
          display: true,
          text: "Consumption",
          color: "#0F0F0F",
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    layout: {
      padding: {
        bottom: 5,
        left: 5,
        top: 5,
        right: 5,
      },
    },
  };
  return <Line data={trendData} options={options} />;
};

export default BillTrend;
