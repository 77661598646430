import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Layout from "../Layout/Layout";
import { useAuth } from "../Context/AuthContext";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import UserLogin from "../User Control/UserLogin";

function PrivateRoute({ children }) {
  const path = useLocation();
  const { isAuthenticated } = useAuth();

  return isAuthenticated() && path.pathname !== "/login" ? (
    <>
      <NavBar />
      {children}
      <Outlet />
      <Footer />
    </>
  ) : (
    <UserLogin />
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  children: PropTypes.node,
};
