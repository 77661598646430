import React, { useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "jspdf-autotable";
import ToastContext from "../../Context/ToastContext";

const styles = {
  Filter: {
    margin: "auto",
  },
  Select: {
    width: "220px",
    height: "35px",
    borderRadius: "2.667px",
    border: "0.667px solid rgba(91, 91, 91, 0.37)",
  },
  datePicker: {
    width: "220px",
    height: "35px",
    fontSize: "1rem",
    borderRadius: "2.667px",
    border: "0.667px solid rgba(91, 91, 91, 0.37)",
  },
  column: { padding: "20px" },
  searchBtn: {
    borderRadius: "2.667px",
    border: "0.667px solid #375EAD",
    backgroundColor: "#375EAD",
    color: "white",
    fontWeight: "bold",
    width: "146px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchBtnDiv: {
    display: "flex",
    height: "65px",
    alignItems: "flex-end",
  },
};

const CardsDateFilter = (props) => {
  const {
    dateFilter,
    setDateFilter,
    fetchData,
    fetchVoulme,
    fetchAvgFlowRate,
  } = props;
  const { toast } = useContext(ToastContext);

  const handleSearch = async () => {
    if (
      dateFilter.startdate != "" &&
      dateFilter.startdate < "2024-03-01" &&
      dateFilter.enddate != "" &&
      dateFilter.enddate < "2024-03-01" &&
      !dateFilter.days
    ) {
      return toast.error("Data not available");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      return toast.error("Please select start date earlier than curren date");
    } else {
      if (fetchData !== undefined) {
        await fetchData();
      }
      if (fetchVoulme !== undefined) {
        await fetchVoulme();
      }
      if (fetchAvgFlowRate !== undefined) {
        await fetchAvgFlowRate();
      }
    }
  };

  return (
    <>
      <Container style={styles.Filter}>
        <Row>
          <Col xs={12} md={12} lg={3} xl={3} style={styles.column}>
            <Form.Group>
              <Form.Label style={styles.label}>Select Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startdate"
                htmlFor="date"
                onChange={(e) => {
                  setDateFilter({
                    ...dateFilter,
                    startdate: e.target.value,
                  });
                }}
                style={styles.datePicker}
                disabled={dateFilter.days.length !== 0}
                value={dateFilter.startdate}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={12} lg={3} xl={3} style={styles.column}>
            <Form.Group>
              <Form.Label style={styles.label}>Select End Date</Form.Label>
              <Form.Control
                type="date"
                name="enddate"
                htmlFor="date"
                onChange={(e) => {
                  setDateFilter({
                    ...dateFilter,
                    enddate: e.target.value,
                  });
                }}
                style={styles.datePicker}
                disabled={dateFilter.days.length !== 0}
                value={dateFilter.enddate}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={12} lg={3} xl={3} style={styles.column}>
            <Form.Group>
              <Form.Label style={styles.label}>Select Duration</Form.Label>
              <Form.Select
                style={styles.Select}
                name="days"
                value={dateFilter.days}
                onChange={(e) => {
                  setDateFilter({ ...dateFilter, days: e.target.value });
                }}
                disabled={
                  dateFilter.startdate.length && dateFilter.enddate.length !== 1
                }
              >
                <option value="">Select</option>
                <option value="10">Last 10 Days</option>
                <option value="30">Last 30 Days</option>
                <option value="90">Last 3 months</option>
                <option value="180">Last 6 months</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={12} lg={3} xl={3} style={styles.column}>
            <div style={styles.searchBtnDiv}>
              <Button style={styles.searchBtn} onClick={handleSearch}>
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardsDateFilter;
