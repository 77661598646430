// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#water-tabs{
    border-radius: 2.91px;
    background: #FFF;
    border: 1px solid rgba(91, 91, 91, 0.37);
    width: 500px;
}



.navItem{
    border-radius: 0px;
   
}

#navLink {
    border-radius: 0px;
    border: none;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#water-tabs  > a{
    border-radius: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Water Networks/Components/Tabs.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,wCAAwC;IACxC,YAAY;AAChB;;;;AAIA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["#water-tabs{\n    border-radius: 2.91px;\n    background: #FFF;\n    border: 1px solid rgba(91, 91, 91, 0.37);\n    width: 500px;\n}\n\n\n\n.navItem{\n    border-radius: 0px;\n   \n}\n\n#navLink {\n    border-radius: 0px;\n    border: none;\n    height: 46px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n#water-tabs  > a{\n    border-radius: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
