// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}
thead{
    background-color: #DDE7EE;
    color: #494F55;
    font-size: large;
    font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/Reports/styles/LiveTrendTable.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["table {\n    font-family: arial, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n    height: 100%;\n    margin-bottom: 30px;\n}\nthead{\n    background-color: #DDE7EE;\n    color: #494F55;\n    font-size: large;\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
