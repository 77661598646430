import React, { useContext } from "react";

import { Button, Form } from "react-bootstrap";
import ToastContext from "../../Context/ToastContext";
import "jspdf-autotable";

const styles = {
  Filter: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 40px 5px 40px",
    height: "100px",
    alignItems: "flex-end",
  },
  Select: {
    width: "220px",
    height: "35px",
    borderRadius: "2.667px",
    border: "0.667px solid rgba(91, 91, 91, 0.37)",
  },
  SelectDate: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 0px 0px 50px",
  },
  datePicker: {
    width: "220px",
    height: "35px",
    fontSize: "1rem",
    borderRadius: "2.667px",
    border: "0.667px solid rgba(91, 91, 91, 0.37)",
  },
};

const MultiAxisChartFilter = (props) => {
  const { dateFilter, setDateFilter, fetchData, DataDownload } = props;
  const { toast } = useContext(ToastContext);
  const handleSearch = async () => {
    if (
      dateFilter.startdate != "" &&
      dateFilter.startdate < "2024-03-01" &&
      dateFilter.enddate != "" &&
      dateFilter.enddate < "2024-03-01" &&
      !dateFilter.days
    ) {
      return toast.error("Data not available");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      return toast.error("Please select start date earlier than curren date");
    } else {
      if (fetchData !== undefined) {
        await fetchData();
      }
    }
  };

  return (
    <>
      <div style={styles.Filter}>
        <>
          <div style={styles.SelectDate}>
            <Form.Group>
              <Form.Label style={styles.label}>Select Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                htmlFor="date"
                onChange={(e) => {
                  setDateFilter({
                    ...dateFilter,
                    startdate: e.target.value,
                  });
                }}
                style={styles.datePicker}
                disabled={dateFilter.days.length !== 0}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label style={styles.label}>Select End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                htmlFor="date"
                onChange={(e) => {
                  setDateFilter({
                    ...dateFilter,
                    enddate: e.target.value,
                  });
                }}
                style={styles.datePicker}
                placeholder="Start Date"
                disabled={dateFilter.days.length !== 0}
              />
            </Form.Group>

            <div>
              <Form.Group>
                <Form.Label style={styles.label}>Select Duration</Form.Label>

                <Form.Select
                  style={styles.Select}
                  name="days"
                  value={dateFilter.days}
                  onChange={(e) => {
                    setDateFilter({ ...dateFilter, days: e.target.value });
                  }}
                  disabled={
                    dateFilter.startdate.length !== 0 ||
                    dateFilter.enddate.length !== 0
                  }
                >
                  <option value="">Select</option>
                  <option value="10">Last 10 Days</option>
                  <option value="30">Last 30 Days</option>
                  <option value="90">Last 3 months</option>
                  <option value="180">Last 6 months</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "400px",
                height: "65px",
                alignItems: "flex-end",
              }}
            >
              <div>
                <Button
                  style={{
                    borderRadius: "2.667px",
                    border: "0.667px solid #375EAD",
                    backgroundColor: "#375EAD",
                    color: "white",
                    fontWeight: "bold",
                    width: "146px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    borderRadius: "2.667px",
                    border: "0.667px solid #375EAD",
                    backgroundColor: "white",
                    color: "#375EAD",
                    fontWeight: "bold",
                    width: "146px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={DataDownload}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default MultiAxisChartFilter;
