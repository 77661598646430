import React, { useState } from "react";
import "./style.css";
import Tabs from "../../Layout/Tabs";
import WaterNetTabs from "../Components/WaterNetTabs";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import MeterDetails from "../Modals/MeterDetails";

const PaintShop = () => {
  const [openMeterModal, setOpenMeterModal] = useState(false);
  // const [selectedMeter, setSelectedmeters] = useState("");
  const [meterName, setMeterName] = useState("");
  const [value, setValue] = useState("");
  const [consumptionValue, setConsumptionValue] = useState(0);
  const [flowRate, setFlowRate] = useState(0);
  const { token } = useAuth();
  // const [totalVolume, setTotalVolume] = useState([]);
  const [meterClicked, setMeterClicked] = useState(false);
  // const [openMeterModal, setOpenMeterModal] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  // const [flowRate, setFlowRate] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [meterNames, setMeterNames] = useState([]);
  // const [meterClicked, setMeterClicked] = useState(false);

  const DrinkingArr = [
    {
      M1: "Pump House-4",
      M2: "Pump House-4",
      M3: "Pump House-4",
      M4: "Pump House-4",
      M50: "Pump House-4",
      M51: "Pump House-4",
    },
    {
      M18: "Paint Shop",
      M20: "Paint Shop",
    },
    {
      M27: "Office Block",
      M28: "Office Block",
      M29: "Office Block",
      M30: "Office Block",
    },

    {
      M23: "Press Shop",
    },
    {
      M24: "Weld Shop",
    },
    {
      M7: "ETP",
      M8: "ETP",
      M9: "ETP",
      M10: "ETP",

      M12: "ETP",
      M13: "ETP",
      M14: "ETP",
      M56: "ETP",
      M57: "ETP",
      M54: "ETP",
    },
    {
      M17: "Paint Shop",
    },
  ];

  const extractPropertyValues = (arr, propertyNames) => {
    return arr.map((item) =>
      propertyNames.map((propertyName) => item[propertyName])
    );
  };

  const handleMeters = async (flag, meter) => {
    let url = `${process.env.REACT_APP_URL}get-meters-data`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`, // Replace with your actual token
        },
      });

      const { meterName, value, data } = response.data;

      // Moved setMeterNames and setSelectedMeter outside of handleMeters
      setMeterNames(extractPropertyValues(DrinkingArr, [meter]));
      setSelectedMeter(meter);
      //
      const meterData = response.data.data;
      console.log(
        "Meter Data:",
        meter,
        meterData,
        meterData.hasOwnProperty(`${meter}FR`),
        meterData.hasOwnProperty(`${meter}`)
      );

      if (meterData) {
        // Check if the meter has Flow Rate (FR) and Total Volume (TV) properties
        if (
          meterData.hasOwnProperty(`${meter}FR`) &&
          meterData.hasOwnProperty(`${meter}`)
        ) {
          const flowRate = meterData[`${meter}FR`];
          const totalVolume = meterData[`${meter}`];
          console.log("Flow Rate:", flowRate);
          console.log("Total Volume:", totalVolume);
          // Set the state variables for Flow Rate and Total Volume
          setFlowRate(flowRate);
          setTotalVolume(totalVolume);

          // You can use flowRate and totalVolume values as needed in your component
        }
      }

      setOpenMeterModal(true);
      setMeterClicked(true);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };
  const dynamicName = "Process Water Line";
  return (
    <>
      <Tabs />
      <WaterNetTabs />
      <div className="paint-shop">
        <div className="div">
          <div className="overlap-group">
            <div className="overlap-2">
              <img
                className="line"
                alt="Line"
                src={require("./img/line-8.png")}
              />
              <div className="rectangle-2" />
              <div className="rectangle-3" />
              <img
                className="vector"
                alt="Vector"
                src={require("./img/vector-23.png")}
              />
              <img
                className="img"
                alt="Vector"
                src={require("./img/vector-22.png")}
              />
              <img
                className="vector-2"
                alt="Vector"
                src={require("./img/vector-21.png")}
              />
              <img
                className="vector-3"
                alt="Vector"
                src={require("./img/vector-20.png")}
              />
              <img
                className="vector-4"
                alt="Vector"
                src={require("./img/vector-19.png")}
              />
              <img
                className="vector-5"
                alt="Vector"
                src={require("./img/vector-18.png")}
              />
              <img
                className="vector-6"
                alt="Vector"
                src={require("./img/vector-17.png")}
              />
              <img
                className="vector-7"
                alt="Vector"
                src={require("./img/vector-16.png")}
              />
              <img
                className="vector-8"
                alt="Vector"
                src={require("./img/vector-15.png")}
              />
              <img
                className="vector-9"
                alt="Vector"
                src={require("./img/vector-14.png")}
              />
              <img
                className="line-2"
                alt="Line"
                src={require("./img/line-50.png")}
              />
              <img
                className="line-3"
                alt="Line"
                src={require("./img/line-61.png")}
              />
              <img
                className="line-4"
                alt="Line"
                src={require("./img/line-66.png")}
              />
              <img
                className="line-5"
                alt="Line"
                src={require("./img/line-64.png")}
              />
              <img
                className="line-6"
                alt="Line"
                src={require("./img/line-65.png")}
              />
              <img
                className="line-7"
                alt="Line"
                src={require("./img/line-66.png")}
              />
              <img
                className="line-8"
                alt="Line"
                src={require("./img/line-67.png")}
              />
              <img
                className="line-9"
                alt="Line"
                src={require("./img/line-68.png")}
              />
              <img
                className="line-10"
                alt="Line"
                src={require("./img/line-69.png")}
              />
              <img
                className="line-11"
                alt="Line"
                src={require("./img/line-75.png")}
              />
              <img
                className="line-12"
                alt="Line"
                src={require("./img/line-77.png")}
              />
              <img
                className="line-13"
                alt="Line"
                src={require("./img/line-77.png")}
              />
              <img
                className="line-14"
                alt="Line"
                src={require("./img/line-78.png")}
              />
              <img
                className="line-15"
                alt="Line"
                src={require("./img/line-79.png")}
              />
              <img
                className="line-16"
                alt="Line"
                src={require("./img/line-80.png")}
              />
              <img
                className="line-17"
                alt="Line"
                src={require("./img/line-81.png")}
              />
              <img
                className="line-18"
                alt="Line"
                src={require("./img/line-73.png")}
              />
              <img
                className="line-19"
                alt="Line"
                src={require("./img/line-73.png")}
              />
              <img
                className="line-20"
                alt="Line"
                src={require("./img/line-72.png")}
              />
              <img
                className="line-21"
                alt="Line"
                src={require("./img/line-73.png")}
              />
              <img
                className="line-22"
                alt="Line"
                src={require("./img/line-74.png")}
              />
              <img
                className="line-23"
                alt="Line"
                src={require("./img/line-62.png")}
              />
              <img
                className="line-24"
                alt="Line"
                src={require("./img/line-51.png")}
              />
              <img
                className="vector-10"
                alt="Vector"
                src={require("./img/vector-5.png")}
              />
              <img
                className="line-25"
                alt="Line"
                src={require("./img/line-52.png")}
              />
              <img
                className="vector-11"
                alt="Vector"
                src={require("./img/vector-7.png")}
              />
              <img
                className="line-26"
                alt="Line"
                src={require("./img/line-53.png")}
              />
              <img
                className="vector-12"
                alt="Vector"
                src={require("./img/vector-7.png")}
              />
              <img
                className="line-27"
                alt="Line"
                src={require("./img/line-54.png")}
              />
              <img
                className="vector-13"
                alt="Vector"
                src={require("./img/vector-7.png")}
              />
              <img
                className="line-28"
                alt="Line"
                src={require("./img/line-53.png")}
              />
              <img
                className="vector-14"
                alt="Vector"
                src={require("./img/vector-9.png")}
              />
              <img
                className="line-29"
                alt="Line"
                src={require("./img/line-59.png")}
              />
              <img
                className="vector-15"
                alt="Vector"
                src={require("./img/vector-7.png")}
              />
              <img
                className="line-30"
                alt="Line"
                src={require("./img/line-59.png")}
              />
              <img
                className="vector-16"
                alt="Vector"
                src={require("./img/vector-7.png")}
              />
              <img
                className="line-31"
                alt="Line"
                src={require("./img/line-60.png")}
              />
              <img
                className="line-32"
                alt="Line"
                src={require("./img/line-53.png")}
              />
              <img
                className="vector-17"
                alt="Vector"
                src={require("./img/vector-6.png")}
              />
              <img
                className="line-33"
                alt="Line"
                src={require("./img/line-54.png")}
              />
              <img
                className="vector-18"
                alt="Vector"
                src={require("./img/vector-5.png")}
              />
              <div className="text-wrapper-3">PT6</div>
              <div className="text-wrapper-4">PT10</div>
              <div className="text-wrapper-5">PT7</div>
              <div className="text-wrapper-6">PT11</div>
              <div className="text-wrapper-7">PT8</div>
              <div className="text-wrapper-8">PT12</div>
              <div className="text-wrapper-9">PT9</div>
              <div className="text-wrapper-10">PT13</div>
              <div className="text-wrapper-11">PT13</div>
              <img
                className="vector-19"
                alt="Vector"
                src={require("./img/vector-1.png")}
              />
              <img
                className="vector-20"
                alt="Vector"
                src={require("./img/vector-1.png")}
              />
              <img
                className="vector-21"
                alt="Vector"
                src={require("./img/vector-1.png")}
              />
              <img
                className="vector-22"
                alt="Vector"
                src={require("./img/vector-1.png")}
              />
              <div className="text-wrapper-12">HOT WATER</div>
              <div className="text-wrapper-13">CHILLING COIL</div>
              <div className="text-wrapper-14">DM PLANT</div>
              <div className="text-wrapper-15">PT1</div>
              <div className="text-wrapper-16">PT2</div>
              <div className="text-wrapper-17">PT3</div>
              <div className="text-wrapper-18">PT4</div>
              <div className="text-wrapper-19">PT5</div>
              <div className="text-wrapper-20">NORTH EQUIPMENT</div>
              <div className="text-wrapper-21">4 CHILLING TOWER</div>
              <div className="text-wrapper-22">CT1</div>
              <div className="text-wrapper-23">CT2</div>
              <div className="text-wrapper-24">CT3</div>
              <div className="text-wrapper-25">CT4</div>
              <img
                className="vector-23"
                alt="Vector"
                src={require("./img/vector.png")}
              />
              <div className="STORE-CHILLER-TOWER">
                STORE CHILLER <br />
                TOWER
              </div>
              <img
                className="group"
                alt="Group"
                src={require("./img/group-14.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-2"
                alt="Group"
                src={require("./img/group-30.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-3"
                alt="Group"
                src={require("./img/group-31.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-4"
                alt="Group"
                src={require("./img/group-34.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-5"
                alt="Group"
                src={require("./img/group-34.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-6"
                alt="Group"
                src={require("./img/group-32.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-7"
                alt="Group"
                src={require("./img/group-35.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-8"
                alt="Group"
                src={require("./img/group-36.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-9"
                alt="Group"
                src={require("./img/group-37.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="group-10"
                alt="Group"
                src={require("./img/group-38.png")}
                onClick={() => {
                  handleMeters(true, "M17");
                }}
              />
              <img
                className="polygon"
                alt="Polygon"
                src={require("./img/polygon-74.png")}
                // onClick={() => {
                //   handleMeters(true, "M17");
                // }}
              />
              <img
                className="polygon-2"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
                // onClick={() => {
                //   handleMeters(true, "M17");
                // }}
              />
              <img
                className="polygon-3"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
                // onClick={() => {
                //   handleMeters(true, "M17");
                // }}
              />
              <img
                className="polygon-4"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
                // onClick={() => {
                //   handleMeters(true, "M17");
                // }}
              />
              <img
                className="polygon-5"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-6"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-7"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-8"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-9"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-10"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-11"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-12"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-13"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-14"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-15"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-16"
                alt="Polygon"
                src={require("./img/polygon-91.png")}
              />
              <img
                className="polygon-17"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-18"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-19"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-20"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-21"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-22"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-23"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-24"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-25"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-26"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
              <img
                className="polygon-27"
                alt="Polygon"
                src={require("./img/polygon-83.png")}
              />
            </div>
          </div>
          <img
            className="vector-24"
            alt="Vector"
            src={require("./img/vector-10-1.png")}
          />
        </div>
      </div>
      {meterClicked && (
        <div>
          <h3>MeterNames</h3>
          {meterNames
            .flat()
            .filter((value) => value !== undefined)
            .map((value, index) => (
              <p key={index}>{value}</p>
            ))}
          {/* Pass meterNames as a prop to MeterDetails */}
          <MeterDetails
            show={openMeterModal}
            onShow={() => setOpenMeterModal(true)}
            onHide={() => setOpenMeterModal(false)}
            consumptionValue={totalVolume}
            flowRate={flowRate}
            meterNames={meterNames.flat()}
            dynamicName={dynamicName}
          />
        </div>
      )}
    </>
  );
};

export default PaintShop;
