import React, { createContext, useContext, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const isTokenExpired = (token) => {
    if (!token) return false; // Token does not exist or is invalid
    const expirationTime = jwtDecode(token).exp * 1000; // Convert expiration time to milliseconds
    return Date.now() >= expirationTime;
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    console.log("isauthenticated", isTokenExpired(token));
    return token && !isTokenExpired(token);
  };

  const token = localStorage.getItem("token");

  return (
    <AuthContext.Provider value={{ user, logout, isAuthenticated, token }}>
      {children}
    </AuthContext.Provider>
  );
};
