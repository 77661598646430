import NovaBiwReport from "./NovaBiwReport";
import { ALTROZ_BIW } from "./utils/Constants";

const AltrozBiwReport = () => {
  return (
    <div>
      <NovaBiwReport shopname={"AltrozBiw"} shopNo={ALTROZ_BIW} />
    </div>
  );
};
export default AltrozBiwReport;
