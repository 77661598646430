import React, { useEffect, useState } from "react";
import "./styles/ReportTable.css";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";

export const ReportTable1 = ({ endpoint, headers, dynamicKey }) => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const [MapData, setMapData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}${endpoint}`, {
          headers: {
            Authorization: `abc ${token}`,
          },
        });
        console.log("responsetcf", response, endpoint);

        setMapData(response?.data?.data || {});
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [URL, token, endpoint]);

  console.log("MapData:", dynamicKey);
  console.log("Headers:", headers, endpoint, dynamicKey);

  return (
    <>
      <div className="fixTableHead">
        {loading ? (
          <p>Loading...</p>
        ) : (
          MapData && (
            <table>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  {headers.map((header, index) => (
                    <React.Fragment key={index}>
                      <th colSpan={2}>{header}</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tr className="ColSpan">
                <td></td>
                {Object.keys(MapData).map((key) => (
                  <React.Fragment key={key}>
                    <td>FR</td>
                    <td>TV</td>
                  </React.Fragment>
                ))}
              </tr>
              <tbody>
                {MapData &&
                  MapData[dynamicKey] &&
                  Object?.keys(MapData[dynamicKey])?.map((index) => (
                    <tr key={index}>
                      <td>{MapData?.[dynamicKey]?.[index]?.D}</td>
                      {Object?.keys(MapData)?.map((key) => (
                        <React.Fragment key={key}>
                          <td>{MapData[key]?.[index]?.[`${key}FR`]}</td>
                          <td>{MapData[key]?.[index]?.[`${key}TV`]}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          )
        )}
      </div>
    </>
  );
};
