import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughNut = (props) => {
  const options = {
    responsive: true,
    cutout: 90,
    plugins: {
      datalabels: { display: false },

      legend: {
        display: false,
        position: "right",
        align: "center",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
        },
      },
    },
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      const width = chart.width / 2;
      const height = chart.height / 2;

      ctx.save();
      ctx.font = "bold 45px sans-serif";
      ctx.fillStyle = "#375EAD";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(props.NeutralityRatio, width, height);
      //ctx.fillText("Under Scheduled Maintenance", width, height);
      ctx.restore();
    },
  };
  return (
    <div style={{ margin: "auto" }}>
      <Doughnut
        data={props.data}
        className="custom-chart"
        width={250}
        height={250}
        options={options}
        plugins={[textCenter]}
      />
    </div>
  );
};

export default DoughNut;
