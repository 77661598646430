// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blink {
    animation: blink-animation 1s infinite;
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }`, "",{"version":3,"sources":["webpack://./src/CXO Dashboard/style.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;EACxC;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".blink {\n    animation: blink-animation 1s infinite;\n  }\n  \n  @keyframes blink-animation {\n    0% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0.3;\n    }\n    100% {\n      opacity: 1;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
