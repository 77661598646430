import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReportTable } from "./ReportTable";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";
import { Link } from "react-router-dom";
import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M18_METER_NAME,
  M20_METER_NAME,
  M43_METER_NAME,
  M55_METER_NAME,
  M38_METER_NAME,
  M27_METER_NAME,
  M28_METER_NAME,
  M29_METER_NAME,
  M30_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "700px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "50px 0px",
  },
};

const Canteen1Report = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);
  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=14&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for M18fr and M18fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}line-chartpaintshop?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      // console.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const customDateData = [
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getCustomVolume(graphData?.M18),
      labels: getCustomLabels(volChartData?.data?.M18),
      data:
        volChartData?.data?.M18 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M18),
    },
    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getCustomVolume(graphData?.M20),
      labels: getCustomLabels(volChartData?.data?.M20),
      data:
        volChartData?.data?.M20 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M20),
    },
    {
      meter: 29,
      title: `${TML_METER_NUMBERS.M29} : ${M29_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M29FR),
      volume: getCustomVolume(graphData?.M29),
      labels: getCustomLabels(volChartData?.data?.M29),
      data:
        volChartData?.data?.M29 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M29),
    },
    {
      meter: 55,
      title: `${TML_METER_NUMBERS.M55} : ${M55_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M55FR),
      volume: getCustomVolume(graphData?.M55),
      labels: getCustomLabels(volChartData?.data?.M55),
      data:
        volChartData?.data?.M55 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M55),
    },
    {
      meter: 38,
      title: `${TML_METER_NUMBERS.M38} : ${M38_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M38FR),
      volume: getCustomVolume(graphData?.M38),
      labels: getCustomLabels(volChartData?.data?.M38),
      data:
        volChartData?.data?.M38 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M38),
    },
    {
      meter: 43,
      title: `${TML_METER_NUMBERS.M43} : ${M43_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M43FR),
      volume: getCustomVolume(graphData?.M43),
      labels: getCustomLabels(volChartData?.data?.M43),
      data:
        volChartData?.data?.M43 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M43),
    },
    // {
    //   title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M27FR),
    //   volume: getCustomVolume(graphData?.M27),
    //   labels: getCustomLabels(volChartData?.data?.M27),
    //   data:
    //     volChartData?.data?.M27 &&
    //     formatLargeRecordsDataTo10Values(volChartData?.data?.M27),
    // },
    // {
    //   title: `${TML_METER_NUMBERS.M28} : ${M28_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M28FR),
    //   volume: getCustomVolume(graphData?.M28),
    //   labels: getCustomLabels(volChartData?.data?.M28),
    //   data:
    //     volChartData?.data?.M28 &&
    //     formatLargeRecordsDataTo10Values(volChartData?.data?.M28),
    // },
    // {
    //   title: `${TML_METER_NUMBERS.M30} : ${M30_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M30FR),
    //   volume: getCustomVolume(graphData?.M30),
    //   labels: getCustomLabels(volChartData?.data?.M30),
    //   data:
    //     volChartData?.data?.M30 &&
    //     formatLargeRecordsDataTo10Values(volChartData?.data?.M30),
    // },
  ];

  const defaultData = [
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getDefaultVolume(
        graphData?.M18,
        volChartData?.data?.M18,
        "M18TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M18),
      data: volChartData?.data?.M18?.map((item) => item?.M18TV),
    },
    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getDefaultVolume(
        graphData?.M20,
        volChartData?.data?.M20,
        "M20TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M20),
      data: volChartData?.data?.M20?.map((item) => item?.M20TV),
    },
    {
      meter: 29,
      title: `${TML_METER_NUMBERS.M29} : ${M29_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M29FR),
      volume: getDefaultVolume(
        graphData?.M29,
        volChartData?.data?.M29,
        "M29TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M29),
      data: volChartData?.data?.M29?.map((item) => item?.M29TV),
    },
    {
      meter: 55,
      title: `${TML_METER_NUMBERS.M55} : ${M55_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M55FR),
      volume: getDefaultVolume(
        graphData?.M55,
        volChartData?.data?.M55,
        "M55TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M55),
      data: volChartData?.data?.M55?.map((item) => item?.M55TV),
    },
    {
      meter: 38,
      title: `${TML_METER_NUMBERS.M38} : ${M38_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M38FR),
      volume: getDefaultVolume(
        graphData?.M38,
        volChartData?.data?.M38,
        "M38TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M38),
      data: volChartData?.data?.M38?.map((item) => item?.M38TV),
    },
    {
      meter: 43,
      title: `${TML_METER_NUMBERS.M43} : ${M43_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M43FR),
      volume: getDefaultVolume(
        graphData?.M43,
        volChartData?.data?.M43,
        "M43TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M43),
      data: volChartData?.data?.M43?.map((item) => item?.M43TV),
    },
    // {
    //   title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M27FR),
    //   volume: getDefaultVolume(
    //     graphData?.M27,
    //     volChartData?.data?.M27,
    //     "M27TV"
    //   ),
    //   labels: getDefaultLabels(volChartData?.data?.M27),
    //   data: volChartData?.data?.M27?.map((item) => item?.M27TV),
    // },
    // {
    //   title: `${TML_METER_NUMBERS.M28} : ${M28_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M28FR),
    //   volume: getDefaultVolume(
    //     graphData?.M28,
    //     volChartData?.data?.M28,
    //     "M28TV"
    //   ),
    //   labels: getDefaultLabels(volChartData?.data?.M28),
    //   data: volChartData?.data?.M28?.map((item) => item?.M28TV),
    // },
    // {
    //   title: `${TML_METER_NUMBERS.M30} : ${M30_METER_NAME}`,
    //   flowRate: getFormattedNumber(graphData?.M30FR),
    //   volume: getDefaultVolume(
    //     graphData?.M30,
    //     volChartData?.data?.M30,
    //     "M30TV"
    //   ),
    //   labels: getDefaultLabels(volChartData?.data?.M30),
    //   data: volChartData?.data?.M30?.map((item) => item?.M30TV),
    // },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  // const array1 = Data[0]?.data;
  // const array2 = Data[1]?.data;
  // const array3 = Data[2]?.data;
  // const array4 = Data[3]?.data;

  // const maxLength = Math.max(
  //   array1?.length,
  //   array2?.length,
  //   array3?.length,
  //   array4?.length
  // ); // Update minLength

  // while (array1?.length < maxLength) {
  //   array1.push(0);
  // }

  // while (array2?.length < maxLength) {
  //   array2.push(0);
  // }
  // while (array3?.length < maxLength) {
  //   array1.push(0);
  // }

  // while (array4?.length < maxLength) {
  //   array2.push(0);
  // }

  // const combinedArray = [];
  // for (let i = 0; i < maxLength; i++) {
  //   const v1 = isNaN(Number(array1[i])) ? 0 : Number(array1[i]);
  //   const v2 = isNaN(Number(array2[i])) ? 0 : Number(array2[i]);
  //   const v3 = isNaN(Number(array3[i])) ? 0 : Number(array3[i]);
  //   const v4 = isNaN(Number(array4[i])) ? 0 : Number(array4[i]);
  //   const total = v4 - (v1 + v2 + v3);
  //   combinedArray.push(total);
  // }

  // const getTotalVolume = () => {
  //   const v1 = isNaN(Number(Data[0].volume)) ? 0 : Number(Data[0].volume);
  //   const v2 = isNaN(Number(Data[1].volume)) ? 0 : Number(Data[1].volume);
  //   const v3 = isNaN(Number(Data[2].volume)) ? 0 : Number(Data[2].volume);
  //   const v4 = isNaN(Number(Data[3].volume)) ? 0 : Number(Data[3].volume);
  //   const total = v4 - (v1 + v2 + v3).toFixed(2);
  //   return Math.round(total);
  // };

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Canteen K Block">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                {/* <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Canteen K Block Total</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={Math.abs(graphData?.M18FR?.toFixed(2))}
                            maxValue={
                              Math.abs(graphData?.M18FR?.toFixed(2)) > 10000
                                ? 50000
                                : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {Math.abs(graphData?.M30FR?.toFixed(2))}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={Data[0].labels}
                            Data={combinedArray}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {getTotalVolume()}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div> */}
              </Col>
              {Data?.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={Math.abs(item.flowRate)}
                                maxValue={
                                  Math.abs(item.flowRate) > 1000 ? 50000 : 10000
                                }
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>
                              {!isNaN(Math.abs(item.flowRate))
                                ? Math.abs(item.flowRate)
                                : 0}
                            </div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item.volume}</div>
                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>
            <AxisTrendAndTabularReport shopNo={14} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default Canteen1Report;
