import React, { useState } from "react";
import "./style.css";
import Tabs from "../../Layout/Tabs";
import { Link } from "react-router-dom";
import MeterDetails from "../Modals/MeterDetails";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";

const HoricultureWater = () => {
  const [openMeterModal, setOpenMeterModal] = useState(false);
  // const [selectedMeter, setSelectedmeters] = useState("");
  const [meterName, setMeterName] = useState("");
  const [value, setValue] = useState("");
  const [consumptionValue, setConsumptionValue] = useState(0);
  const [flowRate, setFlowRate] = useState(0);
  const { token } = useAuth();
  const [meterClicked, setMeterClicked] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [totalVolume, setTotalVolume] = useState(0);
  const [meterNames, setMeterNames] = useState([]);

  const DrinkingArr = [
    {
      M1: "Pump House-4",
      M2: "Pump House-4",
      M3: "Pump House-4",
      M4: "Pump House-4",
      M5: "Pump House-4",
      M50: "Pump House-4",
      M51: "Pump House-4",
    },
    {
      M18: "Paint Shop",
      M20: "Paint Shop",
    },
    {
      M27: "Office Block",
      M28: "Office Block",
      M29: "Office Block",
      M30: "Office Block",
    },

    {
      M23: "Press Shop",
    },
    {
      M24: "Weld Shop",
    },
    {
      M56: "ETP",
      M57: "ETP",
      M54: "ETP",
    },
  ];

  const extractPropertyValues = (arr, propertyNames) => {
    return arr.map((item) =>
      propertyNames.map((propertyName) => item[propertyName])
    );
  };

  const handleMeters = async (flag, meter) => {
    let url = `${process.env.REACT_APP_URL}get-meters-data`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`, // Replace with your actual token
        },
      });

      const { meterName, value, data } = response.data;

      // Moved setMeterNames and setSelectedMeter outside of handleMeters
      setMeterNames(extractPropertyValues(DrinkingArr, [meter]));
      setSelectedMeter(meter);
      //
      const meterData = response.data.data;
      // console.log(
      //   "Meter Data:",
      //   meter,
      //   meterData,
      //   meterData.hasOwnProperty(`${meter}FR`),
      //   meterData.hasOwnProperty(`${meter}`)
      // );

      if (meterData) {
        // Check if the meter has Flow Rate (FR) and Total Volume (TV) properties
        if (
          meterData.hasOwnProperty(`${meter}FR`) &&
          meterData.hasOwnProperty(`${meter}`)
        ) {
          const flowRate = meterData[`${meter}FR`];
          const totalVolume = meterData[`${meter}`];
          // console.log("Flow Rate:", flowRate);
          // console.log("Total Volume:", totalVolume);
          // Set the state variables for Flow Rate and Total Volume
          setFlowRate(flowRate);
          setTotalVolume(totalVolume);

          // You can use flowRate and totalVolume values as needed in your component
        }
      }

      setOpenMeterModal(true);
      setMeterClicked(true);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };
  const dynamicName = "Horticulture Water Line";

  return (
    <>
      <Tabs />
      <div className="horiculture-water">
        <div className="div">
          <div
            style={{
              color: "#375EAD",
              fontWeight: "bold",
              fontSize: "18px",
              margin: "30px 0px",
              width: "100%",
              height: "30px",
              textAlign: "center",
            }}
          >
            Plant View
          </div>

          <img
            className="vector"
            alt="Vector"
            src={require("./img/vector-68.png")}
          />
          <img
            className="img"
            alt="Vector"
            src={require("./img/vector-66.png")}
          />

          <Link to="/waternetwork/processwaterline">
            <div className="rectangle-4" />
            <div className="text-wrapper-4">Process Water Line</div>
          </Link>

          <Link to="/waternetwork/drinkingwaterline">
            <div className="rectangle-5" />
            <div className="text-wrapper-5">Drinking Water Line</div>
          </Link>

          <Link to="/waternetwork/horiculturewaterline">
            <div className="rectangle-6" />
            <div className="text-wrapper-6">Horiculture Water Line</div>
          </Link>
          <img
            className="vector-2"
            alt="Vector"
            src={require("./img/vector-71.png")}
          />
          <img
            className="vector-3"
            alt="Vector"
            src={require("./img/vector-70.png")}
          />
          <img
            className="vector-4"
            alt="Vector"
            src={require("./img/vector-69.png")}
          />
          <div className="text-wrapper-7">K BLOCK</div>
          <div className="text-wrapper-8">JLR</div>
          <div className="text" />
          <img
            className="vector"
            alt="Vector"
            src={require("./img/vector-68.png")}
          />
          <img
            className="vector-5"
            alt="Vector"
            src={require("./img/vector-67.png")}
          />
          <img
            className="img"
            alt="Vector"
            src={require("./img/vector-66.png")}
          />
          <img
            className="vector-6"
            alt="Vector"
            src={require("./img/vector-65.png")}
          />
          <img
            className="vector-7"
            alt="Vector"
            src={require("./img/vector-64.png")}
          />
          <img
            className="vector-8"
            alt="Vector"
            src={require("./img/vector-63.png")}
          />
          <img
            className="vector-9"
            alt="Vector"
            src={require("./img/vector-62.png")}
          />
          <img
            className="vector-10"
            alt="Vector"
            src={require("./img/vector-61.png")}
          />
          <img
            className="vector-11"
            alt="Vector"
            src={require("./img/vector-60.png")}
          />
          <img
            className="vector-12"
            alt="Vector"
            src={require("./img/vector-59.png")}
          />
          <img
            className="vector-13"
            alt="Vector"
            src={require("./img/vector-58.png")}
          />
          <img
            className="vector-14"
            alt="Vector"
            src={require("./img/vector-57.png")}
          />
          <img
            className="vector-15"
            alt="Vector"
            src={require("./img/vector-56.png")}
          />
          <img
            className="vector-16"
            alt="Vector"
            src={require("./img/vector-55.png")}
          />
          <img
            className="vector-17"
            alt="Vector"
            src={require("./img/vector-54.png")}
          />
          <Link to="/waternetwork/pumphouse">
            <img
              className="vector-18"
              alt="Vector"
              src={require("./img/vector-53.png")}
            />
          </Link>
          <img
            className="vector-19"
            alt="Vector"
            src={require("./img/vector-52.png")}
          />
          <img
            className="vector-20"
            alt="Vector"
            src={require("./img/vector-51.png")}
          />
          <img
            className="vector-21"
            alt="Vector"
            src={require("./img/vector-50.png")}
          />
          <img
            className="vector-22"
            alt="Vector"
            src={require("./img/vector-49.png")}
          />
          <Link to="/waternetwork/paintshop">
            <img
              className="vector-23"
              alt="Vector"
              src={require("./img/vector-48.png")}
            />
          </Link>
          <Link to="/waternetwork/Etp">
            <img
              className="vector-24"
              alt="Vector"
              src={require("./img/vector-47.png")}
            />
          </Link>
          {/* <img
            className="vector-24"
            alt="Vector"
            src={require("./img/vector-47.png")}
          /> */}
          <img
            className="vector-25"
            alt="Vector"
            src={require("./img/vector-46.png")}
          />
          <img
            className="vector-26"
            alt="Vector"
            src={require("./img/vector-45.png")}
          />
          <div className="text-wrapper-9">WELD SHOP</div>
          <img
            className="vector-27"
            alt="Vector"
            src={require("./img/vector-44.png")}
          />
          <img
            className="vector-28"
            alt="Vector"
            src={require("./img/vector-43.png")}
          />
          <img
            className="vector-29"
            alt="Vector"
            src={require("./img/vector-42.png")}
          />
          <img
            className="vector-30"
            alt="Vector"
            src={require("./img/vector-41.png")}
          />
          <img
            className="vector-31"
            alt="Vector"
            src={require("./img/vector-40.png")}
          />
          <img
            className="vector-32"
            alt="Vector"
            src={require("./img/vector-39.png")}
          />
          <img
            className="vector-33"
            alt="Vector"
            src={require("./img/vector-38.png")}
          />
          <img
            className="vector-34"
            alt="Vector"
            src={require("./img/vector-36.png")}
          />
          <img
            className="vector-35"
            alt="Vector"
            src={require("./img/vector-36.png")}
          />
          <img
            className="vector-36"
            alt="Vector"
            src={require("./img/vector-35.png")}
          />
          <img
            className="vector-37"
            alt="Vector"
            src={require("./img/vector-4.png")}
          />
          <img
            className="vector-38"
            alt="Vector"
            src={require("./img/vector-33.png")}
          />
          <img
            className="vector-39"
            alt="Vector"
            src={require("./img/vector-32.png")}
          />
          <img
            className="vector-40"
            alt="Vector"
            src={require("./img/vector-31.png")}
          />
          <img
            className="vector-41"
            alt="Vector"
            src={require("./img/vector-30.png")}
          />
          <img
            className="vector-42"
            alt="Vector"
            src={require("./img/vector-29.png")}
          />
          <div className="OFFICE-BUILDING">
            OFFICE <br />
            BUILDING
          </div>
          <img
            className="vector-43"
            alt="Vector"
            src={require("./img/vector-27.png")}
          />
          <img
            className="vector-44"
            alt="Vector"
            src={require("./img/vector-27.png")}
          />
          <img
            className="vector-45"
            alt="Vector"
            src={require("./img/vector-26.png")}
          />
          <img
            className="vector-46"
            alt="Vector"
            src={require("./img/vector-24.png")}
          />
          <img
            className="vector-47"
            alt="Vector"
            src={require("./img/vector-24.png")}
          />
          <img
            className="vector-48"
            alt="Vector"
            src={require("./img/vector-23.png")}
          />
          <img
            className="vector-49"
            alt="Vector"
            src={require("./img/vector-22.png")}
          />
          <img
            className="vector-50"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          <img
            className="vector-51"
            alt="Vector"
            src={require("./img/vector-20.png")}
          />
          <div className="UNDERGROUND-TANK">
            UNDERGROUND <br />
            TANK
          </div>
          <div className="PUMP-FOR-LIFT">
            PUMP
            <br />
            FOR LIFT
          </div>
          <div className="TERRACE-TANK">
            TERRACE
            <br />
            TANK
          </div>
          <div className="text-wrapper-10">JLR SHOP FLOOR</div>
          <div className="text-wrapper-11">S GATE</div>
          <div className="text-wrapper-12">POLYHOUSE</div>
          <div className="text-wrapper-13">SOUTH TOILET</div>
          <div className="text-wrapper-14">CANTEEN</div>
          <div className="text-wrapper-15">GDC SUMO GATE</div>
          <div className="text-wrapper-16">PARKING</div>
          <div className="text-wrapper-17">OFFICE BLOCK</div>
          <div className="text-wrapper-18">ENGINE SHOP</div>
          <div className="text-wrapper-19">PRESS SHOP</div>
          <div className="text-wrapper-20">PAINT SHOP</div>
          <div className="text-wrapper-21">ETP</div>
          <div className="text-wrapper-22">TCF 1</div>
          <div className="text-wrapper-23">TCF 2</div>
          <img
            className="line-2"
            alt="Line"
            src={require("./img/line-18.png")}
          />
          <img
            className="vector-52"
            alt="Vector"
            src={require("./img/vector-19.png")}
          />
          <div className="PUMP-HOUSE">
            PUMP
            <br />
            HOUSE-4
          </div>
          <div className="text-wrapper-24">MRS-3 BLOCK</div>
          <img
            className="vector-53"
            alt="Vector"
            src={require("./img/vector-18.png")}
          />
          <div className="text-wrapper-25">CANTEEN</div>
          <div className="text-wrapper-26">KITCHEN</div>
          <div className="text-wrapper-27">MATERIAL GATE</div>
          <div className="text-wrapper-28">CANTEEN 2</div>
          <div className="text-wrapper-29">CANTEEN 1</div>
          <div className="SUPPORT-SERVICE">
            SUPPORT
            <br />
            SERVICE
            <br />
            BUILDING
          </div>
          <div className="text-wrapper-30">LW SHOP</div>
          <div className="text-wrapper-31">EV SHOP</div>
          <div className="text-wrapper-32">COLLECTING TOWER</div>
          <div className="text-wrapper-33">EMERGENCY TANK</div>
          <div className="text-wrapper-34">FIRE CONTROL</div>
          <div className="text-wrapper-35">JRL PDI</div>
          <div className="text-wrapper-36">CANTEEN</div>
          <div className="text-wrapper-37">GDC BUILDING</div>
          <div className="text-wrapper-38">SCRAPYARD</div>
          <div className="text-wrapper-39">POLYHOUSE</div>
          <img
            className="line-3"
            alt="Line"
            src={require("./img/line-19.png")}
          />
          <img
            className="vector-54"
            alt="Vector"
            src={require("./img/vector-17.png")}
          />
          <div className="TANK-EACH">
            TANK
            <br />
            5M3 EACH
          </div>
          <img
            className="vector-55"
            alt="Vector"
            src={require("./img/vector-15.png")}
          />
          <img
            className="vector-56"
            alt="Vector"
            src={require("./img/vector-15.png")}
          />
          <img
            className="vector-57"
            alt="Vector"
            src={require("./img/vector-14.png")}
          />
          <img
            className="vector-58"
            alt="Vector"
            src={require("./img/vector-13.png")}
          />
          <div className="text-wrapper-40">LPG YARD</div>
          <div className="PUMP-HOUSE-m">
            PUMP
            <br />
            HOUSE-6
            <br />
            576M3
          </div>
          <img
            className="vector-59"
            alt="Vector"
            src={require("./img/vector-12.png")}
          />
          <div className="COMPRESSOR-ROOM-n">
            COMPRESSOR
            <br />
            ROOM (N GRID)
          </div>
          <img
            className="vector-60"
            alt="Vector"
            src={require("./img/vector-10.png")}
          />
          <img
            className="vector-61"
            alt="Vector"
            src={require("./img/vector-10.png")}
          />
          <div className="text-wrapper-41">CT1</div>
          <div className="text-wrapper-42">CT2</div>
          <img
            className="vector-62"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <img
            className="vector-63"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <div className="text-wrapper-43">COMPRESSOR HOUSE</div>
          <div className="text-wrapper-44">COOLING TOWER</div>
          {/* <div className="text-wrapper-ro_reject">RO REJECT / HORTICULTURE</div> */}
          <img
            className="vector-64"
            alt="Vector"
            src={require("./img/vector-5.png")}
          />
          <img
            className="vector-65"
            alt="Vector"
            src={require("./img/vector-6.png")}
          />
          <img
            className="vector-66"
            alt="Vector"
            src={require("./img/vector-5.png")}
          />
          <div className="text-wrapper-45">COOLING TOWER</div>
          <p className="p">SOLAR CAR PARKING SHED 1</p>
          <p className="text-wrapper-46">SOLAR CAR PARKING SHED 2</p>
          <img
            className="vector-67"
            alt="Vector"
            src={require("./img/vector-4.png")}
          />
          <div className="SOLAR-CAR-PARKING">
            SOLAR CAR
            <br />
            PARKING
            <br />
            SHED 2
          </div>
          <img
            className="vector-68"
            alt="Vector"
            src={require("./img/vector-3.png")}
          />
          <div className="element-LITRE">
            5000
            <br />
            LITRE
          </div>
          <div className="element-LITRE-2">
            5000
            <br />
            LITRE
          </div>
          <img
            className="line-4"
            alt="Line"
            src={require("./img/line-23.png")}
          />
          <img
            className="vector-69"
            alt="Vector"
            src={require("./img/vector-2.png")}
          />
          <img
            className="line-3"
            alt="Line"
            src={require("./img/line-24.png")}
          />
          <div className="text-wrapper-47">T2</div>
          <div className="text-wrapper-48">T1</div>
          <img
            className="line-5"
            alt="Line"
            src={require("./img/line-25.png")}
          />
          <img
            className="line-6"
            alt="Line"
            src={require("./img/line-26.png")}
          />
          <img
            className="line-7"
            alt="Line"
            src={require("./img/line-40.png")}
          />
          <img
            className="line-8"
            alt="Line"
            src={require("./img/line-30.png")}
          />
          <img
            className="line-9"
            alt="Line"
            src={require("./img/line-27.png")}
          />
          <img
            className="line-10"
            alt="Line"
            src={require("./img/line-31.png")}
          />
          <img
            className="line-11"
            alt="Line"
            src={require("./img/line-28.png")}
          />
          <img
            className="line-12"
            alt="Line"
            src={require("./img/line-41.png")}
          />
          <img
            className="line-13"
            alt="Line"
            src={require("./img/line-32.png")}
          />
          {/*  */}
          <img
            className="line-etp_top"
            alt="Line"
            src={require("./img/line-32.png")}
          />
          {/* <img
            className="line-etp_side"
            alt="Line"
            src={require("./img/line-32.png")}
          />
          <img
            className="line-etp_side1"
            alt="Line"
            src={require("./img/line-32.png")}
          /> */}
          <img
            className="line-pump_house2"
            alt="Line"
            src={require("./img/line-32.png")}
          />
          {/*  */}
          <img
            className="line-14"
            alt="Line"
            src={require("./img/line-33.png")}
          />
          <img
            className="line-15"
            alt="Line"
            src={require("./img/line-38.png")}
          />
          <img
            className="line-16"
            alt="Line"
            src={require("./img/line-35.png")}
          />
          <img
            className="line-17"
            alt="Line"
            src={require("./img/line-36.png")}
          />
          <img
            className="line-18"
            alt="Line"
            src={require("./img/line-34.png")}
          />
          {/*  */}
          <img
            className="line-etp"
            alt="Line"
            src={require("./img/line-34.png")}
          />
          {/* <img
            className="line-etp_side_top"
            alt="Line"
            src={require("./img/line-34.png")}
          /> */}
          <img
            className="line-etp1"
            alt="Line"
            src={require("./img/line-34.png")}
          />
          <img
            className="line-etp2"
            alt="Line"
            src={require("./img/line-34.png")}
          />
          <img
            className="line-etp3"
            alt="Line"
            src={require("./img/line-34.png")}
          />
          {/*  */}
          {/* <img
            className="line-19"
            alt="Line"
            src={require("./img/line-39.png")}
          /> */}
          <img
            className="line-20"
            alt="Line"
            src={require("./img/line-39.png")}
          />
          <img
            className="vector-70"
            alt="Vector"
            src={require("./img/vector-1.png")}
          />
          <img
            className="vector-71"
            alt="Vector"
            src={require("./img/vector.png")}
          />
          <div className="FROM-PUMP-HOUSE">
            FROM
            <br />
            PUMP HOUSE-3
          </div>
          <img
            className="line-21"
            alt="Line"
            src={require("./img/line-20.png")}
          />
          <img
            className="group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M5");
            }}
          />
          <img
            className="group-2"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M4");
            }}
          />
          {/*  */}
          <img
            className="group-M10"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M56");
            }}
          />
          <img
            className="group-M45"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M57");
            }}
          />
          <img
            className="group-M47"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M54");
            }}
          />
          {/* <img
            className="group-M"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
               handleMeters(true,"M4");
            }}
          /> */}
          {/*  */}
        </div>
      </div>
      <img
        className="vector-72"
        alt="Vector"
        src={require("./img/vector-10-1.png")}
      />

      {meterClicked && (
        <div>
          <h3>MeterNames</h3>
          {/* Pass meterNames as a prop to MeterDetails */}
          <MeterDetails
            show={openMeterModal}
            onShow={() => setOpenMeterModal(true)}
            onHide={() => setOpenMeterModal(false)}
            consumptionValue={totalVolume}
            flowRate={flowRate}
            meterNames={meterNames.flat()}
            dynamicName={dynamicName}
          />
        </div>
      )}
    </>
  );
};

export default HoricultureWater;
