import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";

import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";

import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";

import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";
import { Link } from "react-router-dom";
import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";

import {
  M41_METER_NAME,
  M59_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const CompressorHouseReport = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);

  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=12&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for m4fr and m5fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr-carplant?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  useEffect(() => {}, [dateFilter]);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const customDateData = [
    {
      meter: 41,
      title: `${TML_METER_NUMBERS.M41} : ${M41_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M41FR),
      volume: getCustomVolume(graphData?.M41),
      labels: getCustomLabels(volChartData?.data?.M41),
      data:
        volChartData?.data?.M41 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M41),
    },
    {
      meter: 59,
      title: `${TML_METER_NUMBERS.M59} : ${M59_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M59FR),
      volume: getCustomVolume(graphData?.M59),
      labels: getCustomLabels(volChartData?.data?.M59),
      data:
        volChartData?.data?.M59 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M59),
    },
  ];

  const defaultData = [
    {
      meter: 41,
      title: `${TML_METER_NUMBERS.M41} : ${M41_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M41FR),
      volume: getDefaultVolume(
        graphData?.M41,
        volChartData?.data?.M41,
        "M41TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M41),
      data: volChartData?.data?.M41?.map((item) => item?.M41TV),
    },
    {
      meter: 59,
      title: `${TML_METER_NUMBERS.M59} : ${M59_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M59FR),
      volume: getDefaultVolume(
        graphData?.M59,
        volChartData?.data?.M59,
        "M59TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M59),
      data: volChartData?.data?.M59?.map((item) => item?.M59TV),
    },
  ];

  const TotalVol = (graphData?.M41 + graphData?.M59).toFixed();
  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");
  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = volChartData?.data?.M41;
  const array2 = volChartData?.data?.M59;
  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength
  console.log("array1 array2", array1, array2);
  while (array1?.length < maxLength) {
    array1.push({ M41TV: null, M41TVDifference: null });
  }

  while (array2?.length < maxLength) {
    array2.push({ M59TV: null, M59TVDifference: null });
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const combinedItem = {
      defaultTotal: array1[i].M41TV + array2[i].M59TV, // Update this line
      customTotal: array1[i].M41TVDifference + array2[i].M59TVDifference, // Update this line
    };
    combinedArray.push(combinedItem);
  }
  console.log("Combined array", combinedArray);

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Compressor House">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}> Compressor House Total</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(
                              (Number(Data[0].flowRate) +
                                Number(Data[1].flowRate)) /
                                2
                            )}
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(
                              (Number(Data[0].flowRate) +
                                Number(Data[1].flowRate)) /
                                2
                            )}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? [
                                    "00:00",
                                    "06:00",
                                    "12:00",
                                    "18:00",
                                    "23:00",
                                    "00:00",
                                  ]
                                : Data[0].labels
                            }
                            Data={combinedArray.map((item) =>
                              setTrue === 0
                                ? item?.defaultTotal
                                : item?.customTotal
                            )}
                          />
                          <div style={styles.totalVolDiv}>{TotalVol}</div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data?.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={item.flowRate}
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>{item.flowRate}</div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>

            <AxisTrendAndTabularReport shopNo={12} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default CompressorHouseReport;
