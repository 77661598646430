import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import "./Tabs.css";
import { Button } from "react-bootstrap";
const styles = {
  activeTab: {
    borderBottom: "3px solid #375EAD",
    color: "#375EAD",
    fontWeight: "bold",
    fontSize: "14px",
  },

  deactiveTab: {
    color: "rgba(30, 30, 30, 0.53)",
    fontWeight: "600",
    fontSize: "14px",
  },
  etpBackButton: {
    background: "transparent",
    border: "none",
    fontWeight: "bold",
    color: "#000078",
    cursor: "pointer",
    alignItems: "center",
    padding: "15px 00px",
    boxShadow: "none",
  },
};
function EtpNetTabs() {
  const path = useLocation();

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "50px",
        }}
      >
        <Link to="/waternetwork/horiculturewaterline">
          <Button style={styles.etpBackButton}>
            <img
              className="img"
              alt="Vector"
              src={require("../../Assets/arrowLeft.png")}
              height={20}
              width={20}
            />
          </Button>
        </Link>

        <div
          style={{ color: "#375EAD", fontWeight: "600", marginLeft: "20px" }}
        >
          ETP Views
        </div>
      </div>
      <div
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Nav
          id="water-tabs"
          variant="tabs"
          justify
          defaultActiveKey={
            path.pathname === "/waternetwork/paintshopcxo"
              ? "/waternetwork/paintshopcxo"
              : path.pathname === "/waternetwork/paintshop"
              ? "/waternetwork/paintshop"
              : path.pathname === "/waternetwork/bill"
              ? "/waternetwork/bill"
              : null
          }
        >
          {/* <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === "/waternetwork/paintshopcxo"
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              eventKey="/waternetwork/paintshopcxo"
              href="/waternetwork/paintshopcxo"
            >
              CXO Dashboard
            </Nav.Link>
          </Nav.Item> */}
          <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === "/waternetwork/Etp"
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              // eventKey="/waternetwork/Etp"
              // href="/waternetwork/Etp"
            >
              ETP Network
            </Nav.Link>
          </Nav.Item>
          {/* <div> ETP Network</div> */}
          {/* <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === "/waternetwork/bill"
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              eventKey="/waternetwork/bill"
              href="/waternetwork/bill"
            >
              Bill
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
    </div>
  );
}

export default EtpNetTabs;
