import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";

import { Link } from "react-router-dom";
import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";

import {
  M10_METER_NAME,
  M11_METER_NAME,
  M12_METER_NAME,
  M13_METER_NAME,
  M14_METER_NAME,
  M56_METER_NAME,
  M57_METER_NAME,
  M7_METER_NAME,
  M8_METER_NAME,
  M9_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "550px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    overflowX: "auto",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "25px 0px",
  },
};

const ETPreport = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState([0.0]);

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    let url = `${URL}getShopwiseMetersData?shopNo=13&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      console.log("res?.data?.data" ,res?.data )
      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setAvgFlowRate(res.data.data.M10FR || 0.0);
      // setTimeout(() => {
      //   setLoading(false);
      //   toast.success("Data updated");
      // }, 1000);
      graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);


  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;


  const customDateData = [
    {
      meter: 7,
      title: M7_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M7FR),
      volume: getCustomVolume(graphData?.M7),
      labels: getCustomLabels(volChartData?.data?.M7),
      data:
        volChartData?.data?.M7 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M7),
    },
    {
      meter: 8,
      title: M8_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M8FR),
      volume: getCustomVolume(graphData?.M8),
      labels: getCustomLabels(volChartData?.data?.M8),
      data:
        volChartData?.data?.M8 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M8),
    },
    {
      meter: 9,
      title: M9_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M9FR),
      volume: getCustomVolume(graphData?.M9),
      labels: getCustomLabels(volChartData?.data?.M9),
      data:
        volChartData?.data?.M9 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M9),
    },
    {
      meter: 10,
      title: M10_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M10FR),
      volume: getCustomVolume(graphData?.M10),
      labels: getCustomLabels(volChartData?.data?.M10),
      data:
        volChartData?.data?.M10 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M10),
    },
    //
    {
      meter: 11,
      title: M11_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M11FR),
      volume: getCustomVolume(graphData?.M11),
      labels: getCustomLabels(volChartData?.data?.M11),
      data:
        volChartData?.data?.M11 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M11),
    },
    {
      meter: 12,
      title: M12_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M12FR),
      volume: getCustomVolume(graphData?.M12),
      labels: getCustomLabels(volChartData?.data?.M12),
      data:
        volChartData?.data?.M12 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M12),
    },
    {
      meter: 13,
      title: M13_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M13FR),
      volume: getCustomVolume(graphData?.M13),
      labels: getCustomLabels(volChartData?.data?.M13),
      data:
        volChartData?.data?.M13 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M13),
    },
    {
      meter: 14,
      title: M14_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M14FR),
      volume: getCustomVolume(graphData?.M14),
      labels: getCustomLabels(volChartData?.data?.M14),
      data:
        volChartData?.data?.M14 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M14),
    },
    {
      meter: 56,
      title: `${TML_METER_NUMBERS.M56} : ${M56_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M56FR),
      volume: getCustomVolume(graphData?.M56),
      labels: getCustomLabels(volChartData?.data?.M56),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M56 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M56),
    },
    {
      meter: 57,
      title: `${TML_METER_NUMBERS.M57} : ${M57_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M57FR),
      volume: getCustomVolume(graphData?.M57),
      labels: getCustomLabels(volChartData?.data?.M57),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M57 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M57),
    },
  ];

  const defaultData = [
    {
      meter: 7,
      title: M7_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M7FR),
      volume: getDefaultVolume(graphData?.M7, volChartData?.data?.M7, "M7TV"),
      //volume: 517,
      labels: getDefaultLabels(volChartData?.data?.M7),
      data: volChartData?.data?.M7.map((item) => item?.M7TV),
    },
    {
      meter: 8,
      title: M8_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M8FR),
      volume: getDefaultVolume(graphData?.M8, volChartData?.data?.M8, "M8TV"),
      // volume: "--",
      labels: getDefaultLabels(volChartData?.data?.M8),
      data: volChartData?.data?.M8.map((item) => item?.M8TV),
    },
    {
      meter: 9,
      title: M9_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M9FR),
      volume: getDefaultVolume(graphData?.M9, volChartData?.data?.M9, "M9TV"),
      //volume: "--",
      labels: getDefaultLabels(volChartData?.data?.M9),
      data: volChartData?.data?.M9.map((item) => item?.M9TV),
    },
    {
      meter: 10,
      title: M10_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M10FR),
      volume: getDefaultVolume(
        graphData?.M10,
        volChartData?.data?.M10,
        "M10TV"
      ),
      //volume: 1002,
      labels: getDefaultLabels(volChartData?.data?.M10),
      data: volChartData?.data?.M10.map((item) => item?.M10TV),
    },
    //
    {
      meter: 11,
      title: M11_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M11FR),
      // volume: getDefaultVolume(
      //   graphData?.M11,
      //   volChartData?.data?.M11,
      //   "M11TV"
      // ),
      volume: 1804,
      labels: getDefaultLabels(volChartData?.data?.M11),
      data: volChartData?.data?.M11?.map((item) => item?.M11TV),
    },
    {
      meter: 12,
      title: M12_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M12FR),
      // volume: getDefaultVolume(
      //   graphData?.M12,
      //   volChartData?.data?.M12,
      //   "M12TV"
      // ),
      volume: 1962,
      labels: getDefaultLabels(volChartData?.data?.M12),
      data: volChartData?.data?.M12?.map((item) => item?.M12TV),
    },
    {
      meter: 13,
      title: M13_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M13FR),
      // volume: getDefaultVolume(
      //   graphData?.M13,
      //   volChartData?.data?.M13,
      //   "M13TV"
      // ),
      volume: 303,
      labels: getDefaultLabels(volChartData?.data?.M13),
      data: volChartData?.data?.M13?.map((item) => item?.M13TV),
    },
    {
      meter: 14,
      title: M14_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M14FR),
      // volume: getDefaultVolume(
      //   graphData?.M14,
      //   volChartData?.data?.M14,
      //   "M14TV"
      // ),
      volume: 238,
      labels: getDefaultLabels(volChartData?.data?.M14),
      data: volChartData?.data?.M14?.map((item) => item?.M14TV),
    },
    {
      meter: 56,
      title: `${TML_METER_NUMBERS.M56} : ${M56_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M56FR),
      volume: getDefaultVolume(
        graphData?.M56,
        volChartData?.data?.M56,
        "M56TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M56),
      data: volChartData?.data?.M56?.map((item) => item?.M56TV),
    },
    {
      meter: 57,
      title: `${TML_METER_NUMBERS.M57} : ${M57_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M57FR),
      volume: getDefaultVolume(
        graphData?.M57,
        volChartData?.data?.M57,
        "M57TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M57),
      data: volChartData?.data?.M57?.map((item) => item?.M57TV),
    },
  ];

  const TotalVol = Math.round(graphData?.M10);
  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = volChartData?.data?.M10;
  // const array2 = volChartData?.data?.M5;
  const minLength = Math.min(array1?.length);

  const combinedArray = [];
  for (let i = 0; i < minLength; i++) {
    const combinedItem = {
      defaultTotal: Math.abs(array1[i].M10TV),
      customTotal: Math.abs(array1[i].M10TVDifference),
    };
    combinedArray.push(combinedItem);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={undefined}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/ETP">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Total Recycle Water</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={Math.abs(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {Math.abs(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={ setTrue === 0
                              ? [
                                  "00:00",
                                  "06:00",
                                  "12:00",
                                  "18:00",
                                  "23:00",
                                  "00:00",
                                ]
                              : getCustomLabels(volChartData?.data?.M10)}
                            Data={combinedArray.map((item) =>
                              setTrue === 0
                                ? item?.defaultTotal
                                : item?.customTotal
                            )}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {setTrue === 0
                              ? getDefaultVolume(
                                  graphData?.M10,
                                  volChartData?.data?.M10,
                                  "M10TV"
                                )
                              : getCustomVolume(graphData?.M10)}
                            {/* {getTotalVolume(
                              combinedArray.map((item) =>
                                setTrue === 0
                                  ? item?.defaultTotal
                                  : item?.customTotal
                              )
                            )} */}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={
                                item?.meter
                                  ? `/live-dashboard/${item.meter}/${item.title}`
                                  : null
                              }
                            >
                              <Meter
                                value={Math.abs(item.flowRate)}
                                maxValue={
                                  Math.abs(item.flowRate) > 1000 ? 50000 : 10000
                                }
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>
                              {!isNaN(Math.abs(item.flowRate))
                                ? Math.abs(item.flowRate)
                                : 0}
                            </div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>

            <AxisTrendAndTabularReport shopNo={13} token={token} />
          </Container>
        </>
      )}
      ,
    </div>
  );
};

export default ETPreport;
