import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  layout: {
    padding: {
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
    datalabels: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
      display: true,
    },
    y: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        display: false,
      },

      suggestedMax: 10,
      // stepSize: 2,
      //beginAtZero: true,
    },
  },
};

const VolumeChart = (props) => {
  const { Data, Labels, suggestedMax } = props;
  if (suggestedMax) {
    options.scales.y.suggestedMax = suggestedMax;
  }
  const labels = Labels;
  const data = {
    labels,
    datasets: [
      {
        label: "Volume",
        data: Data,
        borderColor: "#375EAD",
        backgroundColor: "#375EAD",
        // pointStyle: false,
      },
    ],
  };

  return (
    <>
      <Line
        options={options}
        data={data}
        width={props.width}
        height={props.height}
      />
    </>
  );
};
export default VolumeChart;
