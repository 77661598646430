import React from "react";
import "./BillingTable.css";

export const BillingTable = ({ data }) => {
  console.log("billing table ", data);
  return (
    <div>
      <table>
        <tr className="FirstRow">
          <th>Serial Number</th>
          <th>Consumption (KL)</th>
          <th>From</th>
          <th>To</th>
        </tr>
        {/* {data?.map((item, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{item?.volume}</td>
            <td>
              {item?.month} - {item?.year}
            </td>
            <td>
              {item?.to} - {item?.year}
            </td>
          </tr>
        ))} */}
        <tr>
          <td>1</td>
          <td>850</td>
          <td>Jan 2023</td>
          <td>Feb 2023</td>
        </tr>
        <tr>
          <td>2</td>
          <td>811</td>
          <td>Feb 2023</td>
          <td>Mar 2023</td>
        </tr>
        <tr>
          <td>3</td>
          <td>896</td>
          <td>Mar 2023</td>
          <td>Apr 2023</td>
        </tr>

        <tr className="LastRow">
          <td>4</td>
          <td>896</td>
          <td>Apr 2023</td>
          <td>May 2023</td>
        </tr>
      </table>
    </div>
  );
};
