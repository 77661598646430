import { Container, Navbar } from "react-bootstrap";
import "../Styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";

const styles = {
  NavBarBrand: {
    display: "flex",
    alignItems: "center",
  },
  menu: { marginLeft: "30px" },
};
const NavBar = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Navbar className="custom-navbar" sticky="top">
      <Container fluid style={{ padding: "0px 42px" }}>
        <Navbar.Brand href="/homepage" style={styles.NavBarBrand}>
          <img
            src={require("../Assets/more.png")}
            width="34"
            height="34"
            alt="logo"
          />

          <Navbar.Text className="navText">TATA Motors Pune</Navbar.Text>
        </Navbar.Brand>

        <Navbar.Brand style={styles.NavBarBrand}>
          <img
            src={require("../Assets/Netp_Logo_2.10.png")}
            width="125"
            height="40"
            alt="logo"
            onClick={handleBackClick}
            style={{ cursor: "pointer" }}
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavBar;
