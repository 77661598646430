import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Col, Container, Row, Button } from "react-bootstrap";
import { ReportTable } from "./ReportTable";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import MultiAxisLineChart from "./Components/MultiAxisLineChart";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";

import { Link } from "react-router-dom";

import AxisTrendAndTabularReport from "./Components/AxisTrendAndTabularReport";
import {
  M17_METER_NAME,
  M24_METER_NAME,
  M62_METER_NAME,
  TML_METER_NUMBERS,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    // height: "700px",
    height: "475px",
    border: "none",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    // margin: "50px 0px",
    margin: "25px 0px",
  },
};

const NexonBIWShopReport = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState([]);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getShopwiseMetersData?shopNo=2&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 1000);
      // graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for M24fr and M62fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr-data_Nexon?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      // console.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const getTotalProcessData = (array1, array2) => {
    const arrayLength = Math.max(array1?.length, array2?.length);
    while (array1?.length < arrayLength) {
      array1.push(array1[array1.length - 1]);
    }

    while (array2?.length < arrayLength) {
      array2.push(array2[array2.length - 1]);
    }
    let combinedArray = [];
    for (let i = 0; i < arrayLength; i++) {
      combinedArray.push(array1[i] + array2[i]);
    }
    return combinedArray;
  };

  const getAddition = (value1, value2) => {
    console.log("value1 , value2", value1, value2);
    const v1 = isNaN(Number(value1)) ? 0 : Number(value1);
    const v2 = isNaN(Number(value2)) ? 0 : Number(value2);
    const total = (v1 + v2).toFixed();
    return total;
  };

  const customDateData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getCustomVolume(graphData?.M24),
      labels: getCustomLabels(volChartData?.data?.M24),
      data:
        volChartData?.data?.M24 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M24),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M62FR?.toFixed(2) + graphData?.M17FR?.toFixed(2)) / 2
      ),
      volume: getAddition(
        getCustomVolume(graphData?.M62),
        getCustomVolume(graphData?.M17)
      ),
      labels: getCustomLabels(volChartData?.data?.M62),
      data: getTotalProcessData(
        volChartData?.data?.M62
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M62)
          : [],
        volChartData?.data?.M17
          ? formatLargeRecordsDataTo10Values(volChartData?.data?.M17)
          : []
      ),
    },
    {
      meter: 24,
      title: `${TML_METER_NUMBERS.M24} : ${M24_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getCustomVolume(graphData?.M24),
      labels: getCustomLabels(volChartData?.data?.M24),
      data:
        volChartData?.data?.M24 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M24),
    },
    {
      meter: 17,
      title: `${TML_METER_NUMBERS.M17} : ${M17_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M17FR),
      volume: getCustomVolume(graphData?.M17, "M17"),
      labels: getCustomLabels(volChartData?.data?.M17),
      data:
        volChartData?.data?.M17 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M17),
    },
    null,
    {
      meter: 62,
      title: `${TML_METER_NUMBERS.M62} : ${M62_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M62FR),
      volume: getCustomVolume(graphData?.M62),
      labels: getCustomLabels(volChartData?.data?.M62),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M62 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M62),
    },
  ];

  const defaultData = [
    {
      title: "Total Drinking Water",
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getDefaultVolume(
        graphData?.M24,
        volChartData?.data?.M24,
        "M24TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M24),
      data: volChartData?.data?.M24?.map((item) => item?.M24TV),
    },
    {
      title: "Total Process Water",
      flowRate: getFormattedNumber(
        (graphData?.M62FR?.toFixed(2) + graphData?.M17FR?.toFixed(2)) / 2
      ),
      volume: getAddition(
        getDefaultVolume(graphData?.M62, volChartData?.data?.M62, "M62TV"),
        getDefaultVolume(graphData?.M17, volChartData?.data?.M17, "M17TV")
      ),
      labels: getDefaultLabels(
        volChartData?.data?.M62?.length > volChartData?.data?.M17?.length
          ? volChartData?.data?.M62
          : volChartData?.data?.M17
      ),
      data: getTotalProcessData(
        volChartData?.data?.M62?.map((item) => item?.M62TV),
        volChartData?.data?.M17?.map((item) => item?.M17TV)
      ),
    },
    {
      meter: 24,
      title: `${TML_METER_NUMBERS.M24} : ${M24_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getDefaultVolume(
        graphData?.M24,
        volChartData?.data?.M24,
        "M24TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M24),
      data: volChartData?.data?.M24?.map((item) => item?.M24TV) || 0,
    },
    {
      meter: 17,
      title: `${TML_METER_NUMBERS.M17} : ${M17_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M17FR),
      volume: getDefaultVolume(
        graphData?.M17,
        volChartData?.data?.M17,
        "M17TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M17),
      data: volChartData?.data?.M17?.map((item) => item?.M17TV),
    },
    null,
    {
      meter: 62,
      title: `${TML_METER_NUMBERS.M62} : ${M62_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M62FR),
      volume: getDefaultVolume(
        graphData?.M62,
        volChartData?.data?.M62,
        "M62TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M62),
      data: volChartData?.data?.M62?.map((item) => item?.M62TV),
    },
  ];

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = Data[0]?.data;
  const array2 = Data[1]?.data;

  const maxLength = Math.max(array1?.length, array2?.length); // Update minLength

  while (array1?.length < maxLength) {
    array1.push(0);
  }

  while (array2?.length < maxLength) {
    array2.push(0);
  }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const v1 = isNaN(Number(array1[i])) ? 0 : Number(array1[i]);
    const v2 = isNaN(Number(array2[i])) ? 0 : Number(array2[i]);
    const total = v1 + v2;
    combinedArray.push(total);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchData={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Nexon BIW">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}>Weld Shop Total </div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={Math.abs(avgFlowRate)}
                            maxValue={
                              Math.abs(avgFlowRate) > 10000 ? 50000 : 20000
                            }
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {Math.abs(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? ["00:00", "06:00", "12:00", "18:00", "23:59"]
                                : getCustomLabels(volChartData?.data?.M24)
                            }
                            Data={combinedArray}
                            suggestedMax={10000}
                          />
                          <div style={styles.totalVolDiv}>
                            {getAddition(Data[0]?.volume, Data[1]?.volume)}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data?.map((item, index) =>
                item ? (
                  <Col
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    style={styles.column}
                    key={index}
                  >
                    <div className="card" style={styles.card}>
                      <>
                        <div style={styles.titleDiv}>
                          <div style={styles.title}>{item.title}</div>
                          <div className="Box">
                            <div>
                              <Link
                                to={
                                  item?.meter
                                    ? `/live-dashboard/${item.meter}/${item.title}`
                                    : null
                                }
                              >
                                <Meter
                                  value={Math.abs(item.flowRate)}
                                  maxValue={
                                    Math.abs(item.flowRate) > 1000
                                      ? 50000
                                      : 10000
                                  }
                                  width={280}
                                  height={160}
                                />
                              </Link>
                              <div style={styles.FlowRate}>
                                {!isNaN(Math.abs(item.flowRate))
                                  ? Math.abs(item.flowRate)
                                  : 0}
                              </div>
                              <div style={styles.flowRateVol}>
                                Flow Rate (
                                <span>
                                  &nbsp;m<sup>3</sup>
                                </span>
                                /h)
                              </div>
                            </div>
                            <div>
                              <VolumeChart
                                width={280}
                                height={160}
                                Labels={item.labels}
                                Data={item.data}
                              />
                              <div style={styles.Volume}>{item?.volume}</div>

                              <div style={styles.flowRateVol}>
                                Volume (
                                <span>
                                  &nbsp;m<sup>3</sup>
                                </span>
                                )
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </Col>
                ) : (
                  <Col sm={12} xs={12} md={12} lg={12} xl={6} xxl={6}></Col>
                )
              )}
            </Row>

            <AxisTrendAndTabularReport shopNo={2} token={token} />
          </Container>
        </>
      )}
    </div>
  );
};

export default NexonBIWShopReport;
