// MultiAxisCommon.js

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ToastContext from "../../Context/ToastContext";
import html2canvas from "html2canvas";
import MultiAxisChartFilter from "./MultiAxisChartFilter";

const MultiAxisCommon = ({ shopNo, meterConfig, color }) => {
  const { token } = useAuth();
  //   const URL = `http://localhost:9000/api/getShopwiseMetersData?shopNo=${shopNo}`;
  //   console.log("URL", URL);
  const { toast } = useContext(ToastContext);
  const [MapData, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [dateError, setDateError] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      //   if (!dateFilter.startdate || !dateFilter.enddate || !dateFilter.days) {
      //     throw new Error("Please select start and end dates");
      //   }

      const URL = `http://localhost:9000/api/getShopwiseMetersData?shopNo=${shopNo}&days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
      const response = await axios.get(URL, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setData(response?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      if (err.response?.data?.message === "Unauthorized") {
        setAuthorized(false);
      }
      toast.error("Failed to fetch data");
    }
  }, [dateFilter, shopNo, toast, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setTrue =
    dateFilter.days.length ||
    (dateFilter.startdate.length && dateFilter.enddate.length);

  let label1 = [];
  for (const meter of meterConfig) {
    const meterData = MapData?.data?.[meter.key];

    if (meterData && meterData.length > 0 && meterData[0]?.D) {
      const meterLabel = meter.label; // Get the meter label
      label1 = meterData.map((item) => {
        const dateObj = new Date(item?.D);
        return {
          label: meterLabel,
          date: format(dateObj, "dd/MM"),
        };
      });
      break; // Exit the loop once valid data is found
    }
  }

  let label2 = [];
  for (const meter of meterConfig) {
    const meterData = MapData?.data?.[meter.key];
    if (meterData && meterData.length > 0 && meterData[0]?.T) {
      label2 = meterData.map((item) => {
        const timeString = item?.T;
        return format(new Date(`1970-01-01T${timeString}`), "HH:mm");
      });
      break; // Exit the loop once valid data is found
    }
  }

  //   const customDateData = {
  //     labels: label1,
  //     datasets: meterConfig?.map((meter) => ({
  //       label: meter.label,
  //       data: MapData?.data?.[meter.key]?.map(
  //         (item) => item?.[`${meter.key}TVDifference`]
  //       ),

  //       borderColor: meter.borderColor,
  //       backgroundColor: meter.backgroundColor,
  //       yAxisID: mete r.yAxisID,
  //     })),
  //   };

  const customDateData = {
    labels: label1,
    datasets: meterConfig?.map((meter, index) => ({
      label: meter.label,
      data: MapData?.data?.[meter.key]?.map(
        (item) => item?.[`${meter.key}TVDifference`]
      ),
      borderColor: color[index % color.length], // Set line color based on index
      backgroundColor: meter.backgroundColor,
      yAxisID: meter.yAxisID,
    })),
  };

  const defaultData = {
    labels: label2,
    datasets: meterConfig?.map((meter) => ({
      label: meter.label,
      data: MapData?.data?.[meter.key]?.map((item) => item?.[`${meter.key}TV`]),
      borderColor: meter.borderColor,
      backgroundColor: meter.backgroundColor,
      yAxisID: meter.yAxisID,
    })),
  };

  const handleCSVDownload = (meterConfig) => {
    if (MapData?.data) {
      const csvData = MapData?.data?.M23.map((item, index) => {
        let DateTime;
        if (setTrue === 0) {
          DateTime = item.T;
        } else {
          DateTime = item.D;
        }
        const rowData = {
          DateTime,
          //   [meterName]: MapData.data[meterName][index]?.[`${meterName}TV`] || 0,
        };

        if (setTrue !== 0) {
          //   rowData[meterName] =
          //     MapData.data[meterName][index]?.[`${meterName}TVDifference`] || 0;
        }
        return rowData;
      });

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });

      //   saveAs(csvBlob, `${meterName}_Data.csv`);
      toast.success("Data downloading, please wait...");
    }
  };

  const handlePDFDownload = async (MapData) => {
    const meterDetails = meterConfig?.map((meter) => meter.key);
    console.log("meterDetails", meterDetails);

    const headers = meterDetails.label; // Include column names

    const pdfData = MapData?.data;

    let firstValidMeterData = null;
    const validDataArrays = [];

    // Check if pdfData is valid and contains data
    if (pdfData && Object.keys(pdfData).length > 0) {
      console.log("Valid meter data found.");

      // Process each meter data array
      for (const key of Object.keys(pdfData)) {
        const dataArray = pdfData[key];

        if (Array.isArray(dataArray) && dataArray.length > 0) {
          console.log("Processing data array:", dataArray);

          // Filter out invalid values or convert them to numbers if necessary
          const filteredDataArray = dataArray.map((item) => {
            const value = parseFloat(item?.[`${meterDetails}TVDifference`]);
            return isNaN(value) ? 0 : value;
          });

          // Example: Calculate sum of numerical values in the array
          const sum = filteredDataArray.reduce((acc, curr) => acc + curr, 0);
          console.log("Sum of values:", sum);

          // Example: Find maximum value in the array
          const max = Math.max(...filteredDataArray.filter((v) => !isNaN(v)));
          console.log("Maximum value:", max);

          // Example: Perform other operations as needed

          // Store valid data arrays
          validDataArrays.push(dataArray);
          if (!firstValidMeterData) {
            firstValidMeterData = dataArray;
          }
        }
      }
    } else {
      console.log("No valid meter data found.");
    }

    // Generate rows based on the meter data
    const rows = [];
    for (const key of Object.keys(pdfData)) {
      const dataArray = pdfData[key];
      if (Array.isArray(dataArray)) {
        for (const item of dataArray) {
          const date = format(new Date(item?.D), "dd/MM/yyyy");
          const value =
            item?.[`${meterDetails}TVDifference`]?.toFixed() ?? "N/A";
          console.log("value", meterDetails);
          rows.push([date, value]);
        }
      }
    }

    // Logic for PDF generation with headers and rows...
    const doc = new jsPDF("l", "pt", "letter");

    doc.text("Meter Data", 40, 50); // Title

    // Add headers to the PDF
    doc.autoTable({
      head: [headers],
      startY: 70,
    });

    // Add rows to the PDF
    doc.autoTable({
      body: rows,
      startY: 90,
    });

    // Save the PDF
    doc.save("MeterData.pdf");
  };

  const DataDownload = async (meterName) => {
    if (!authorized) {
      return toast.error("Unauthorized");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than current date");
    }
    if (dateError) {
      return toast.error("Data not available for download");
    }
    handleCSVDownload(meterName);
    await handlePDFDownload(MapData);
    const chart = document.getElementById("lineChart");
    chart.style.backgroundColor = "";
    chart.style.paddingTop = "";
    chart.style.borderRadius = "";
  };

  //
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 15,
        },
      },
      title: {
        display: false,
        text: "Multi Axis",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    layout: {
      padding: {
        bottom: 50,
        left: 10,
        top: 0,
        right: 10,
      },
    },
  };

  return (
    <>
      <div style={{ margin: "auto" }}>
        {/* <div style={{ margin: "auto" }}> */}
        <MultiAxisChartFilter
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          fetchData={fetchData}
          DataDownload={DataDownload}
        />
        {MapData?.data ? (
          <Line
            id="lineChart"
            options={options}
            data={setTrue === 0 ? defaultData : customDateData}
            height={400}
            width={1350}
          />
        ) : (
          <Line
            id="lineChart"
            options={options}
            data={customDateData}
            height={400}
            width={1350}
          />
        )}
      </div>
    </>
  );
};

export default MultiAxisCommon;
