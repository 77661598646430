import React from "react";
import "./style.css";
import { Modal } from "react-bootstrap";
import CheckMeterDetails from "./CheckMeterDetails";
import Meter from "../../Reports/Components/Meter";

const styles = {
  Title: {
    textAlign: "center",
    width: "100%",
    fontWeight: "600",
    color: "#375EAD",
  },
  Body: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  MainDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "200px",
  },
  SubDiv: { color: "#375EAD", fontSize: "18px", fontWeight: "700" },
  SubDiv1: {
    color: "#375EAD",
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "20px",
  },
  Span: {
    color: "black",
    fontWeight: "400",
  },
  CheckMeterDiv: {
    borderRadius: "2.667px",
    border: "0.667px solid #375EAD",
    background: "#375EAD",
    color: "#FFF",
    fontSize: "15px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "272px",
    height: "34px",
    marginBottom: "10px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "255px",
    height: "22px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "-22px",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
};

// const getImageSource = (flowRate) => {
//   // Example: You can replace these conditions with your own logic
//   if (flowRate > 50) {
//     return require("../../Assets/FlowMeter.png");
//   } else {
//     return require("../../Assets/FlowMeter.png");
//   }
// };
const MeterDetails = (props) => {
  const [checkMeterModal, setCheckMeterModal] = React.useState(false);
  const OpenCheckMeterModal = () => {
    props.onHide();
    setCheckMeterModal(true);
  };
  const { meterNames, dynamicName } = props;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={styles.Title}>
            Flow Meter Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={styles.Body}>
            <div>
              {/* <img
                className="img"
                alt="Vector"
                src={require("../../Assets/FlowMeter.png")}
                // src={getImageSource()}
                height={250}
                width={300}
                flowRate={props.flowRate}
              /> */}
              {/* <Meter width={280} height={160} flowRate={props.flowRate} /> */}
              <Meter
                value={props.flowRate}
                maxValue={456 > 10000 ? 50000 : 20000}
                width={300}
                height={180}
              />
              <div style={styles.flowRateDiv}>
                {/* {Math.abs(avgFlowRate)} */}
                {Math.abs(props.flowRate || 0).toFixed(2)}
              </div>
              <div style={styles.flowRateVol}>
                Flow Rate (
                <span>
                  &nbsp;m<sup>3</sup>
                </span>
                /h)
              </div>
            </div>
            <div style={styles.MainDiv}>
              <div style={styles.SubDiv}>
                {/* Name : <span style={styles.Span}>Drinking Line</span>{" "} */}
                Name : <span style={styles.Span}>{dynamicName}</span>{" "}
              </div>
              <div style={styles.SubDiv}>
                Location : <span style={styles.Span}>{meterNames} </span>{" "}
              </div>
              <div style={styles.SubDiv1}>
                Consumption(24hr) :
                <span style={styles.Span}>
                  {typeof props.consumptionValue === "number"
                    ? props.consumptionValue.toFixed(2)
                    : "0.00"}
                </span>
              </div>
              <button
                style={styles.CheckMeterDiv}
                onClick={OpenCheckMeterModal}
              >
                Check Meter Details{" "}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CheckMeterDetails
        show={checkMeterModal}
        onShow={props.onShow}
        onHide={() => setCheckMeterModal(false)}
        meterNames={meterNames}
      />
    </>
  );
};

export default MeterDetails;
