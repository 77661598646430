import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Home Page/HomePage";
import PlantBlocks from "./Plant Blocks/PlantBlocks";
import PlantShops from "./Plant Shops/PlantShops";
import CXODashboard from "./CXO Dashboard/CXODashboard";
import Bill from "./Billing/Bill";
import DetailedCharts from "./Detailed Charts/DetailedCharts";
import ProcessWaterLine from "./Water Networks/ProcessWaterLine/ProcessWaterLine";
import DrinkingWaterLine from "./Water Networks/DrinkingWaterLine/DrinkingWaterLine";
import HoricultureWater from "./Water Networks/HoricultureWater/HoricultureWater";
import PaintShop from "./Water Networks/Paint Shop/PaintShop";
import PumpHouse from "./Water Networks/PumpHouse/PaintShop";
import PaintShopCXO from "./PaintShopCXO/PaintShopCXO";
import { AuthProvider } from "./Context/AuthContext";
import { DataProvider } from "./Context/DataContext";
import { ToastProvider } from "./Context/ToastContext";
import LogIn from "./User Control/Login";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PlantBlockReport from "./Reports/PlantBlockReport";
import PlantShopReport from "./Reports/PlantShopReport";
import ModuleSelection from "./Module Selection/ModuleSelection";
import Electricity from "./Electricity/Electricity";
import Food from "./Food/Food";
import ETPreport from "./Reports/ETPreport";
import UploadFile from "./Electricity/UploadFile";
import Etp1 from "./Water Networks/ETP/Etp1";
import PaintShopReports from "./Reports/PaintShopReports";
import Tcf1ShopReports from "./Reports/Tcf1ShopReports";
import Tcf2ShopReports from "./Reports/Tcf2ShopReports";
import JLRShopReports from "./Reports/JLRShopReports";
import PressShopReports from "./Reports/PressShopReports";
import HistoryReports from "./HistoryReports/HistoryReports";
import MonthWiseHistoryReport from "./HistoryReports/MonthWiseHistoryReports";
import NexonBIWShopReport from "./Reports/NexonBIWShopReport";
import Canteen1Report from "./Reports/Canteen1Report";
import Canteen2Report from "./Reports/Canteen2Report";
import PowerTrainReport from "./Reports/PowerTrainReport";
import ShopWiseBilling from "./ShopwiseBilling/ShopWiseBilling";
import NovaBiwReport from "./Reports/NovaBiwReport";
import AltrozBiwReport from "./Reports/AltrozBiwReport";
import HarrierSafariReport from "./Reports/HarrierSafariReport";
import CompressorHouseReport from "./Reports/CompressorHouseReport";
import UserLogIn from "./User Control/UserLogin";
import LiveTrend from "./Reports/LiveTrend";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <DataProvider>
          <ToastProvider>
            <Routes>
              <Route path="/login" element={<UserLogIn />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/homepage" element={<HomePage />} />
                <Route path="/selectmodule" element={<ModuleSelection />} />
                <Route path="/upload" element={<UploadFile />} />
                <Route path="/electricity" element={<Electricity />} />
                <Route path="/food" element={<Food />} />
                <Route path="/reports/plantblocks" element={<PlantBlocks />} />
                <Route path="/reports/plantshops" element={<PlantShops />} />

                <Route path="/reports/canteen2" element={<Canteen2Report />} />
                <Route path="/reports/etpreport" element={<ETPreport />} />
                <Route
                  path="/reports/nexonBiw"
                  element={<NexonBIWShopReport />}
                />
                <Route path="/reports/novaBiw" element={<NovaBiwReport />} />
                <Route
                  path="/reports/altroxBiw"
                  element={<AltrozBiwReport />}
                />
                <Route
                  path="/reports/harrierSafari"
                  element={<HarrierSafariReport />}
                />
                <Route
                  path="/reports/powerTrain"
                  element={<PowerTrainReport />}
                />
                <Route path="/reports/canteen1" element={<Canteen1Report />} />
                <Route path="/cxodashboard" element={<CXODashboard />} />
                <Route path="/waternetwork/bill/:key" element={<Bill />} />
                <Route path="/detailedcharts" element={<DetailedCharts />} />
                <Route
                  path="/waternetwork/processwaterline"
                  element={<ProcessWaterLine />}
                />
                <Route
                  path="/waternetwork/drinkingwaterline"
                  element={<DrinkingWaterLine />}
                />
                <Route
                  path="/waternetwork/horiculturewaterline"
                  element={<HoricultureWater />}
                />
                <Route
                  path="/reports/blockreport"
                  element={<PlantBlockReport />}
                />
                <Route
                  path="/reports/shopreport"
                  element={<PlantShopReport />}
                />
                <Route
                  path="/reports/paintreports"
                  element={<PaintShopReports />}
                />
                <Route
                  path="/reports/tcf1reports"
                  element={<Tcf1ShopReports />}
                />
                <Route
                  path="/reports/tcf2reports"
                  element={<Tcf2ShopReports />}
                />
                <Route
                  path="/reports/jlrreports"
                  element={<JLRShopReports />}
                />
                <Route
                  path="/reports/pressreports"
                  element={<PressShopReports />}
                />
                <Route
                  path="/reports/compressorHouse"
                  element={<CompressorHouseReport />}
                />
                <Route path="/waternetwork/paintshop" element={<PaintShop />} />
                <Route path="/waternetwork/etp" element={<Etp1 />} />
                <Route path="/waternetwork/pumphouse" element={<PumpHouse />} />
                <Route
                  path="/waternetwork/paintshopcxo"
                  element={<PaintShopCXO />}
                />
                <Route path="/history" element={<HistoryReports />} />
                <Route
                  path="/monthhistory"
                  element={<MonthWiseHistoryReport />}
                />
                <Route
                  path="/shopbilling/:shopname"
                  element={<ShopWiseBilling />}
                />
                <Route
                  path="/live-dashboard/:meter/:title"
                  element={<LiveTrend />}
                />
              </Route>
            </Routes>
          </ToastProvider>
        </DataProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
