import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ToastContext from "../../Context/ToastContext";
import MultiAxisChartFilter from "./MultiAxisChartFilter";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "center",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 15,
      },
    },
    title: {
      display: false,
      text: "Multi Axis",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  layout: {
    padding: {
      bottom: 50,
      left: 10,
      top: 0,
      right: 10,
    },
  },
};

const MultiAxisChartsPressShop = () => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const { toast } = useContext(ToastContext);
  const [MapData, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [dateError, setDateError] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const fetchData = useCallback(async () => {
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than curren date");
    }
    setDateError(false);
    let url = `${URL}line-chart_press_shop?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      console.log("res---", res);
      setData(res?.data);
    } catch (err) {
      if (err.response.data.message === "Unauthorized") setAuthorized(false);
      console.log(err);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    (dateFilter.startdate.length && dateFilter.enddate.length);

  const label1 =
    MapData?.data?.M23[0]?.D &&
    MapData?.data?.M23.map((item) => {
      const dateObj = new Date(item?.D);
      return format(dateObj, "dd/MM");
    });

  const label2 =
    MapData?.data?.M23[0]?.T &&
    MapData?.data?.M23.map((item) => {
      const timeString = item?.T;
      return format(new Date(`1970-01-01T${timeString}`), "HH:mm");
    });

  const customDateData = {
    labels: label1,
    datasets: [
      {
        label: "Press Shop Drinking",
        data: MapData?.data?.M23?.map((item) => item?.M23TVDifference),
        borderColor: "#d4d41c",
        backgroundColor: "#d4d41c",
        yAxisID: "y",
      },
      {
        label: "Press Shop Process",
        data: MapData?.data?.M61?.map((item) => item?.M61TVDifference),
        borderColor: "#3d872e",
        backgroundColor: "#3d872e",
        yAxisID: "y1",
      },
    ],
  };

  const defaultData = {
    labels: label2,
    datasets: [
      {
        label: "Press Shop Drinking",
        data: MapData?.data?.M23?.map((item) => item?.M23TV),
        borderColor: "#d4d41c",
        backgroundColor: "#d4d41c",
        yAxisID: "y1",
      },
      {
        label: "Press Shop Process",
        data: MapData?.data?.M61?.map((item) => item?.M61TV),
        borderColor: "#3d872e",
        backgroundColor: "#3d872e",
        yAxisID: "y1",
      },
    ],
  };
  const handleCSVDownload = () => {
    if (MapData?.data) {
      const csvData = MapData.data.M61.map((item, index) => {
        let DateTime;
        if (setTrue === 0) {
          DateTime = item.T;
        } else {
          DateTime = item.D;
        }
        const rowData = {
          DateTime,
          "Press Shop Drinking": MapData.data.M23[index]?.M23TV || 0,
          "Press Shop Process": MapData.data.M61[index]?.M61TV || 0,
        };

        if (setTrue !== 0) {
          rowData["Press Shop Drinking"] =
            MapData.data.M23[index]?.M23TVDifference || 0;
          rowData["Press Shop Process"] =
            MapData.data.M61[index]?.M61TVDifference || 0;
        }
        return rowData;
      });

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });

      saveAs(csvBlob, "Data.csv");
      toast.success("Data downloading, please wait...");
    }
  };

  // ... (previous code)

  const handlePDFDownload = async () => {
    const headers = [
      setTrue === 0 ? "Time" : "Date",
      "Press Shop Drinking",
      "Press Shop Process",
    ];

    const pdfData = MapData?.data;

    if (pdfData?.M23 && pdfData?.M61) {
      const rows = [];

      if (setTrue === 0) {
        for (let i = 0; i < pdfData.M23.length; i++) {
          const timeWithSeconds = pdfData.M23[i]?.T;
          const time = timeWithSeconds?.split(":")?.slice(0, 2)?.join(":");
          const row = [
            time,
            pdfData.M23[i]?.M23TV?.toFixed(),
            pdfData.M61[i]?.M61TV?.toFixed(),
          ];

          rows.push(row);
        }
      } else {
        for (let i = 0; i < pdfData.M23.length; i++) {
          const date = format(new Date(pdfData.M23[i]?.D), "dd/MM/yyyy");
          const row = [
            date,
            pdfData.M23[i]?.M23TVDifference?.toFixed(),
            pdfData.M61[i]?.M61TVDifference?.toFixed(),
          ];
          rows.push(row);
        }
      }

      const date = new Date().toLocaleString();
      const doc = new jsPDF("l", "pt", "letter");

      let y = 10;
      doc.setLineWidth(2);
      doc.text(350, (y += 30), "Report");

      const chart = document.getElementById("lineChart");
      chart.style.backgroundColor = "#F9F9F9";
      chart.style.paddingTop = "40px";
      chart.style.borderRadius = "10px";

      const canvas = await html2canvas(chart);
      const imgData = canvas.toDataURL("image/jpeg");
      doc.addImage(
        imgData,
        "JPEG",
        40,
        (y += 20),
        doc.internal.pageSize.getWidth() - 70,
        250
      );
      doc.setFontSize(8);
      doc.text(`Downloaded at: ${date}`, 170, 22, null, null, "right");
      // doc.autoTable({
      //   head: [headers],
      //   body: rows,
      //   startY: 340,
      //   theme: "grid",
      //   tableWidth: "100%",
      //   headStyles: { fontSize: 8, halign: "center" },
      //   columnStyles: {
      //     // ... (your column styles)
      //   },
      // });
      const columnStyles = {};

      // Center-align content for all columns
      for (let i = 0; i < headers.length; i++) {
        columnStyles[i] = { halign: "center" };
      }

      doc.autoTable({
        head: [headers],
        body: rows,
        startY: 340,
        theme: "grid",
        tableWidth: "100%",
        headStyles: {
          fontSize: 8,
          halign: "center",
          rowHeight: 25,
          margin: { bottom: 20 },
        },
        columnStyles: columnStyles,
      });

      doc.save("Data.pdf");
      toast.success("Data downloading, please wait...");
    } else {
      console.error("pdfData.M23 or pdfData.M61 is undefined");
    }
  };

  // ... (rest of the code)

  const DataDownload = async () => {
    if (!authorized) {
      return toast.error("Unauthorized");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than current date");
    }
    if (dateError) {
      return toast.error("Data not available for download");
    }
    handleCSVDownload();
    await handlePDFDownload();
    const chart = document.getElementById("lineChart");
    chart.style.backgroundColor = "";
    chart.style.paddingTop = "";
    chart.style.borderRadius = "";
  };
  return (
    <>
      <div style={{ margin: "auto" }}>
        <MultiAxisChartFilter
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          fetchData={fetchData}
          DataDownload={DataDownload}
        />
        {MapData?.data ? (
          <Line
            id="lineChart"
            options={options}
            data={setTrue === 0 ? defaultData : customDateData}
            height={400}
            width={1350}
          />
        ) : (
          <Line
            id="lineChart"
            options={options}
            data={customDateData}
            height={400}
            width={1350}
          />
        )}
      </div>
    </>
  );
};
export default MultiAxisChartsPressShop;
