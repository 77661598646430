import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./Styles.css";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          padding: 25,
        },
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "white",
        font: {
          weight: "700",
          size: 16,
        },
      },
    },
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },
  };

  return (
    <>
      <div style={{ margin: "auto" }}>
        <Pie
          data={props.data}
          options={options}
          className="custom-chart"
          height={props.height}
          width={props.width}
        />
      </div>
    </>
  );
};

export default PieChart;
