import React, { useState } from "react";
import "./style.css";
import EtpNetTabs from "../Components/EtpNetTabs";
import Tabs from "../../Layout/Tabs";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import MeterDetails from "../Modals/MeterDetails";

const Etp1 = () => {
  const [openMeterModal, setOpenMeterModal] = useState(false);
  // const [selectedMeter, setSelectedmeters] = useState("");
  const [meterName, setMeterName] = useState("");
  const [value, setValue] = useState("");
  const [consumptionValue, setConsumptionValue] = useState(0);
  const [flowRate, setFlowRate] = useState(0);
  const { token } = useAuth();
  // const [totalVolume, setTotalVolume] = useState([]);
  const [meterClicked, setMeterClicked] = useState(false);
  // const [openMeterModal, setOpenMeterModal] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  // const [flowRate, setFlowRate] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [meterNames, setMeterNames] = useState([]);
  // const [meterClicked, setMeterClicked] = useState(false);

  const DrinkingArr = [
    {
      M1: "Pump House-4",
      M2: "Pump House-4",
      M3: "Pump House-4",
      M4: "Pump House-4",
      M50: "Pump House-4",
      M51: "Pump House-4",
    },
    {
      M18: "Paint Shop",
      M20: "Paint Shop",
    },
    {
      M27: "Office Block",
      M28: "Office Block",
      M29: "Office Block",
      M30: "Office Block",
    },

    {
      M23: "Press Shop",
    },
    {
      M24: "Weld Shop",
    },
    {
      M7: "ETP",
      M8: "ETP",
      M9: "ETP",
      M10: "ETP",
      M11: "ETP",
      M12: "ETP",
      M13: "ETP",
      M14: "ETP",
      M56: "ETP",
      M57: "ETP",
      M54: "ETP",
    },
  ];

  const extractPropertyValues = (arr, propertyNames) => {
    return arr.map((item) =>
      propertyNames.map((propertyName) => item[propertyName])
    );
  };

  const handleMeters = async (flag, meter) => {
    let url = `${process.env.REACT_APP_URL}get-meters-data`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`, // Replace with your actual token
        },
      });

      const { meterName, value, data } = response.data;

      // Moved setMeterNames and setSelectedMeter outside of handleMeters
      setMeterNames(extractPropertyValues(DrinkingArr, [meter]));
      setSelectedMeter(meter);
      //
      const meterData = response.data.data;
      console.log(
        "Meter Data:",
        meter,
        meterData,
        meterData.hasOwnProperty(`${meter}FR`),
        meterData.hasOwnProperty(`${meter}`)
      );

      if (meterData) {
        // Check if the meter has Flow Rate (FR) and Total Volume (TV) properties
        if (
          meterData.hasOwnProperty(`${meter}FR`) &&
          meterData.hasOwnProperty(`${meter}`)
        ) {
          const flowRate = meterData[`${meter}FR`];
          const totalVolume = meterData[`${meter}`];
          console.log("Flow Rate:", flowRate);
          console.log("Total Volume:", totalVolume);
          // Set the state variables for Flow Rate and Total Volume
          setFlowRate(flowRate);
          setTotalVolume(totalVolume);

          // You can use flowRate and totalVolume values as needed in your component
        }
      }

      setOpenMeterModal(true);
      setMeterClicked(true);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };
  const dynamicName = "Horticulture Water Line";

  return (
    <>
      <Tabs />
      <EtpNetTabs />

      <div className="etp">
        <div className="div">
          <div className="overlap-group">
            <div className="overlap-2">
              <img
                className="line"
                alt="Line"
                src={require("./img/line-8.png")}
              />
              <div className="rectangle-2" />
              <div className="rectangle-3" />
              {/* <img
                className="vector"
                alt="Vector"
                src={require("./img/vector-23.png")}
              /> */}

              <div className="p-2">
                <img
                  className="img"
                  alt="Vector"
                  src={require("./img/vector-22.png")}
                />
                <img
                  className="img1"
                  alt="Vector"
                  src={require("./img/vector-22.png")}
                />
                <img
                  className="img2"
                  alt="Vector"
                  src={require("./img/vector-22.png")}
                />
                <img
                  className="img3"
                  alt="Vector"
                  src={require("./img/vector-22.png")}
                />

                <img
                  className="line-4"
                  alt="Line"
                  src={require("./img/line-66.png")}
                />
                <img
                  className="line-Ro_Reject"
                  alt="Line"
                  src={require("./img/line-66.png")}
                />
                <img
                  className="line-5"
                  alt="Line"
                  src={require("./img/line-64.png")}
                />
                <img
                  className="line-6"
                  alt="Line"
                  src={require("./img/line-65.png")}
                />
                <img
                  className="line-7"
                  alt="Line"
                  src={require("./img/line-66.png")}
                />

                <img
                  className="line-STPINLET"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-ETPOUTLET"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-STPOUTLET"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-UFREJECT"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-UFOUTLET"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-ROPERMIT"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-ROREJECT"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <img
                  className="line-13"
                  alt="Line"
                  src={require("./img/line-77.png")}
                />
                <div className="text-wrapper-20">ETP INLET</div>
                <div className="text-wrapper-21">STP INLET</div>
                <div className="text-wrapper-22">ETP OUTLET</div>
                <div className="text-wrapper-23">STP OUTLET</div>
                {/* <div className="text-wrapper-24">CT3</div> */}
                {/* <div className="text-wrapper-25">CT4</div> */}
                <div className="text-wrapper-24">UF REJECT</div>
                <div className="text-wrapper-25">UF OUTLET</div>
                <div className="text-wrapper-16">UF INLET</div>
                <div className="text-wrapper-17">RO PERMIT</div>
                <div className="text-wrapper-18">RO REJECT/HORTICULTURE</div>
                <div className="text-wrapper-RO_REJECT">RO REJECT FLUSHING</div>

                <img
                  className="group"
                  alt="Group"
                  src={require("./img/group-14.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M13");
                  }}
                />
                <img
                  className="group-2"
                  alt="Group"
                  src={require("./img/group-30.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M11");
                  }}
                />
                <img
                  className="group-3"
                  alt="Group"
                  src={require("./img/group-31.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M7");
                  }}
                />
                <img
                  className="group-4"
                  alt="Group"
                  src={require("./img/group-31.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M54");
                  }}
                />
                <img
                  className="group-5"
                  alt="Group"
                  src={require("./img/group-31.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M10");
                  }}
                />
                <img
                  className="group-6"
                  alt="Group"
                  src={require("./img/group-31.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M8");
                  }}
                />
                <img
                  className="group-7"
                  alt="Group"
                  src={require("./img/group-31.png")}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleMeters(true, "M14");
                  }}
                />
              </div>
            </div>
          </div>
          {/* <img
            className="vector-24"
            alt="Vector"
            src={require("./img/vector-10-1.png")}
          /> */}
          <img
            className="polygon_RO_REJECT"
            alt="Polygon"
            src={require("./img/polygon-91.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M21");
            }}
          />
        </div>
      </div>
      {meterClicked && (
        <div>
          {/* Pass meterNames as a prop to MeterDetails */}
          <MeterDetails
            show={openMeterModal}
            onShow={() => setOpenMeterModal(true)}
            onHide={() => setOpenMeterModal(false)}
            consumptionValue={totalVolume}
            flowRate={flowRate}
            meterNames={meterNames.flat()}
            dynamicName={dynamicName}
          />
        </div>
      )}
    </>
  );
};

export default Etp1;
