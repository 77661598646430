  import React from "react";
  import ReactSpeedometer from "react-d3-speedometer";
  import './Styles.css'

  const Meter = (props) => {
    const Value = props.value;
    const maxValue = Value > 500000 ? 5000000 : 500000;
 

    return (
      <>
        {Value >= 0 && (
          <ReactSpeedometer
            needleTransitionDuration={9000}
            needleHeightRatio={0.8}
            needleTransition="easeElastic"
            width={props.width}
            height={props.height}
            value={Value}
            minValue={0}
            maxValue={maxValue}
            maxSegmentLabels={5}
            segments={5}
            needleColor="black"
            // startColor="#42A5F5"
            // endColor="#c30000"
            segmentColors={[
              "rgb(66, 165, 245) ",   "rgb(42, 50, 246)",    "rgb(125, 17, 248)",    "rgb(232, 5, 237)",    "#B71C1C"
              
            ]}
            currentValueText={`Total: ${Value} kWh`}
            className='speedometer'
          />
        )}
      </>
    );
  };

  export default Meter;
