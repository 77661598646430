import React from "react";
import "./style.css";
import { Modal, Button } from "react-bootstrap";

const styles = {
  Title: {
    textAlign: "center",
    width: "100%",
    fontWeight: "600",
    color: "#375EAD",
    display: "flex",
    alignItems: "center",
  },
  Header: {
    margin: "auto",
  },
  Body: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  MainDiv: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    height: "260px",
  },
  SubDiv: { color: "#375EAD", fontSize: "18px", fontWeight: "700" },
  SubDiv1: {
    color: "#375EAD",
    fontSize: "18px",
    fontWeight: "700",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100px",
  },
  Span: {
    color: "black",
    fontWeight: "400",
  },
  CheckMeterDiv: {
    borderRadius: "2.667px",
    border: "0.667px solid #375EAD",
    background: "#375EAD",
    color: "#FFF",
    fontSize: "15px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "272px",
    height: "34px",
    marginBottom: "10px",
  },
  backButton: {
    background: "transparent",
    border: "none",
    fontWeight: "bold",
    color: "#000078",
    cursor: "pointer",
    alignItems: "center",
    padding: "15px 0px",
    boxShadow: "none",
  },
};
const CheckMeterDetails = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={styles.Title}>
          <Button style={styles.backButton} onClick={props.onHide}>
            <img
              className="img"
              alt="Vector"
              src={require("../../Assets/arrowLeft.png")}
              height={20}
              width={20}
              onClick={props.onHide}
            />
          </Button>
          <div style={styles.Header}>Flow Meter Details</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={styles.Body}>
          <div style={styles.MainDiv}>
            <div style={styles.SubDiv}>
              Make : <span style={styles.Span}>Krohne-Marshall</span>{" "}
            </div>
            <div style={styles.SubDiv}>
              Model Number : <span style={styles.Span}>IFC 050</span>{" "}
            </div>
            <div style={styles.SubDiv}>
              Communication Protocol :
              <span style={styles.Span}> MODBUS RS485</span>{" "}
            </div>
            <div style={styles.SubDiv}>
              Location :
              <span style={styles.Span}>
                {" "}
                {/* {props.meterNames}, Process Line{" "} */}
                {props.meterNames},
              </span>{" "}
            </div>
          </div>
          <div style={styles.MainDiv}>
            <div style={styles.SubDiv}>
              Error Status:<span style={styles.Span}></span>{" "}
            </div>
            <div style={styles.SubDiv}>NA</div>
            <div style={styles.SubDiv1}>
              Contact Details:
              <div style={styles.Span}>
                {" "}
                <img
                  className="img"
                  alt="Vector"
                  src={require("../../Assets/telephone.png")}
                  height={25}
                  width={25}
                />{" "}
                +91 97650 00543
              </div>
              <div style={styles.Span}>
                <img
                  className="img"
                  alt="Vector"
                  src={require("../../Assets/envelope.png")}
                  height={25}
                  width={25}
                />{" "}
                connexus@dacpl.in
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CheckMeterDetails;
