import React from "react";
import { Bar, Line } from "react-chartjs-2";

const TrendChart = ({ chartData, suggestedMaxProps }) => {
  const options = {
    layout: {
      padding: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 7,
        },
        formatter: function (value) {
          return "\n     \n" + value;
          // eq. return ['line1', 'line2', value]
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        display: true,
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },
        suggestedMax: suggestedMaxProps ? suggestedMaxProps : 100,
        // stepSize: 2,
        //beginAtZero: true,
      },
    },
  };
  return <Bar data={chartData} options={options} />;
};

export default TrendChart;
