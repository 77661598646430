import React, { useEffect, useState } from "react";
import "./styles/ReportTable.css";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
import { format } from "date-fns";

export const ReportTable = () => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const [MapData, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}10-latest`, {
          headers: {
            Authorization: `abc ${token}`,
          },
        });

        setData(response?.data?.data || {});
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="fixTableHead">
        {MapData && (
          <table>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th colSpan={2}>JLR | Drinking Water</th>
                <th colSpan={2}>K Block | Drinking Water</th>
                <th colSpan={2}>K Block | Process Water</th>
                <th colSpan={2}>MIDC | Fresh Water 2</th>
                <th colSpan={2}>MIDC | Fresh Water 1</th>
                <th colSpan={2}>J Block | Drinking Water</th>
                <th colSpan={2}>ETP | RO Reject</th>
                <th colSpan={2}>ETP | ACF 2 Out</th>
                <th colSpan={2}>ETP | JLR Garden</th>
                <th colSpan={2}>ETP | RO Transfer</th>
                <th colSpan={2}> STP Inlet</th>
                <th colSpan={2}>ETP + STP Inlet</th>
                <th colSpan={2}>ETP Inlet</th>
                <th colSpan={2}>STP Outlet</th>
                <th colSpan={2}>Press Shop-Drinking water</th>
                <th colSpan={2}>Weld Shop-Drinking water</th>
                <th colSpan={2}>EV Shop Process Water</th>
                <th colSpan={2}>Engine Shop-Drinking Water</th>
                <th colSpan={2}>Process water - PDI Solar</th>
                <th colSpan={2}>TCF-1 Drinking Water</th>
                <th colSpan={2}>JLRD | JLR Drinking</th>
                {/* <th colSpan={2}>EV Shop Drinking</th> */}
                <th colSpan={2}>Scanning Post Drinking Water</th>
                <th colSpan={2}>Cons.Yard & Poly House Drinking Water</th>
                <th colSpan={2}>Pump House no.6</th>
                <th colSpan={2}>RO water Pump House No.6</th>
              </tr>
            </thead>

            <tr className="ColSpan">
              <td></td>

              {Object.keys(MapData).map((key) => (
                <React.Fragment key={key}>
                  <td>FR</td>
                  <td>TV</td>
                </React.Fragment>
              ))}
            </tr>

            <tbody>
              {MapData &&
                MapData.M1 &&
                Object?.keys(MapData.M1)?.map((index) => (
                  <tr key={index}>
                    <td>{MapData?.M1[index]?.D}</td>
                    {Object?.keys(MapData)?.map((key) => (
                      <React.Fragment key={key}>
                        <td>{MapData[key]?.[index]?.[`${key}FR`]}</td>
                        <td>{MapData[key]?.[index]?.[`${key}TV`]}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
