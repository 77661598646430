import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ToastContext from "../../Context/ToastContext";
import MultiAxisChartFilter from "./MultiAxisChartFilter";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "center",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 15,
      },
    },
    title: {
      display: false,
      text: "Multi Axis",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  layout: {
    padding: {
      bottom: 50,
      left: 10,
      top: 0,
      right: 10,
    },
  },
};

const MultiAxisLineChartETP = () => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const { toast } = useContext(ToastContext);
  const [MapData, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [dateError, setDateError] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const fetchData = useCallback(async () => {
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than curren date");
    }
    setDateError(false);
    let url = `${URL}axis-chart?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setData(res?.data);
    } catch (err) {
      if (err.response.data.message === "Unauthorized") setAuthorized(false);
      console.log(err);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  const setTrue =
    dateFilter.days.length ||
    (dateFilter.startdate.length && dateFilter.enddate.length);

  const label1 =
    MapData?.data?.M7[0]?.D &&
    MapData?.data?.M7.map((item) => {
      const dateObj = new Date(item?.D);
      return format(dateObj, "dd/MM");
    });

  const label2 =
    MapData?.data?.M7[0]?.T &&
    MapData?.data?.M7.map((item) => {
      const timeString = item?.T;
      return format(new Date(`1970-01-01T${timeString}`), "HH:mm");
    });

  const customDateData = {
    labels: label1,
    datasets: [
      {
        label: "ETP | Ro Reject",
        data: MapData?.data?.M7?.map((item) => item?.M7TVDifference),
        borderColor: "#ff7d69",
        backgroundColor: "#ff7d69",
        yAxisID: "y",
      },
      {
        label: "ETP | ACF 2 Out",
        data: MapData?.data?.M8.map((item) => item?.M8TVDifference),
        borderColor: "#FF4ECD",
        backgroundColor: "#FF4ECD",
        yAxisID: "y",
      },
      {
        label: "ETP | JLR Garden  ",
        data: MapData?.data?.M9.map((item) => item?.M9TVDifference),
        borderColor: "#7A7A7A",
        backgroundColor: "#7A7A7A",
        yAxisID: "y1",
      },
      {
        label: "ETP | RO Transfer",
        data: MapData?.data?.M10.map((item) => item?.M10TVDifference),
        borderColor: "#06F",
        backgroundColor: "#06F",
        yAxisID: "y",
      },
      {
        label: "STP INLET",
        data: MapData?.data?.M11.map((item) => item?.M11TVDifference),
        borderColor: "#699167",
        backgroundColor: "#699167",
        yAxisID: "y1",
      },
      {
        label: "ETP + STP INLET",
        data: MapData?.data?.M12.map((item) => item?.M12TVDifference),
        borderColor: "#6b5a6e",
        backgroundColor: "#6b5a6e",
        yAxisID: "y1",
      },
      {
        label: "ETP INLET  ",
        data: MapData?.data?.M13.map((item) => item?.M13TVDifference),
        borderColor: "#bbc967",
        backgroundColor: "#bbc967",
        yAxisID: "y",
      },
      {
        label: "STP OUTLET",
        data: MapData?.data?.M14.map((item) => item?.M14TVDifference),
        borderColor: "#9e6fed",
        backgroundColor: "#9e6fed",
        yAxisID: "y1",
      },
      {
        label: "Ro Transfer Pump House_6",
        data: MapData?.data?.M54?.map((item) => item?.M54TVDifference),
        borderColor: "#e68ca5",
        backgroundColor: "#e68ca5",
        yAxisID: "y",
      },

      {
        label: "Pump House no.6",
        data: MapData?.data?.M56?.map((item) => item?.M56TVDifference),
        borderColor: "#4aa9d9",
        backgroundColor: "#4aa9d9",
        yAxisID: "y",
      },
      {
        label: "RO water Pump House No.6",
        data: MapData?.data?.M57?.map((item) => item?.M57TVDifference),
        borderColor: "#8a4f2f",
        backgroundColor: "#8a4f2f",
        yAxisID: "y1",
      },
    ],
  };

  const defaultData = {
    labels: label2,
    datasets: [
      {
        label: "ETP | RO Reject",
        data: MapData?.data?.M7.map((item) => item?.M7TV),
        borderColor: "#ff7d69",
        backgroundColor: "#ff7d69",
        yAxisID: "y1",
      },
      {
        label: "ETP | ACF 2 Out",
        data: MapData?.data?.M8.map((item) => item?.M8TV),
        borderColor: "#06F",
        backgroundColor: "#06F",
        yAxisID: "y",
      },
      {
        label: "ETP | JLR Garden",
        data: MapData?.data?.M9.map((item) => item?.M9TV),
        borderColor: "#FF7A00",
        backgroundColor: "#FF7A00",
        yAxisID: "y1",
      },
      {
        label: "ETP | RO Transfer",
        data: MapData?.data?.M10.map((item) => item?.M10TV),
        borderColor: "#F00",
        backgroundColor: "#F00",
        yAxisID: "y1",
      },
      //
      {
        label: "STP INLET",
        data: MapData?.data?.M11.map((item) => item?.M11TV),
        borderColor: "#699167",
        backgroundColor: "#699167",
        yAxisID: "y1",
      },
      {
        label: "ETP + STP INLET",
        data: MapData?.data?.M12.map((item) => item?.M12TV),
        borderColor: "#6b5a6e",
        backgroundColor: "#6b5a6e",
        yAxisID: "y1",
      },
      {
        label: "ETP INLET",
        data: MapData?.data?.M13.map((item) => item?.M13TV),
        borderColor: "#bbc967",
        backgroundColor: "#bbc967",
        yAxisID: "y1",
      },
      {
        label: "STP OUTLET",
        data: MapData?.data?.M14.map((item) => item?.M14TV),
        borderColor: "#9e6fed",
        backgroundColor: "#9e6fed",
        yAxisID: "y1",
      },
      {
        label: "Ro Transfer Pump House-6",
        data: MapData?.data?.M54?.map((item) => item?.M54TV),
        borderColor: "#e68ca5",
        backgroundColor: "#e68ca5",
        yAxisID: "y1",
      },
      {
        label: "Pump House no.6",
        data: MapData?.data?.M56?.map((item) => item?.M56TV),
        borderColor: "#4aa9d9",
        backgroundColor: "#4aa9d9",
        yAxisID: "y1",
      },
      {
        label: "RO water Pump House No.6",
        data: MapData?.data?.M57?.map((item) => item?.M57TV),
        borderColor: "#8a4f2f",
        backgroundColor: "#8a4f2f",
        yAxisID: "y1",
      },
    ],
  };
  const handleCSVDownload = () => {
    if (MapData?.data) {
      const csvData = MapData.data.M1.map((item, index) => {
        let DateTime;
        if (setTrue === 0) {
          DateTime = item.T;
        } else {
          DateTime = item.D;
        }
        const rowData = {
          DateTime,
          "ETP | Ro Reject": MapData.data.M7[index]?.M7TV || 0,
          "ETP | ACF 2 Out": MapData.data.M8[index]?.M8TV || 0,
          "ETP | JLR Garden ": MapData.data.M9[index]?.M9TV || 0,
          "ETP | RO Transfer": MapData.data.M10[index]?.M10TV || 0,
          "STP INLET": MapData.data.M11[index]?.M11TV || 0,
          "ETP + STP INLET": MapData.data.M12[index]?.M12TV || 0,
          "ETP INLET": MapData.data.M13[index]?.M13TV || 0,
          "STP OUTLET": MapData.data.M14[index]?.M14TV || 0,
          // "Pump House no.6": MapData?.data?.M56[index]?.M56TV || 0,
          // "RO water Pump House No.6": MapData?.data?.M57[index]?.M57TV || 0,
        };

        if (setTrue !== 0) {
          rowData["ETP | Ro Reject"] =
            MapData.data.M7[index]?.M7TVDifference || 0;
          rowData["ETP | ACF 2 Out"] =
            MapData.data.M8[index]?.M8TVDifference || 0;
          rowData["ETP | JLR Garden "] =
            MapData.data.M9[index]?.M9TVDifference || 0;
          rowData["ETP | RO Transfer"] =
            MapData.data.M10[index]?.M10TVDifference || 0;
          rowData["STP INLET"] = MapData.data.M11[index]?.M11TVDifference || 0;
          rowData["ETP + STP INLET"] =
            MapData.data.M12[index]?.M12TVDifference || 0;
          rowData["ETP INLET  "] =
            MapData.data.M13[index]?.M13TVDifference || 0;
          rowData["STP OUTLET"] = MapData.data.M14[index]?.M14TVDifference || 0;
          // rowData["Ro Transfer Pump House-6"] =
          //   MapData.data.M54[index]?.M54TVDifference || 0;
          // rowData["Pump House no.6"] =
          //   MapData.data.M56[index]?.M56TVDifference || 0;
          // rowData["RO water Pump House No.6"] =
          //   MapData.data.M57[index]?.M57TVDifference || 0;

          //
          // rowData["JLRD | JLR Drinking"] =
          //   (MapData.data.M43 && MapData.data.M43[index]?.M43TVDifference) || 0;
        }
        return rowData;
      });

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });

      saveAs(csvBlob, "Data.csv");
      toast.success("Data downloading, please wait...");
    }
  };

  const handlePDFDownload = async () => {
    const headers = [
      setTrue === 0 ? "Time" : "Date",
      "ETP | RO Reject",
      "ETP | ACF 2 Out",
      "ETP | JLR Garden ",
      "ETP | RO Transfer",
      "STP INLET",
      "ETP + STP INLET",
      "ETP INLET  ",
      "STP OUTLET",
      "Ro Transfer Pump House-6",
      "Pump House no.6",
      "RO water Pump House No.6",
      // "ETP | RO Transfer",
      // "JLRD | JLR Drinking",
    ];

    const pdfData = MapData?.data;
    const rows = [];

    if (setTrue === 0) {
      for (let i = 0; i < pdfData.M1.length; i++) {
        const timeWithSeconds = pdfData.M1[i]?.T;
        const time = timeWithSeconds?.split(":")?.slice(0, 2)?.join(":");
        const row = [
          time,
          pdfData.M7[i]?.M7TV?.toFixed(),
          pdfData.M8[i]?.M8TV?.toFixed(),
          pdfData.M9[i]?.M9TV?.toFixed(),
          pdfData.M10[i]?.M10TV?.toFixed(),
          pdfData.M11[i]?.M11TV?.toFixed(),
          pdfData.M12[i]?.M12TV?.toFixed(),
          pdfData.M13[i]?.M13TV?.toFixed(),
          pdfData.M14[i]?.M14TV?.toFixed(),
          // pdfData.M56[i]?.M56TV?.toFixed(),
          // pdfData.M57[i]?.M57TV?.toFixed(),
          // pdfData.M57 && pdfData.M57[i]?.M57TV?.toFixed(),
        ];
        console.log("row----", row);
        rows.push(row);
      }
    } else {
      for (let i = 0; i < pdfData.M1.length; i++) {
        const date = format(new Date(pdfData.M1[i]?.D), "dd/MM/yyyy");
        const row = [
          date,
          pdfData.M7[i]?.M7TVDifference?.toFixed(),
          pdfData.M8[i]?.M8TVDifference?.toFixed(),
          pdfData.M9[i]?.M9TVDifference?.toFixed(),
          pdfData.M10[i]?.M10TVDifference?.toFixed(),
          pdfData.M11[i]?.M11TVDifference?.toFixed(),
          pdfData.M12[i]?.M12TVDifference?.toFixed(),
          pdfData.M13[i]?.M13TVDifference?.toFixed(),
          pdfData.M14[i]?.M14TVDifference?.toFixed(),
        ];
        rows.push(row);
      }
    }
    const date = new Date().toLocaleString();
    const doc = new jsPDF("l", "pt", "letter");

    let y = 10;
    doc.setLineWidth(2);
    doc.text(350, (y += 30), "Report");

    const chart = document.getElementById("lineChart");
    chart.style.backgroundColor = "#F9F9F9";
    chart.style.paddingTop = "40px";
    chart.style.borderRadius = "10px";

    const canvas = await html2canvas(chart);
    const imgData = canvas.toDataURL("image/jpeg");
    doc.addImage(
      imgData,
      "JPEG",
      40,
      (y += 20),
      doc.internal.pageSize.getWidth() - 70,
      250
    );
    doc.setFontSize(8);
    doc.text(`Downloaded at: ${date}`, 170, 22, null, null, "right");
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 340,
      theme: "grid",
      tableWidth: "100%",
      headStyles: { fontSize: 8, halign: "center" },
      columnStyles: {
        0: { halign: "center", cellWidth: 55, fontSize: 8 },
        1: { halign: "center", cellWidth: 55, fontSize: 8 },
        2: { halign: "center", cellWidth: 55, fontSize: 8 },
        3: { halign: "center", cellWidth: 55, fontSize: 8 },
        4: { halign: "center", cellWidth: 55, fontSize: 8 },
        5: { halign: "center", cellWidth: 55, fontSize: 8 },
        6: { halign: "center", cellWidth: 55, fontSize: 8 },
        7: { halign: "center", cellWidth: 55, fontSize: 8 },
        8: { halign: "center", cellWidth: 55, fontSize: 8 },
        9: { halign: "center", cellWidth: 55, fontSize: 8 },
        10: { halign: "center", cellWidth: 55, fontSize: 8 },
        11: { halign: "center", cellWidth: 55, fontSize: 8 },
      },
    });
    doc.save("Data.pdf");
    toast.success("Data downloading, please wait...");
  };

  const DataDownload = async () => {
    if (!authorized) {
      return toast.error("Unauthorized");
    }
    if (dateFilter.startdate && !dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select end date");
    }
    if (!dateFilter.startdate && !dateFilter.days && dateFilter.enddate) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date");
    }
    if (dateFilter.startdate > dateFilter.enddate && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Start date should be date earlier than end date");
    }
    if (new Date() < new Date(dateFilter.startdate) && !dateFilter.days) {
      setDateError(true);
      setData([]);
      return toast.error("Please select start date earlier than current date");
    }
    if (dateError) {
      return toast.error("Data not available for download");
    }
    handleCSVDownload();
    await handlePDFDownload();
    const chart = document.getElementById("lineChart");
    chart.style.backgroundColor = "";
    chart.style.paddingTop = "";
    chart.style.borderRadius = "";
  };
  return (
    <>
      <div style={{ margin: "auto" }}>
        <MultiAxisChartFilter
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          fetchData={fetchData}
          DataDownload={DataDownload}
        />
        {MapData?.data ? (
          <Line
            id="lineChart"
            options={options}
            data={setTrue === 0 ? defaultData : customDateData}
            height={400}
            width={1350}
          />
        ) : (
          <Line
            id="lineChart"
            options={options}
            data={customDateData}
            height={400}
            width={1350}
          />
        )}
      </div>
    </>
  );
};
export default MultiAxisLineChartETP;
