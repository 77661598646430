import NovaBiwReport from "./NovaBiwReport";
import { HARRIER_SAFARI } from "./utils/Constants";

const HarrierSafariReport = () => {
  return (
    <div>
      <NovaBiwReport shopname={"Harrier Safari"} shopNo={HARRIER_SAFARI} />
    </div>
  );
};

export default HarrierSafariReport;
