// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav.nav-tabs{
    background: #F9F9F9;
    border: none;
}


.NavLink{
    width: 220px;
height: 64px;

}`, "",{"version":3,"sources":["webpack://./src/Styles/Tabs.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;IACI,YAAY;AAChB,YAAY;;AAEZ","sourcesContent":[".nav.nav-tabs{\n    background: #F9F9F9;\n    border: none;\n}\n\n\n.NavLink{\n    width: 220px;\nheight: 64px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
