import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import Tabs from "../../Layout/Tabs";
import { Link } from "react-router-dom";
import MeterDetails from "../Modals/MeterDetails";
import toast from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";

const DrinkingWaterLine = () => {
  const [openMeterModal, setOpenMeterModal] = useState(false);
  // const [selectedMeter, setSelectedmeters] = useState("");
  const [meterName, setMeterName] = useState("");
  const [value, setValue] = useState("");
  const [consumptionValue, setConsumptionValue] = useState(0);
  const [flowRate, setFlowRate] = useState(0);
  const { token } = useAuth();
  // const [totalVolume, setTotalVolume] = useState([]);
  const [meterClicked, setMeterClicked] = useState(false);
  // const [openMeterModal, setOpenMeterModal] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  // const [flowRate, setFlowRate] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [meterNames, setMeterNames] = useState([]);
  // const [meterClicked, setMeterClicked] = useState(false);

  const DrinkingArr = [
    {
      M1: "Pump House-4",
      M2: "Pump House-4",
      M3: "Pump House-4",
      M4: "Pump House-4",
      M5: "Pump House-4",
      M50: "Pump House-4",
      M51: "Pump House-4",
    },
    {
      M18: "Paint Shop",
      M20: "Paint Shop",
    },
    {
      M27: "Office Block",
      M28: "Office Block",
      M29: "Office Block",
      M30: "Office Block",
    },

    {
      M23: "Press Shop",
    },
    {
      M24: "Weld Shop",
    },
    {
      M26: "EV Shop",
    },
    {
      M32: "TCF-2      ",
    },
    {
      M39: "TCF-1",
    },
    {
      M63: "Parking Area Drinking",
    },
    {
      M45: "PDI",
    },
    {
      M46: "PolyHouse",
    },
    {
      M55: "OMEGA",
    },
    {
      M49: "Scarpyard",
    },
  ];

  const extractPropertyValues = (arr, propertyNames) => {
    return arr.map((item) =>
      propertyNames.map((propertyName) => item[propertyName])
    );
  };

  const handleMeters = async (flag, meter) => {
    let url = `${process.env.REACT_APP_URL}get-meters-data`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`, // Replace with your actual token
        },
      });

      const { meterName, value, data } = response.data;

      // Moved setMeterNames and setSelectedMeter outside of handleMeters
      setMeterNames(extractPropertyValues(DrinkingArr, [meter]));
      setSelectedMeter(meter);
      //
      const meterData = response.data.data;
      // console.log(
      //   "Meter Data:",
      //   meter,
      //   meterData,
      //   meterData.hasOwnProperty(`${meter}FR`),
      //   meterData.hasOwnProperty(`${meter}`)
      // );

      if (meterData) {
        // Check if the meter has Flow Rate (FR) and Total Volume (TV) properties
        if (
          meterData.hasOwnProperty(`${meter}FR`) &&
          meterData.hasOwnProperty(`${meter}`)
        ) {
          const flowRate = meterData[`${meter}FR`];
          const totalVolume = meterData[`${meter}`];
          // console.log("Flow Rate:", flowRate);
          // console.log("Total Volume:", totalVolume);
          // Set the state variables for Flow Rate and Total Volume
          setFlowRate(flowRate);
          setTotalVolume(totalVolume);

          // You can use flowRate and totalVolume values as needed in your component
        }
      }

      setOpenMeterModal(true);
      setMeterClicked(true);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };

  const dynamicName = "Drinking Line";

  return (
    <>
      <Tabs />

      <div className="drinking-water-line">
        <div className="div">
          <div
            style={{
              color: "#375EAD",
              fontWeight: "bold",
              fontSize: "18px",
              margin: "30px 0px",
              width: "100%",
              height: "30px",
              textAlign: "center",
            }}
          >
            Plant View
          </div>
          <img
            className="vector"
            alt="Vector"
            src={require("./img/vector-108.png")}
          />
          <img
            className="img"
            alt="Vector"
            src={require("./img/vector-107.png")}
          />
          <img
            className="vector-2"
            alt="Vector"
            src={require("./img/vector-106.png")}
          />
          <img
            className="vector-3"
            alt="Vector"
            src={require("./img/vector-103.png")}
          />
          <img
            className="vector-4"
            alt="Vector"
            src={require("./img/vector-103.png")}
          />
          <img
            className="vector-5"
            alt="Vector"
            src={require("./img/vector-103.png")}
          />
          <img
            className="vector-6"
            alt="Vector"
            src={require("./img/vector-102.png")}
          />
          <img
            className="vector-7"
            alt="Vector"
            src={require("./img/vector-101.png")}
          />
          <img
            className="vector-8"
            alt="Vector"
            src={require("./img/vector-100.png")}
          />
          <img
            className="vector-9"
            alt="Vector"
            src={require("./img/vector-99.png")}
          />
          <img
            className="vector-10"
            alt="Vector"
            src={require("./img/vector-98.png")}
          />
          <img
            className="vector-11"
            alt="Vector"
            src={require("./img/vector-97.png")}
          />
          {/*  */}
          <img
            className="vector-weld_shop1"
            alt="Vector"
            src={require("./img/vector-97.png")}
          />
          <img
            className="vector-press_shop"
            alt="Vector"
            src={require("./img/vector-97.png")}
          />
          <img
            className="vector-tcf_1_side"
            alt="Vector"
            src={require("./img/vector-97.png")}
          />
          {/*  */}
          <img
            className="vector-12"
            alt="Vector"
            src={require("./img/vector-96.png")}
          />
          <img
            className="vector-13"
            alt="Vector"
            src={require("./img/vector-95.png")}
          />
          <img
            className="vector-14"
            alt="Vector"
            src={require("./img/vector-94.png")}
          />
          <img
            className="vector-15"
            alt="Vector"
            src={require("./img/vector-93.png")}
          />
          <img
            className="vector-16"
            alt="Vector"
            src={require("./img/vector-92.png")}
          />
          <img
            className="vector-17"
            alt="Vector"
            src={require("./img/vector-91.png")}
          />
          <img
            className="vector-18"
            alt="Vector"
            src={require("./img/vector-90.png")}
          />
          <img
            className="vector-19"
            alt="Vector"
            src={require("./img/vector-89.png")}
          />
          {/*  */}
          <img
            className="vector-service_line"
            alt="Vector"
            src={require("./img/vector-89.png")}
          />
          <img
            className="vector-office_block1"
            alt="Vector"
            src={require("./img/vector-89.png")}
          />
          <img
            className="vector-office_block2"
            alt="Vector"
            src={require("./img/vector-89.png")}
          />
          {/*  */}
          <img
            className="vector-20"
            alt="Vector"
            src={require("./img/vector-88.png")}
          />
          <img
            className="vector-21"
            alt="Vector"
            src={require("./img/vector-87.png")}
          />
          <img
            className="vector-service_block_line"
            alt="Vector"
            src={require("./img/vector-87.png")}
          />
          {/*  */}
          <img
            className="vector-canteen"
            alt="Vector"
            src={require("./img/vector-87.png")}
          />
          <img
            className="vector-weld_shop"
            alt="Vector"
            src={require("./img/vector-87.png")}
          />
          <img
            className="vector-office_block"
            alt="Vector"
            src={require("./img/vector-87.png")}
          />
          {/*  */}
          <img
            className="vector-22"
            alt="Vector"
            src={require("./img/vector-86.png")}
          />
          <img
            className="vector-23"
            alt="Vector"
            src={require("./img/vector-85.png")}
          />
          <img
            className="vector-24"
            alt="Vector"
            src={require("./img/vector-84.png")}
          />
          <img
            className="vector-25"
            alt="Vector"
            src={require("./img/vector-83.png")}
          />
          <img
            className="vector-26"
            alt="Vector"
            src={require("./img/vector-82.png")}
          />
          <img
            className="vector-27"
            alt="Vector"
            src={require("./img/vector-81.png")}
          />
          <img
            className="vector-28"
            alt="Vector"
            src={require("./img/vector-80.png")}
          />
          <img
            className="vector-29"
            alt="Vector"
            src={require("./img/vector-79.png")}
          />
          <img
            className="vector-30"
            alt="Vector"
            src={require("./img/vector-78.png")}
          />
          <img
            className="vector-31"
            alt="Vector"
            src={require("./img/vector-77.png")}
          />
          <img
            className="vector-32"
            alt="Vector"
            src={require("./img/vector-76.png")}
          />
          <img
            className="vector-33"
            alt="Vector"
            src={require("./img/vector-75.png")}
          />
          <img
            className="vector-34"
            alt="Vector"
            src={require("./img/vector-74.png")}
          />
          <img
            className="vector-35"
            alt="Vector"
            src={require("./img/vector-73.png")}
          />
          <img
            className="vector-36"
            alt="Vector"
            src={require("./img/vector-72.png")}
          />
          <img
            className="vector-37"
            alt="Vector"
            src={require("./img/vector-71.png")}
          />
          <div className="text-wrapper-4">K BLOCK</div>
          <div className="text-wrapper-5">JLR</div>
          <div className="text" />
          <img
            className="vector-38"
            alt="Vector"
            src={require("./img/vector-70.png")}
          />
          <img
            className="vector-39"
            alt="Vector"
            src={require("./img/vector-69.png")}
          />
          <img
            className="vector-40"
            alt="Vector"
            src={require("./img/vector-68.png")}
          />
          <img
            className="vector-41"
            alt="Vector"
            src={require("./img/vector-67.png")}
          />
          <img
            className="vector-42"
            alt="Vector"
            src={require("./img/vector-66.png")}
          />
          <img
            className="vector-43"
            alt="Vector"
            src={require("./img/vector-65.png")}
          />
          <img
            className="vector-44"
            alt="Vector"
            src={require("./img/vector-64.png")}
          />
          <img
            className="vector-45"
            alt="Vector"
            src={require("./img/vector-63.png")}
          />
          <img
            className="vector-46"
            alt="Vector"
            src={require("./img/vector-62.png")}
          />
          <img
            className="vector-47"
            alt="Vector"
            src={require("./img/vector-61.png")}
          />
          <img
            className="vector-48"
            alt="Vector"
            src={require("./img/vector-60.png")}
          />
          <img
            className="vector-49"
            alt="Vector"
            src={require("./img/vector-59.png")}
          />
          <img
            className="vector-50"
            alt="Vector"
            src={require("./img/vector-58.png")}
          />
          <img
            className="vector-51"
            alt="Vector"
            src={require("./img/vector-57.png")}
          />
          <img
            className="vector-52"
            alt="Vector"
            src={require("./img/vector-56.png")}
          />
          <img
            className="vector-53"
            alt="Vector"
            src={require("./img/vector-55.png")}
          />
          <img
            className="vector-54"
            alt="Vector"
            src={require("./img/vector-54.png")}
          />
          <Link to="/waternetwork/pumphouse">
            <img
              className="vector-55"
              alt="Vector"
              src={require("./img/vector-53.png")}
            />
          </Link>
          <img
            className="vector-56"
            alt="Vector"
            src={require("./img/vector-52.png")}
          />
          <img
            className="vector-57"
            alt="Vector"
            src={require("./img/vector-51.png")}
          />
          <img
            className="vector-58"
            alt="Vector"
            src={require("./img/vector-50.png")}
          />
          <img
            className="vector-59"
            alt="Vector"
            src={require("./img/vector-49.png")}
          />
          <Link to="/waternetwork/paintshop">
            <img
              className="vector-60"
              alt="Vector"
              src={require("./img/vector-48.png")}
            />
          </Link>
          <img
            className="vector-61"
            alt="Vector"
            src={require("./img/vector-47.png")}
          />
          <img
            className="vector-62"
            alt="Vector"
            src={require("./img/vector-46.png")}
          />
          <img
            className="vector-63"
            alt="Vector"
            src={require("./img/vector-45.png")}
          />
          <img
            className="vector-64"
            alt="Vector"
            src={require("./img/vector-44.png")}
          />
          <div className="text-wrapper-6">WELD SHOP</div>
          <img
            className="vector-65"
            alt="Vector"
            src={require("./img/vector-43.png")}
          />
          <img
            className="vector-66"
            alt="Vector"
            src={require("./img/vector-42.png")}
          />
          <img
            className="vector-service_block"
            alt="Vector"
            src={require("./img/vector-42.png")}
          />
          <img
            className="vector-67"
            alt="Vector"
            src={require("./img/vector-41.png")}
          />
          <img
            className="vector-68"
            alt="Vector"
            src={require("./img/vector-40.png")}
          />
          <img
            className="vector-69"
            alt="Vector"
            src={require("./img/vector-39.png")}
          />
          <img
            className="vector-70"
            alt="Vector"
            src={require("./img/vector-38.png")}
          />
          <img
            className="vector-71"
            alt="Vector"
            src={require("./img/vector-37.png")}
          />
          <img
            className="vector-72"
            alt="Vector"
            src={require("./img/vector-35.png")}
          />
          <img
            className="vector-73"
            alt="Vector"
            src={require("./img/vector-35.png")}
          />
          <img
            className="vector-74"
            alt="Vector"
            src={require("./img/vector-34.png")}
          />
          <img
            className="vector-75"
            alt="Vector"
            src={require("./img/vector-2.png")}
          />
          <img
            className="vector-76"
            alt="Vector"
            src={require("./img/vector-32.png")}
          />
          <img
            className="vector-77"
            alt="Vector"
            src={require("./img/vector-31.png")}
          />
          <img
            className="vector-78"
            alt="Vector"
            src={require("./img/vector-30.png")}
          />
          <img
            className="vector-79"
            alt="Vector"
            src={require("./img/vector-29.png")}
          />
          <img
            className="vector-80"
            alt="Vector"
            src={require("./img/vector-28.png")}
          />
          <div className="OFFICE-BUILDING">
            OFFICE <br />
            BUILDING
          </div>
          <img
            className="vector-81"
            alt="Vector"
            src={require("./img/vector-26.png")}
          />
          <img
            className="vector-82"
            alt="Vector"
            src={require("./img/vector-26.png")}
          />
          <img
            className="vector-83"
            alt="Vector"
            src={require("./img/vector-25.png")}
          />
          <img
            className="vector-84"
            alt="Vector"
            src={require("./img/vector-23.png")}
          />
          <img
            className="vector-85"
            alt="Vector"
            src={require("./img/vector-23.png")}
          />
          <img
            className="vector-86"
            alt="Vector"
            src={require("./img/vector-22.png")}
          />
          <img
            className="vector-87"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          {/*  */}
          <img
            className="vector-87"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          <img
            className="engine_shop_line"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          <img
            className="cantine_line"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          <img
            className="vector-88"
            alt="Vector"
            src={require("./img/vector-20.png")}
          />
          <img
            className="vector-89"
            alt="Vector"
            src={require("./img/vector-19.png")}
          />
          <img
            className="vector-90"
            alt="Vector"
            src={require("./img/vector-18.png")}
          />
          <div className="FROM-PUMP-HOUSE">
            FROM
            <br />
            PUMP HOUSE-3
          </div>
          <p className="TO-j-BLOCK-TO">
            TO J BLOCK
            <br />
            J1 TO J8
            <br />
            SEPARATE METER
            <br />
            NEEDS FOR <br />
            EACH BLOCK
          </p>
          <div className="UNDERGROUND-TANK">
            UNDERGROUND <br />
            TANK
          </div>
          <div className="PUMP-FOR-LIFT">
            PUMP
            <br />
            FOR LIFT
          </div>
          <div className="TERRACE-TANK">
            TERRACE
            <br />
            TANK
          </div>
          <div className="text-wrapper-7">JLR SHOP FLOOR</div>
          <div className="text-wrapper-8">S GATE</div>
          <div className="text-wrapper-9">POLYHOUSE</div>
          <div className="text-wrapper-10">SOUTH TOILET</div>
          <div className="text-wrapper-11">CANTEEN</div>
          <div className="text-wrapper-12">GDC SUMO GATE</div>
          <div className="text-wrapper-13">PARKING</div>
          <div className="text-wrapper-14">OFFICE BLOCK</div>
          {/*  */}
          <div className="text-wrapper-office_block">SERVICE BLOCK</div>
          <div className="text-wrapper-15">ENGINE SHOP</div>
          <div className="text-wrapper-16">PRESS SHOP</div>
          <div className="text-wrapper-17">PAINT SHOP</div>
          <div className="text-wrapper-18">ETP</div>
          <div className="text-wrapper-19">TCF 1</div>
          <div className="text-wrapper-20">TCF 2</div>
          <img
            className="line-2"
            alt="Line"
            src={require("./img/line-18.png")}
          />
          <img
            className="vector-91"
            alt="Vector"
            src={require("./img/vector-17.png")}
          />
          <div className="PUMP-HOUSE">
            PUMP
            <br />
            HOUSE-4
          </div>
          <div className="text-wrapper-21">MRS-3 BLOCK</div>
          <img
            className="vector-92"
            alt="Vector"
            src={require("./img/vector-16.png")}
          />
          <div className="text-wrapper-22">CANTEEN</div>
          <div className="text-wrapper-23">KITCHEN</div>
          <div className="text-wrapper-24">MATERIAL GATE</div>
          <div className="text-wrapper-25">CANTEEN 2</div>
          <div className="text-wrapper-26">CANTEEN 1</div>
          <div className="SUPPORT-SERVICE">
            SUPPORT
            <br />
            SERVICE
            <br />
            BUILDING
          </div>
          <div className="text-wrapper-27">LW SHOP</div>
          <div className="text-wrapper-28">EV SHOP</div>
          <div className="text-wrapper-29">COLLECTING TOWER</div>
          <div className="text-wrapper-30">EMERGENCY TANK</div>
          <div className="text-wrapper-31">FIRE CONTROL</div>
          <div className="text-wrapper-32">JRL PDI</div>
          <div className="text-wrapper-33">CANTEEN</div>
          <div className="text-wrapper-34">GDC BUILDING</div>
          <div className="text-wrapper-35">SCRAPYARD</div>
          <div className="text-wrapper-36">POLYHOUSE</div>
          <img
            className="line-3"
            alt="Line"
            src={require("./img/line-20.png")}
          />
          <img
            className="polygon"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          <img
            className="polygon-2"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          <img
            className="polygon-3"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          {/*  */}
          <img
            className="polygon-tcf_1_sideline"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          {/*  */}
          <img
            className="polygon-4"
            alt="Polygon"
            src={require("./img/polygon-3.png")}
          />
          <img
            className="polygon-5"
            alt="Polygon"
            src={require("./img/polygon-3.png")}
          />
          <img
            className="polygon-6"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          <img
            className="polygon-7"
            alt="Polygon"
            src={require("./img/polygon-17.png")}
          />
          <img
            className="polygon-8"
            alt="Polygon"
            src={require("./img/polygon-17.png")}
          />
          {/* <img
            className="polygon-9"
            alt="Polygon"
            src={require("./img/polygon-17.png")}
          /> */}
          {/*  */}
          <img
            className="polygon-service_line"
            alt="Polygon"
            src={require("./img/polygon-17.png")}
          />
          {/*  */}
          <img
            className="polygon-10"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          {/* polygon-engine_shop */}
          <img
            className="polygon-engine_shop"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          {/*  */}
          <img
            className="polygon-11"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          <img
            className="polygon-12"
            alt="Polygon"
            src={require("./img/polygon-28.png")}
          />
          <img
            className="polygon-13"
            alt="Polygon"
            src={require("./img/polygon-30.png")}
          />
          <img
            className="polygon-14"
            alt="Polygon"
            src={require("./img/polygon-33.png")}
          />
          <img
            className="polygon-15"
            alt="Polygon"
            src={require("./img/polygon-36.png")}
          />
          <img
            className="polygon-16"
            alt="Polygon"
            src={require("./img/polygon-36.png")}
          />
          <img
            className="polygon-17"
            alt="Polygon"
            src={require("./img/polygon-36.png")}
          />
          <img
            className="polygon-18"
            alt="Polygon"
            src={require("./img/polygon-38.png")}
          />
          <img
            className="polygon-19"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-20"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-21"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-22"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-23"
            alt="Polygon"
            src={require("./img/polygon-36.png")}
          />
          <img
            className="polygon-24"
            alt="Polygon"
            src={require("./img/polygon-31.png")}
          />
          <img
            className="polygon-25"
            alt="Polygon"
            src={require("./img/polygon-32.png")}
          />
          <img
            className="polygon-26"
            alt="Polygon"
            src={require("./img/polygon-29.png")}
          />
          <img
            className="polygon-27"
            alt="Polygon"
            src={require("./img/polygon-17.png")}
          />
          <img
            className="polygon-28"
            alt="Polygon"
            src={require("./img/polygon-13.png")}
          />
          <img
            className="polygon-29"
            alt="Polygon"
            src={require("./img/polygon-3.png")}
          />
          <img
            className="polygon-30"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-31"
            alt="Polygon"
            src={require("./img/polygon-10.png")}
          />
          <img
            className="polygon-32"
            alt="Polygon"
            src={require("./img/polygon-10.png")}
          />
          <img
            className="polygon-33"
            alt="Polygon"
            src={require("./img/polygon-10.png")}
          />

          <img
            className="polygon-34"
            alt="Polygon"
            src={require("./img/polygon-18.png")}
          />
          <img
            className="polygon-35"
            alt="Polygon"
            src={require("./img/polygon-19.png")}
          />
          {/* <img
            className="polygon-36"
            alt="Polygon"
            src={require("./img/polygon-25.png")}
          /> */}
          <img
            className="polygon-37"
            alt="Polygon"
            src={require("./img/polygon-24.png")}
          />
          <img
            className="polygon-38"
            alt="Polygon"
            src={require("./img/polygon-25.png")}
          />
          <img
            className="polygon-39"
            alt="Polygon"
            src={require("./img/polygon-21.png")}
          />
          <img
            className="polygon-40"
            alt="Polygon"
            src={require("./img/polygon-7.png")}
          />
          <img
            className="polygon-41"
            alt="Polygon"
            src={require("./img/polygon-15.png")}
          />
          <img
            className="vector-93"
            alt="Vector"
            src={require("./img/vector-15.png")}
          />
          <div className="TANK-EACH">
            TANK
            <br />
            5M3 EACH
          </div>
          <img
            className="line-4"
            alt="Line"
            src={require("./img/line-21.png")}
          />
          <img
            className="line-5"
            alt="Line"
            src={require("./img/line-22.png")}
          />
          <img
            className="m1-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              // setOpenMeterModal(true);
              handleMeters(true, "M5");
            }}
          />
          <img
            className="m9-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M1");
            }}
          />
          <img
            className="m8-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M2");
            }}
          />
          <img
            className="m7-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M50");
            }}
          />
          <img
            className="m6-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M51");
            }}
          />
          <img
            className="m12-group"
            alt="Group"
            src={require("./img/group-15.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M32");
            }}
          />
          <img
            className="group-3"
            alt="Group"
            src={require("./img/group-16.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M23");
            }}
          />
          <img
            className="m27-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M26");
            }}
          />
          <img
            className="m33-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M29");
            }}
          />
          <img
            className="m20-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M18");
            }}
          />
          <img
            className="m21-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M20");
            }}
          />
          <img
            className="m24-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M24");
            }}
          />
          <img
            className="m25-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M23");
            }}
          />
          <img
            className="m38-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M45");
            }}
          />
          <img
            className="m39-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M63");
            }}
          />
          <img
            className="m35-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M27");
            }}
          />
          <img
            className="m36-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M55");
            }}
          />
          <img
            className="m37-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M46");
            }}
          />
          <img
            className="m46-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M43");
            }}
          />
          <img
            className="m16-group"
            alt="Group"
            src={require("./img/group-18.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M39");
            }}
          />
          <img
            className="group-6"
            alt="Group"
            src={require("./img/group-19.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M49");
            }}
          />
          <img
            className="group-7"
            alt="Group"
            src={require("./img/group-20.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M");
            }}
          />
          <img
            className="group-8"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M4");
            }}
          />
          <img
            className="group-M30"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M30");
            }}
          />
          <img
            className="group-M32"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M28");
            }}
          />
          {/* <img
            className="group-13"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
 handleMeters(true,"M1")
            }}
          /> */}
          {/*  */}
          <img
            className="vector-94"
            alt="Vector"
            src={require("./img/vector-13.png")}
          />
          <img
            className="vector-95"
            alt="Vector"
            src={require("./img/vector-13.png")}
          />

          <Link to="/waternetwork/processwaterline">
            <div className="rectangle-4" />
            <div className="text-wrapper-37">Process Water Line</div>
          </Link>

          <Link to="/waternetwork/drinkingwaterline">
            <div className="rectangle-5" />

            <div className="text-wrapper-39">Drinking Water Line</div>
          </Link>

          <Link to="/waternetwork/horiculturewaterline">
            <div className="rectangle-6" />
            <div className="text-wrapper-38">Horiculture Water Line</div>
          </Link>

          <img
            className="vector-96"
            alt="Vector"
            src={require("./img/vector-12.png")}
          />
          <img
            className="vector-97"
            alt="Vector"
            src={require("./img/vector-11.png")}
          />
          <div className="text-wrapper-40">LPG YARD</div>
          <div className="PUMP-HOUSE-m">
            PUMP
            <br />
            HOUSE-6
            <br />
            576M3
          </div>
          <img
            className="vector-98"
            alt="Vector"
            src={require("./img/vector-10.png")}
          />
          <div className="COMPRESSOR-ROOM-n">
            COMPRESSOR
            <br />
            ROOM (N GRID)
          </div>
          <img
            className="vector-99"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <img
            className="vector-100"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <div className="text-wrapper-41">CT1</div>
          <div className="text-wrapper-42">CT2</div>
          <img
            className="vector-101"
            alt="Vector"
            src={require("./img/vector-6.png")}
          />
          <img
            className="vector-102"
            alt="Vector"
            src={require("./img/vector-6.png")}
          />
          <div className="text-wrapper-43">COMPRESSOR HOUSE</div>
          <div className="text-wrapper-44">COOLING TOWER</div>
          <img
            className="vector-103"
            alt="Vector"
            src={require("./img/vector-3.png")}
          />
          <img
            className="vector-104"
            alt="Vector"
            src={require("./img/vector-4.png")}
          />
          <img
            className="vector-105"
            alt="Vector"
            src={require("./img/vector-3.png")}
          />
          <div className="text-wrapper-45">COOLING TOWER</div>
          <p className="p">SOLAR CAR PARKING SHED 1</p>
          <p className="text-wrapper-46">SOLAR CAR PARKING SHED 2</p>
          <img
            className="vector-106"
            alt="Vector"
            src={require("./img/vector-2.png")}
          />
          <div className="SOLAR-CAR-PARKING">
            SOLAR CAR
            <br />
            PARKING
            <br />
            SHED 2
          </div>
          <img
            className="vector-107"
            alt="Vector"
            src={require("./img/vector-1.png")}
          />
          <img
            className="vector-108"
            alt="Vector"
            src={require("./img/vector.png")}
          />
          <div className="element-LITRE">
            5000
            <br />
            LITRE
          </div>
          <div className="element-LITRE-2">
            5000
            <br />
            LITRE
          </div>
          <img
            className="line-6"
            alt="Line"
            src={require("./img/line-23.png")}
          />
          <img
            className="line-7"
            alt="Line"
            src={require("./img/line-24.png")}
          />
          <div className="text-wrapper-47">T2</div>
          <div className="text-wrapper-48">T1</div>
        </div>
      </div>

      {meterClicked && (
        <div>
          {/* Pass meterNames as a prop to MeterDetails */}
          <MeterDetails
            show={openMeterModal}
            onShow={() => setOpenMeterModal(true)}
            onHide={() => setOpenMeterModal(false)}
            consumptionValue={totalVolume}
            flowRate={flowRate}
            meterNames={meterNames.flat()}
            dynamicName={dynamicName}
          />
        </div>
      )}
    </>
  );
};

export default DrinkingWaterLine;
