import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PieChart from "../Charts/PieChart";
import DoughNut from "../Charts/Doughnut";
import { Link } from "react-router-dom";
import Tabs from "../Layout/Tabs";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import { Loader } from "../Loader/Loader";
import "./style.css";
const styles = {
  MainDiv: {
    width: "100vw",
    height: `calc(100vh - 66.66-42px)`,
  },
  card: {
    background: "#F9F9F9",
    height: "120px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card2: {
    backgroundColor: "#F9F9F9",
    height: "332px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  card3: {
    backgroundColor: "#F9F9F9",
    height: "332px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  card4: {
    backgroundColor: "#F9F9F9",
    height: "300px",
    border: "none",
    margin: "",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subDiv: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
  ImgDiv: { borderRight: "solid black 3px" },
  Img: { marginRight: "30px", height: "50px", width: "50px" },
  TextDiv: { textAlign: "left", marginRight: "30px", color: "navy" },
  MainFont: { fontWeight: "bold" },
};

export const CXODashboard = () => {
  const { token } = useAuth();
  const URL = process.env.REACT_APP_URL;
  const [totalConsumption, setTotalConsumption] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}total-consumption-details`, {
          headers: {
            Authorization: `abc ${token}`,
          },
        });
        setTotalConsumption({ ...response?.data?.data } || {});
        // setTotalConsumption({
        //   ETP_RO_Transfer: 1002,
        //   Total_Fresh_Water: 2081,
        // });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const NeutralityRatioData = {
    A1: 0,
    A2: 0,
    A3: 0,
    A4: totalConsumption?.ETP_RO_Transfer,
    C: totalConsumption?.Total_Fresh_Water,
  };
  // console.log("NeutralityRatioData", NeutralityRatioData);

  const NeutralityRatio =
    (NeutralityRatioData?.A1 +
      NeutralityRatioData?.A2 +
      NeutralityRatioData?.A3 +
      NeutralityRatioData?.A4) /
    (NeutralityRatioData?.C +
      NeutralityRatioData?.A1 +
      NeutralityRatioData?.A4);

  const Data = {
    A1: 0,
    A2: 0,
    A3: 0,
    A4: Math.round(
      isNaN(Number(totalConsumption?.ETP_RO_Transfer))
        ? 0
        : Number(totalConsumption?.ETP_RO_Transfer)
    ),
    C: Math.round(Number(totalConsumption?.Total_Fresh_Water)),
  };

  const data = {
    labels: [
      "A1 : Rainwater Storage",
      "A2 : Ground Recharge",
      "A3 : Off-site Recharge",
      "A4 : Recycled Water",
      " C  : Freshwater Consumed",
    ],
    datasets: [
      {
        label: "",
        data: Object.values(Data),
        backgroundColor: [
          "#1E5094",
          "#FFBD2E",
          "#71797E",
          "#008000",
          "#C30000",
        ],
        borderColor: ["#1E5094", "#FFBD2E", "#71797E", "#008000", "#C30000"],
        borderWidth: 1,
      },
    ],
  };

  // const DougData = {
  //   labels: ["A", "B"],
  //   datasets: [
  //     {
  //       NeutralityRatio: NeutralityRatio,
  //       label: "",
  //       data: [0.26, 0.76],
  //       backgroundColor: ["#C30000", "#1e5094"],
  //       borderColor: ["#C30000", "#1e5094"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  const DougData = {
    labels: ["A", "B"],
    datasets: [
      {
        NeutralityRatio: NeutralityRatio,
        label: "",
        data: [1 - NeutralityRatio, NeutralityRatio], // Calculate the values here
        backgroundColor: ["#C30000", "#1e5094"],
        borderColor: ["#C30000", "#1e5094"],
        // backgroundColor: ["#1e5094", "#C30000"],
        // borderColor: ["#1e5094", "#C30000"],
        borderWidth: 1,
      },
    ],
  };
  // console.log("DougData", DougData);

  const currentDate = new Date();
  const startDay = subDays(currentDate, 2);
  const endDay = subDays(currentDate, 1);
  const startTime = format(endOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} md={12} style={{ paddingRight: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={{ marginRight: "20px" }}
                  />
                  {` ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8} style={{ padding: "20px" }}>
                <div className="card" style={styles.card3}>
                  <PieChart data={data} width={450} height={450} />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-90px",
                      width: "100%",
                      alignItems: "center",
                      marginBottom: "80px",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{
                        color: "#000",
                        marginInline: "auto",
                        fontSize: "22px",
                      }}
                    >
                      Water Neutrality
                    </div>
                    <Link to="/detailedcharts">
                      <div style={{ color: "#375EAD", marginRight: "20px" }}>
                        See details...
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4} style={{ padding: "20px" }}>
                <div className="card" style={styles.card2}>
                  <DoughNut
                    data={DougData}
                    NeutralityRatio={
                      isNaN(Number(NeutralityRatio))
                        ? "NA"
                        : Number(NeutralityRatio).toFixed(2)
                    }
                  />
                  {/* <div
                    style={{
                      height: "250px",
                      width: "250px",
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    <h3
                      className="blink"
                      style={{
                        color: "Green",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Under Scheduled Maintenance
                    </h3>
                  </div> */}
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      marginTop: "-15px",
                    }}
                  >
                    <div
                      style={{
                        color: "#000",
                        fontSize: "22px",
                      }}
                    >
                      Neutrality Ratio
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6} style={{ padding: "20px" }}>
                <div className="card" style={styles.card}>
                  <h2 style={{ color: "#C30000", fontWeight: "bold" }}>
                    {totalConsumption?.Total_Fresh_Water !== null
                      ? Math.round(totalConsumption?.Total_Fresh_Water)
                      : "--"}{" "}
                    KL
                  </h2>
                  <h5>FRESH WATER CONSUMPTION (Last 24 hrs)</h5>
                </div>
              </Col>

              <Col xs={12} md={6} style={{ padding: "20px" }}>
                <div className="card" style={styles.card}>
                  <h3
                    style={{
                      color: "Green",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {totalConsumption?.ETP_RO_Transfer !== null
                      ? Math.round(totalConsumption?.ETP_RO_Transfer)
                      : "--"}{" "}
                    KL
                  </h3>
                  <h5>RECYCLED WATER (Last 24 hrs)</h5>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default CXODashboard;
