import Nav from "react-bootstrap/Nav";
import { useLocation } from "react-router-dom";
import "../Styles/Tabs.css";

const styles = {
  activeTab: {
    borderRadius: "5px 5px 0px 0px",
    background: "#FFF",
    boxShadow: "4px -4px 8px 0px rgba(0, 0, 0, 0.05)",
    color: "var(--primary-blue, #375EAD)",
    border: "none",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  deactiveTab: {
    border: "none",
    color: "#5B5B5B",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

function Tabs() {
  const path = useLocation();
  return (
    <Nav
      variant="tabs"
      defaultActiveKey={
        path.pathname === "/processwaterline"
          ? "/processwaterline"
          : path.pathname === "/cxodashboard"
          ? "/cxodashboard"
          : path.pathname === "/reports"
          ? "/reports"
          : null
      }
    >
      <Nav.Item>
        <Nav.Link
          className="NavLink"
          style={
            path.pathname.includes("cxodashboard")
              ? styles.activeTab
              : styles.deactiveTab
          }
          eventKey="/cxodashboard"
          href="/cxodashboard"
        >
          CXO Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className="NavLink"
          eventKey="/waternetwork"
          href="/waternetwork/processwaterline"
          style={
            path.pathname.includes("waternetwork")
              ? styles.activeTab
              : styles.deactiveTab
          }
        >
          Water Networks
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className="NavLink"
          eventKey="/reports"
          href="/reports/plantblocks"
          style={
            path.pathname.includes("reports")
              ? styles.activeTab
              : styles.deactiveTab
          }
        >
          Reports
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className="NavLink"
          eventKey="/history"
          href="/history"
          style={
            path.pathname.includes("history")
              ? styles.activeTab
              : styles.deactiveTab
          }
        >
          History
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default Tabs;
