import React, { useState } from "react";
import "./style.css";
import Tabs from "../../Layout/Tabs";
import { Link } from "react-router-dom";
import MeterDetails from "../Modals/MeterDetails";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";

export const ProcessWaterLine = () => {
  const [openMeterModal, setOpenMeterModal] = useState(false);
  // const [selectedMeter, setSelectedmeters] = useState("");
  const [meterName, setMeterName] = useState("");
  const [value, setValue] = useState("");
  const [consumptionValue, setConsumptionValue] = useState(0);
  const [flowRate, setFlowRate] = useState(0);
  const { token } = useAuth();
  // const [totalVolume, setTotalVolume] = useState([]);
  const [meterClicked, setMeterClicked] = useState(false);
  // const [openMeterModal, setOpenMeterModal] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState(null);
  // const [flowRate, setFlowRate] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [meterNames, setMeterNames] = useState([]);
  // const [meterClicked, setMeterClicked] = useState(false);

  const DrinkingArr = [
    {
      M1: "Pump House-4",
      M2: "Pump House-4",
      M3: "Pump House-4",
      M4: "Pump House-4",
      M5: "Pump House-4",
      M50: "Pump House-4",
      M51: "Pump House-4",
    },
    {
      M15: "Paint Shop",
      M17: "Paint Shop",
      M18: "Paint Shop",

      M20: "Paint Shop",
      M21: "Paint Shop",
    },
    {
      M23: "Press Shop",
    },
    {
      M24: "Weld Shop",
    },
    {
      M26: "EV Shop",
    },
    {
      M27: "Office Block",
      M28: "Office Block",
      M29: "Office Block",
      M30: "Office Block",
    },
    {
      M31: "PDI",
    },
    {
      M33: "TCF2P",
    },
    {
      M36: "TCF-1",
      M37: "TCF-1",
      M38: "TCF-1",
    },
    {
      M40: "Engine",
      M41: "Engine",
    },

    {
      M43: "JLR",
    },
    {
      M45: "PDI",
    },
    {
      M49: "Scarpyard",
    },
    {
      M59: "Compressor Room",
    },
    {
      M61: "Press Shop-Process",
    },
    {
      M63: "Parking Area Drinking",
    },
    {
      M62: "A Grid-Process ",
    },
    {
      M56: "ETP",
      M57: "ETP",
    },
  ];

  const extractPropertyValues = (arr, propertyNames) => {
    return arr.map((item) =>
      propertyNames.map((propertyName) => item[propertyName])
    );
  };

  const handleMeters = async (flag, meter) => {
    let url = `${process.env.REACT_APP_URL}get-meters-data`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`, // Replace with your actual token
        },
      });

      const { meterName, value, data } = response.data;

      // Moved setMeterNames and setSelectedMeter outside of handleMeters
      setMeterNames(extractPropertyValues(DrinkingArr, [meter]));
      setSelectedMeter(meter);
      //
      const meterData = response.data.data;
      // console.log(
      //   "Meter Data:",
      //   meter,
      //   meterData,
      //   meterData.hasOwnProperty(`${meter}FR`),
      //   meterData.hasOwnProperty(`${meter}`)
      // );

      if (meterData) {
        // Check if the meter has Flow Rate (FR) and Total Volume (TV) properties
        if (
          meterData.hasOwnProperty(`${meter}FR`) &&
          meterData.hasOwnProperty(`${meter}`)
        ) {
          const flowRate = meterData[`${meter}FR`];
          const totalVolume = meterData[`${meter}`];
          // console.log("Flow Rate:", flowRate);
          // console.log("Total Volume:", totalVolume);
          // Set the state variables for Flow Rate and Total Volume
          setFlowRate(flowRate);
          setTotalVolume(totalVolume);

          // You can use flowRate and totalVolume values as needed in your component
        }
      }

      setOpenMeterModal(true);
      setMeterClicked(true);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };

  const dynamicName = "Process Line";

  return (
    <>
      <Tabs />
      <div className="process-water-line">
        <div className="div">
          <div
            style={{
              color: "#375EAD",
              fontWeight: "bold",
              fontSize: "18px",
              margin: "30px 0px",
              width: "100%",
              height: "30px",
              textAlign: "center",
            }}
          >
            Plant View
          </div>
          <Link to="/waternetwork/processwaterline">
            <div className="rectangle-4" />
            <div className="text-wrapper-4">Process Water Line</div>
          </Link>

          <Link to="/waternetwork/drinkingwaterline">
            <div className="rectangle-5" />
            <div className="text-wrapper-6">Drinking Water Line</div>
          </Link>

          <Link to="/waternetwork/horiculturewaterline">
            <div className="rectangle-6" />
            <div className="text-wrapper-5">Horiculture Water Line</div>
          </Link>

          <img
            className="vector"
            alt="Vector"
            src={require("./img/vector-68.png")}
          />
          <img
            className="img"
            alt="Vector"
            src={require("./img/vector-66.png")}
          />
          <img
            className="vector-2"
            alt="Vector"
            src={require("./img/vector-71.png")}
          />
          <img
            className="vector-3"
            alt="Vector"
            src={require("./img/vector-70.png")}
          />
          <img
            className="vector-4"
            alt="Vector"
            src={require("./img/vector-69.png")}
          />
          <div className="text-wrapper-7">K BLOCK</div>
          <div className="text-wrapper-8">JLR</div>
          <div className="text" />
          <img
            className="vector"
            alt="Vector"
            src={require("./img/vector-68.png")}
          />
          <img
            className="vector-5"
            alt="Vector"
            src={require("./img/vector-67.png")}
          />
          <img
            className="img"
            alt="Vector"
            src={require("./img/vector-66.png")}
          />
          <img
            className="vector-6"
            alt="Vector"
            src={require("./img/vector-65.png")}
          />
          <img
            className="vector-7"
            alt="Vector"
            src={require("./img/vector-64.png")}
          />
          <img
            className="vector-8"
            alt="Vector"
            src={require("./img/vector-63.png")}
          />
          <img
            className="vector-9"
            alt="Vector"
            src={require("./img/vector-62.png")}
          />
          <img
            className="vector-10"
            alt="Vector"
            src={require("./img/vector-61.png")}
          />
          <img
            className="vector-11"
            alt="Vector"
            src={require("./img/vector-60.png")}
          />
          <img
            className="vector-12"
            alt="Vector"
            src={require("./img/vector-59.png")}
          />
          <img
            className="vector-13"
            alt="Vector"
            src={require("./img/vector-58.png")}
          />
          <img
            className="vector-14"
            alt="Vector"
            src={require("./img/vector-57.png")}
          />
          <img
            className="vector-15"
            alt="Vector"
            src={require("./img/vector-56.png")}
          />
          <img
            className="vector-16"
            alt="Vector"
            src={require("./img/vector-55.png")}
          />
          <img
            className="vector-17"
            alt="Vector"
            src={require("./img/vector-54.png")}
          />
          <img
            className="vector-18"
            alt="Vector"
            src={require("./img/vector-53.png")}
          />
          <img
            className="vector-19"
            alt="Vector"
            src={require("./img/vector-52.png")}
          />
          <img
            className="vector-20"
            alt="Vector"
            src={require("./img/vector-51.png")}
          />
          <Link to="/waternetwork/paintshop">
            <img
              className="vector-21"
              alt="Vector"
              src={require("./img/vector-50.png")}
            />
          </Link>
          <img
            className="vector-22"
            alt="Vector"
            src={require("./img/vector-49.png")}
          />
          <img
            className="vector-23"
            alt="Vector"
            src={require("./img/vector-48.png")}
          />
          <img
            className="vector-24"
            alt="Vector"
            src={require("./img/vector-47.png")}
          />
          <div className="text-wrapper-9">WELD SHOP</div>
          <img
            className="vector-25"
            alt="Vector"
            src={require("./img/vector-46.png")}
          />
          <img
            className="vector-26"
            alt="Vector"
            src={require("./img/vector-45.png")}
          />
          <img
            className="vector-27"
            alt="Vector"
            src={require("./img/vector-44.png")}
          />
          <img
            className="vector-28"
            alt="Vector"
            src={require("./img/vector-43.png")}
          />
          <img
            className="vector-29"
            alt="Vector"
            src={require("./img/vector-42.png")}
          />
          <img
            className="vector-30"
            alt="Vector"
            src={require("./img/vector-41.png")}
          />
          <img
            className="vector-31"
            alt="Vector"
            src={require("./img/vector-40.png")}
          />
          <img
            className="vector-32"
            alt="Vector"
            src={require("./img/vector-38.png")}
          />
          <img
            className="vector-33"
            alt="Vector"
            src={require("./img/vector-38.png")}
          />
          <img
            className="vector-34"
            alt="Vector"
            src={require("./img/vector-37.png")}
          />
          <img
            className="vector-35"
            alt="Vector"
            src={require("./img/vector-7.png")}
          />
          <img
            className="vector-36"
            alt="Vector"
            src={require("./img/vector-35.png")}
          />
          <img
            className="vector-37"
            alt="Vector"
            src={require("./img/vector-34.png")}
          />
          <img
            className="vector-38"
            alt="Vector"
            src={require("./img/vector-33.png")}
          />
          <img
            className="vector-39"
            alt="Vector"
            src={require("./img/vector-32.png")}
          />
          <img
            className="vector-40"
            alt="Vector"
            src={require("./img/vector-31.png")}
          />
          <div className="OFFICE-BUILDING">
            OFFICE <br />
            BUILDING
          </div>
          <img
            className="vector-41"
            alt="Vector"
            src={require("./img/vector-29.png")}
          />
          <img
            className="vector-42"
            alt="Vector"
            src={require("./img/vector-29.png")}
          />
          <img
            className="vector-43"
            alt="Vector"
            src={require("./img/vector-28.png")}
          />
          <img
            className="vector-44"
            alt="Vector"
            src={require("./img/vector-26.png")}
          />
          <img
            className="vector-45"
            alt="Vector"
            src={require("./img/vector-26.png")}
          />
          <img
            className="vector-46"
            alt="Vector"
            src={require("./img/vector-25.png")}
          />
          <img
            className="vector-47"
            alt="Vector"
            src={require("./img/vector-24.png")}
          />
          <img
            className="vector-48"
            alt="Vector"
            src={require("./img/vector-23.png")}
          />
          <div className="UNDERGROUND-TANK">
            UNDERGROUND <br />
            TANK
          </div>
          <div className="PUMP-FOR-LIFT">
            PUMP
            <br />
            FOR LIFT
          </div>
          <div className="TERRACE-TANK">
            TERRACE
            <br />
            TANK
          </div>
          <div className="text-wrapper-10">JLR SHOP FLOOR</div>
          <div className="text-wrapper-11">S GATE</div>
          <div className="text-wrapper-12">POLYHOUSE</div>
          <div className="text-wrapper-13">SOUTH TOILET</div>
          <div className="text-wrapper-14">CANTEEN</div>
          <div className="text-wrapper-15">GDC SUMO GATE</div>
          <div className="text-wrapper-16">PARKING</div>
          <div className="text-wrapper-17">OFFICE BLOCK</div>
          <div className="text-wrapper-18">PRESS SHOP</div>
          <div className="text-wrapper-19">PAINT SHOP</div>
          <div className="text-wrapper-20">ETP</div>
          <div className="text-wrapper-21">TCF 2</div>
          <img
            className="line-2"
            alt="Line"
            src={require("./img/line-18.png")}
          />
          <img
            className="vector-49"
            alt="Vector"
            src={require("./img/vector-22.png")}
          />
          <div className="text-wrapper-22">MRS-3 BLOCK</div>
          <img
            className="vector-50"
            alt="Vector"
            src={require("./img/vector-21.png")}
          />
          <div className="text-wrapper-23">CANTEEN</div>
          <div className="text-wrapper-24">KITCHEN</div>
          <div className="text-wrapper-25">MATERIAL GATE</div>
          <div className="text-wrapper-26">CANTEEN 2</div>
          <div className="text-wrapper-27">CANTEEN 1</div>
          <div className="SUPPORT-SERVICE">
            SUPPORT
            <br />
            SERVICE
            <br />
            BUILDING
          </div>
          <div className="text-wrapper-28">LW SHOP</div>
          <div className="text-wrapper-29">EV SHOP</div>
          <div className="text-wrapper-30">COLLECTING TOWER</div>
          <div className="text-wrapper-31">EMERGENCY TANK</div>
          <div className="text-wrapper-32">FIRE CONTROL</div>
          <div className="text-wrapper-33">JRL PDI</div>
          <div className="text-wrapper-34">CANTEEN</div>
          <div className="text-wrapper-35">GDC BUILDING</div>
          <div className="text-wrapper-36">SCRAPYARD</div>
          <div className="text-wrapper-37">POLYHOUSE</div>
          <img
            className="line-3"
            alt="Line"
            src={require("./img/line-19.png")}
          />
          <img
            className="vector-51"
            alt="Vector"
            src={require("./img/vector-20.png")}
          />
          <div className="TANK-EACH">
            TANK
            <br />
            5M3 EACH
          </div>
          <img
            className="vector-52"
            alt="Vector"
            src={require("./img/vector-18.png")}
          />
          <img
            className="vector-53"
            alt="Vector"
            src={require("./img/vector-18.png")}
          />
          <img
            className="vector-54"
            alt="Vector"
            src={require("./img/vector-17.png")}
          />
          <img
            className="vector-55"
            alt="Vector"
            src={require("./img/vector-16.png")}
          />
          <div className="text-wrapper-38">LPG YARD</div>
          <div className="PUMP-HOUSE">
            PUMP
            <br />
            HOUSE-6
            <br />
            576M3
          </div>
          <img
            className="vector-56"
            alt="Vector"
            src={require("./img/vector-15.png")}
          />
          <div className="COMPRESSOR-ROOM-n">
            COMPRESSOR
            <br />
            ROOM (N GRID)
          </div>
          <img
            className="paint-compressor-connector"
            alt="Line"
            src={require("./img/line-43.png")}
          />
          <img
            className="vector-57"
            alt="Vector"
            src={require("./img/vector-13.png")}
          />
          <img
            className="vector-58"
            alt="Vector"
            src={require("./img/vector-13.png")}
          />
          <div className="text-wrapper-39">CT1</div>
          <div className="text-wrapper-40">CT2</div>
          <img
            className="vector-59"
            alt="Vector"
            src={require("./img/vector-11.png")}
          />
          <img
            className="vector-60"
            alt="Vector"
            src={require("./img/vector-11.png")}
          />
          <div className="text-wrapper-41">COMPRESSOR HOUSE</div>
          <div className="text-wrapper-42">COOLING TOWER</div>
          <img
            className="vector-61"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <img
            className="vector-62"
            alt="Vector"
            src={require("./img/vector-9.png")}
          />
          <img
            className="vector-63"
            alt="Vector"
            src={require("./img/vector-8.png")}
          />
          <div className="text-wrapper-43">COOLING TOWER</div>
          <p className="p">SOLAR CAR PARKING SHED 1</p>
          <p className="text-wrapper-44">SOLAR CAR PARKING SHED 2</p>
          <img
            className="vector-64"
            alt="Vector"
            src={require("./img/vector-7.png")}
          />
          <div className="SOLAR-CAR-PARKING">
            SOLAR CAR
            <br />
            PARKING
            <br />
            SHED 2
          </div>
          <img
            className="vector-65"
            alt="Vector"
            src={require("./img/vector-6.png")}
          />
          <div className="element-LITRE">
            5000
            <br />
            LITRE
          </div>
          <div className="element-LITRE-2">
            5000
            <br />
            LITRE
          </div>
          <img
            className="line-4"
            alt="Line"
            src={require("./img/line-23.png")}
          />
          <img
            className="vector-66"
            alt="Vector"
            src={require("./img/vector-5.png")}
          />
          <img
            className="line-3"
            alt="Line"
            src={require("./img/line-24.png")}
          />
          <img
            className="line-5"
            alt="Line"
            src={require("./img/line-43.png")}
          />
          <img
            className="t1-to-line-5"
            alt="Line"
            src={require("./img/line-43.png")}
          />
          <img
            className="t2-to-line-5"
            alt="Line"
            src={require("./img/line-43.png")}
          />
          <div className="text-wrapper-45">T2</div>
          <div className="text-wrapper-46">T1</div>
          <img
            className="vector-67"
            alt="Vector"
            src={require("./img/vector-4.png")}
          />
          <img
            className="line-6"
            alt="Line"
            src={require("./img/line-43.png")}
          />
          <img
            className="line-7"
            alt="Line"
            src={require("./img/line-47.png")}
          />
          <img
            className="line-8"
            alt="Line"
            src={require("./img/line-75.png")}
          />
          <img
            className="line-9"
            alt="Line"
            src={require("./img/line-55.png")}
          />
          <img
            className="line-10"
            alt="Line"
            src={require("./img/line-55.png")}
          />
          <img
            className="line-11"
            alt="Line"
            src={require("./img/line-51.png")}
          />
          <img
            className="line-12"
            alt="Line"
            src={require("./img/line-52.png")}
          />
          <img
            className="line-13"
            alt="Line"
            src={require("./img/line-46.png")}
          />
          <img
            className="line-14"
            alt="Line"
            src={require("./img/line-48.png")}
          />
          <img
            className="line-15"
            alt="Line"
            src={require("./img/line-49.png")}
          />
          <img
            className="line-16"
            alt="Line"
            src={require("./img/line-45.png")}
          />
          <img
            className="line-17"
            alt="Line"
            src={require("./img/line-44.png")}
          />
          <img
            className="line-18"
            alt="Line"
            src={require("./img/line-53.png")}
          />
          <img
            className="line-19"
            alt="Line"
            src={require("./img/line-57.png")}
          />
          <img
            className="line-20"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <img
            className="line-21"
            alt="Line"
            src={require("./img/line-83.png")}
          />
          <img
            className="line-22"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <img
            className="line-23"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <img
            className="line-24"
            alt="Line"
            src={require("./img/line-57.png")}
          />
          <img
            className="line-25"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <img
            className="line-26"
            alt="Line"
            src={require("./img/line-67.png")}
          />
          <img
            className="line-27"
            alt="Line"
            src={require("./img/line-68.png")}
          />
          <img
            className="line-28"
            alt="Line"
            src={require("./img/line-72.png")}
          />
          <img
            className="line-29"
            alt="Line"
            src={require("./img/line-72.png")}
          />
          <img
            className="line-30"
            alt="Line"
            src={require("./img/line-72.png")}
          />
          <img
            className="line-31"
            alt="Line"
            src={require("./img/line-73.png")}
          />
          <img
            className="line-32"
            alt="Line"
            src={require("./img/line-74.png")}
          />
          <img
            className="line-33"
            alt="Line"
            src={require("./img/line-69.png")}
          />
          <img
            className="line-34"
            alt="Line"
            src={require("./img/line-76.png")}
          />
          <img
            className="line-35"
            alt="Line"
            src={require("./img/line-76.png")}
          />
          <img
            className="line-36"
            alt="Line"
            src={require("./img/line-79.png")}
          />
          <img
            className="line-37"
            alt="Line"
            src={require("./img/line-81.png")}
          />
          <img
            className="line-38"
            alt="Line"
            src={require("./img/line-77.png")}
          />
          <img
            className="line-39"
            alt="Line"
            src={require("./img/line-80.png")}
          />
          <img
            className="line-40"
            alt="Line"
            src={require("./img/line-82.png")}
          />
          <img
            className="line-41"
            alt="Line"
            src={require("./img/line-64.png")}
          />
          <img
            className="line-42"
            alt="Line"
            src={require("./img/line-65.png")}
          />
          <img
            className="line-43"
            alt="Line"
            src={require("./img/line-66.png")}
          />
          <img
            className="line-44"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <img
            className="line-45"
            alt="Line"
            src={require("./img/line-61.png")}
          />
          <Link to="/waternetwork/pumphouse">
            <img
              className="vector-68"
              alt="Vector"
              src={require("./img/vector-3.png")}
            />
          </Link>
          <div className="PUMP-HOUSE-2">
            PUMP
            <br />
            HOUSE-4
          </div>
          <img
            className="vector-69"
            alt="Vector"
            src={require("./img/vector-2.png")}
          />
          <div className="text-wrapper-47">TCF 1</div>
          <img
            className="line-46"
            alt="Line"
            src={require("./img/line-50.png")}
          />
          <img
            className="vector-70"
            alt="Vector"
            src={require("./img/vector-1.png")}
          />
          <div className="text-wrapper-48">ENGINE SHOP</div>
          <img
            className="polygon"
            alt="Polygon"
            src={require("./img/polygon-50.png")}
          />
          <img
            className="polygon-2"
            alt="Polygon"
            src={require("./img/polygon-73.png")}
          />
          <img
            className="polygon-3"
            alt="Polygon"
            src={require("./img/polygon-52.png")}
          />
          <img
            className="polygon-4"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-5"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-6"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-7"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-8"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-9"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-10"
            alt="Polygon"
            src={require("./img/polygon-72.png")}
          />
          <img
            className="polygon-11"
            alt="Polygon"
            src={require("./img/polygon-72.png")}
          />
          <img
            className="polygon-12"
            alt="Polygon"
            src={require("./img/polygon-73.png")}
          />
          <img
            className="polygon-13"
            alt="Polygon"
            src={require("./img/polygon-74.png")}
          />
          <img
            className="polygon-14"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-15"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-16"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-17"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-18"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-19"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-20"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-21"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-22"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-23"
            alt="Polygon"
            src={require("./img/polygon-69.png")}
          />
          <img
            className="polygon-24"
            alt="Polygon"
            src={require("./img/polygon-64.png")}
          />
          <img
            className="polygon-25"
            alt="Polygon"
            src={require("./img/polygon-64.png")}
          />
          <img
            className="vector-71"
            alt="Vector"
            src={require("./img/vector.png")}
          />
          <div className="FROM-PUMP-HOUSE">
            FROM
            <br />
            PUMP HOUSE-3
          </div>
          <img
            className="line-47"
            alt="Line"
            src={require("./img/line-20.png")}
          />
          <img
            className="m1-group"
            alt="Group"
            src={require("./img/group-13.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M5");
            }}
          />
          <img
            className="m2-group"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M4");
            }}
          />
          <img
            className="m3-group"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M3");
            }}
          />
          <img
            className="m5-group"
            alt="Group"
            src={require("./img/group-14.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M4");
            }}
          />
          <img
            className="m11-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M33");
            }}
          />
          <img
            className="m12-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M36");
            }}
          />
          <img
            className="m13-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M37");
            }}
          />
          <img
            className="m15-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M38");
            }}
          />

          <img
            className="m17-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M15");
            }}
          />
          <img
            className="m28-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M59");
            }}
          />
          <img
            className="m18-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M3");
            }}
          />
          <img
            className="m22-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M17");
            }}
          />
          <img
            className="m23-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M62");
            }}
          />
          <img
            className="m29-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M40");
            }}
          />
          <img
            className="m34-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M41");
            }}
          />

          <img
            className="m40-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M31");
            }}
          />
          <img
            className="m41-group"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M45");
            }}
          />

          <img
            className="group-3"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M61");
            }}
          />
          <img
            className="group-4"
            alt="Group"
            src={require("./img/group-17.png")}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleMeters(true, "M57");
            }}
          />

          <p className="TO-j-BLOCK">
            TO J BLOCK
            <br />
            11 &amp; 12
          </p>
          <div className="ENGINE-MAIN-LINE">
            ENGINE <br />
            MAIN LINE
          </div>
          <div className="text-wrapper-49">0 GRID LINE</div>
        </div>
      </div>
      <img
        className="vector-72"
        alt="Vector"
        src={require("./img/vector-10-1.png")}
      />

      {meterClicked && (
        <div>
          {/* Pass meterNames as a prop to MeterDetails */}
          <MeterDetails
            show={openMeterModal}
            onShow={() => setOpenMeterModal(true)}
            onHide={() => setOpenMeterModal(false)}
            consumptionValue={totalVolume}
            flowRate={flowRate}
            meterNames={meterNames.flat()}
            dynamicName={dynamicName}
          />
        </div>
      )}
    </>
  );
};

export default ProcessWaterLine;
