import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles = {
  MainDiv: {
    width: "100vw",
    height: `calc(100vh - 66.66-42px)`,
  },
  card: {
    background: "#F9F9F9",
    height: "260px",
    width: "400px",
    border: "none",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "4.361px",
    boxShadow:
      "0px 0px 7.63194465637207px 1.0902777910232544px rgba(0, 0, 0, 0.20)",
  },
  column:{ padding: "20px" },
  container:{padding: "20px"}
};

const ModuleSelection = () => {
  return (
    <div style={styles.MainDiv}>
      <Container fluid style={styles.container}>
        <Row>
          <Col xs={12} md={6} style={styles.column}>
            <Link to={"/cxodashboard"}>
              <div className="card" style={styles.card}>
                <img
                  src={require("../Assets/drop (1).png")}
                  alt="clock"
                  height={150}
                  width={150}
                />
                <h4>Water</h4>
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} style={styles.column}>
            <Link to={"/upload"}>
              <div className="card" style={styles.card}>
                <img
                  src={require("../Assets/renewable-energy (1).png")}
                  alt="clock"
                  height={150}
                  width={150}
                />
                <h4>Electricity</h4>
              </div>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} style={styles.column}>
            <Link to={"/food"}>
              <div className="card" style={styles.card}>
                <img
                  src={require("../Assets/eco-food (1).png")}
                  alt="clock"
                  height={150}
                  width={150}
                />
                <h4>Waste</h4>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ModuleSelection;
