// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content{
  height: 355px;
}
.modal-header{
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Water Networks/Modals/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd","sourcesContent":[".modal-content{\n  height: 355px;\n}\n.modal-header{\n  height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
