import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = (props) => {

  const options = {
    responsive: true,
    backgroundColor: props.color,
    layout: {
      padding: {
        bottom: 0,
        left:0,
        top:20,
        right:0
      },
    },
    plugins: {
      
      legend: {
        display: false, 
      },
      datalabels: {
        anchor: 'end',
        align: 'top',

        font: {
            weight: 'bold',
            size: 10
        }
    }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: props.titleX,
          color: '#0F0F0F',
          font: {
            size: 12,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        min: props.min,
        max: props.max,
        stepSize: props.stepSize,
        beginAtZero: true,
        title: {
          display: true,
          text: props.titleY,
          color: '#0F0F0F',
          font: {
            size: 12,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 5 },
        },
      },
    },
  };

  return (
    <>
      <div style={{ margin: "auto" }}>
        <Bar
          options={options}
          data={props.data}
          className="custom-chart"
          height={props.height}
          width={props.width}
        />
      </div>
    </>
  );
};

export default BarChart;
