import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import { Loader } from "../Loader/Loader";
import TrendCard from "./components/TrendCard";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useAuth } from "../Context/AuthContext";
import ToastContext from "../Context/ToastContext";
import { Form } from "react-bootstrap";
import HistoryTabs from "./components/HistoryTabs";
import { saveAs } from "file-saver";
import Papa from "papaparse";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },
  dropdownCard: {
    backgroundColor: "#FFF",
    height: "70px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginRight: "10%",
  },
};

const HistoryReports = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const URL = process.env.REACT_APP_URL;
  const [etpConsumptionData, setEtpConsumptionData] = useState([]);
  const [otherConsumptionData, setOtherConsumptionData] = useState([]);
  const { toast } = useContext(ToastContext);
  const [etpChartData, setEtpChartData] = useState(null);
  const [otherConsumptionChartData, setOtherConsumptionChartData] =
    useState(null);
  const [selectedYear, setSelectedYear] = useState("2022 - 2023");

  const fetchETPData = useCallback(
    async (startYear, endYear) => {
      let url = `${URL}Etp_consumtion?startYear=${startYear}&endYear=${endYear}`;
      console.log(url);

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `abc ${token}`,
          },
        });

        if (response.status === 200) {
          if (response.data.status) {
            // Data is available, update the state
            setEtpConsumptionData(response?.data?.data);
            toast.success("Data Updated");
          } else {
            // Server responded with success status, but no data found
            toast.info(response.data.message || "No data found");
          }
        } else {
          // Handle other HTTP status codes
          toast.error(`Error: ${response.data.message}`);
        }
      } catch (err) {
        console.error(err.message);
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    },
    [token, URL, toast]
  );

  const fetchOtherConsumptionData = useCallback(
    async (startYear, endYear) => {
      let url = `${URL}history?startYear=${startYear}&endYear=${endYear}`;
      console.log(url);
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `abc ${token}`,
          },
        });

        setOtherConsumptionData(response?.data?.data);
      } catch (err) {
        console.error(err.response.data.message);
      } finally {
      }
    },
    [token, URL, toast]
  );

  useEffect(() => {
    fetchETPData(selectedYear.split(" - ")[0], selectedYear.split(" - ")[1]);
  }, [selectedYear, fetchETPData]);

  useEffect(() => {
    fetchOtherConsumptionData(
      selectedYear.split(" - ")[0],
      selectedYear.split(" - ")[1]
    );
  }, [selectedYear, fetchOtherConsumptionData]);
  const colors = [
    "#4DA7B7",
    "#617834",
    "#C66D2D",
    "#52BD6B",
    "#9D4AA5",
    "#783B58",
    "#4DA7B7",
    "#617834",
    "#C66D2D",
    "#52BD6B",
    "#9D4AA5",
    "#783B58",
  ];
  const getColor = (flag) => {
    return flag > colors.length ? colors[0] : colors[flag];
  };
  useEffect(() => {
    if (etpConsumptionData?.length > 0) {
      const chartLabels = etpConsumptionData.map((entry) => entry.Month);
      const chartDataKeys = Object.keys(etpConsumptionData[0]).filter(
        (key) => key !== "id" && key !== "Month"
      );

      const newChartData = chartDataKeys.map((key, index) => ({
        labels: chartLabels,
        datasets: [
          {
            label: key,
            data: etpConsumptionData.map((entry) =>
              entry[key] != null ? entry[key] : 0
            ),
            fill: false,
            backgroundColor: getColor(index),
            barThickness: key === "Total" ? 20 : 15,
          },
        ],
      }));

      setEtpChartData(newChartData);
    }
  }, [etpConsumptionData]);

  useEffect(() => {
    if (otherConsumptionData?.length > 0) {
      const chartLabels = otherConsumptionData.map((entry) => entry.Month);
      const chartDataKeys = Object.keys(otherConsumptionData[0]).filter(
        (key) => key !== "id" && key !== "Month" && key !== "Sr_No"
      );

      const newChartData = chartDataKeys.map((key, index) => ({
        labels: chartLabels,
        datasets: [
          {
            label: key,
            data: otherConsumptionData.map((entry) =>
              entry[key] != null ? entry[key] : 0
            ),
            fill: false,
            backgroundColor: getColor(index),
            barThickness: key === "Total_Consumption" ? 20 : 15,
          },
        ],
      }));
      setOtherConsumptionChartData(newChartData);
    }
  }, [otherConsumptionData]);

  const handleSelectYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleCSVDownload = (startYear, endYear) => {
    if (etpConsumptionData?.length > 0) {
      const csvData = etpConsumptionData.map((entry) => ({
        Sr_No: entry.id,
        Month: entry.Month,
        ETP_INLET: entry.ETP_INLET,
        Horticulture_RO_Reject: entry.Horticulture_RO_Reject,
        Inside_land_disposal: entry.Inside_land_disposal,
        RO_Feed: entry.RO_Feed,
        Rain_water_storage: entry.Rain_water_storage,
        Recycled_water: entry.Recycled_water,

        STP_INLET: entry.STP_INLET,
        Total: entry.Total,
      }));

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });

      saveAs(
        csvBlob,
        `TMPVL Effluent and Treated Water${startYear}_${endYear}.csv`
      );
      toast.success(" Data downloading, please wait...");
    }
  };
  const handleOtherCSVDownload = (startYear, endYear) => {
    if (otherConsumptionData?.length > 0) {
      const csvData = otherConsumptionData.map((entry) => ({
        Sr_No: entry.id,
        Month: entry.Month,
        Cooling_Tower: entry.Cooling_Tower,
        MIDC_Third_Party: entry.MIDC_Third_Party,
        MIDC_Third_Party_CVBU: entry.MIDC_Third_Party_CVBU,
        Process_Main: entry.Process_Main,
        Rain_Water_Storage: entry.Rain_Water_Storage,
        Recycle_Back_To_Process: entry.Recycle_Back_To_Process,
        Total_Consumption: entry.Total_Consumption,
        Total_Domestic_water: entry.Total_Domestic_water,
        Total_Fresh_Water_Consumption: entry.Total_Fresh_Water_Consumption,
      }));

      const csvBlob = new Blob([Papa.unparse(csvData, { headers: true })], {
        type: "text/csv;charset=utf-8;",
      });
      saveAs(csvBlob, `TMPVL Pune Water Consumtion${startYear}_${endYear}.csv`);
    }
  };

  const DataDownload = () => {
    const startYear = selectedYear.split(" - ")[0];
    const endYear = selectedYear.split(" - ")[1];

    handleCSVDownload(startYear, endYear);
    handleOtherCSVDownload(startYear, endYear);

    setTimeout(() => {
      const chart = document.getElementById("lineChart");

      if (chart) {
        chart.style.backgroundColor = "";
        chart.style.paddingTop = "";
        chart.style.borderRadius = "";
      } else {
        console.error("Chart element not found");
      }
    }, 0);
  };

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <HistoryTabs />
          <Row style={{ margin: "3%" }}>
            <div className="card" style={styles.dropdownCard}>
              <Col xs={1} md={2} style={{ textAlign: "center" }}>
                Select Year :
              </Col>
              <Col xs={1} md={3} style={{ width: "19%", marginRight: "6%" }}>
                <Form>
                  <Form.Select value={selectedYear} onChange={handleSelectYear}>
                    <option value="2022 - 2023">April 2022 - March 2023</option>
                    <option value="2023 - 2024">April 2023 - March 2024</option>
                  </Form.Select>
                </Form>
              </Col>
              <Col
                xs={1}
                md={7}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={DataDownload}
                  style={{ marginRight: " 93%", backgroundColor: "navy" }}
                >
                  Download
                </Button>
              </Col>
            </div>
          </Row>
          <Row style={{ margin: "3%" }}>
            {otherConsumptionChartData
              ?.filter(
                (item, index) =>
                  item?.datasets[0]?.label === "Total_Consumption"
              )
              ?.map((item, index) => (
                <Col md={6} key={index}>
                  <TrendCard chartData={item} />
                </Col>
              ))}

            {etpChartData
              ?.filter((item, index) => item?.datasets[0]?.label === "Total")
              ?.map((item, index) => (
                <Col md={6} key={index}>
                  <TrendCard chartData={item} />
                </Col>
              ))}
          </Row>
          <Row style={{ margin: "3%" }}>
            {otherConsumptionChartData
              ?.filter(
                (item, index) =>
                  item?.datasets[0]?.label !== "Total_Consumption"
              )
              ?.map((item, index) => (
                <Col md={4} key={index}>
                  <TrendCard chartData={item} />
                </Col>
              ))}

            {etpChartData
              ?.filter((item, index) => item?.datasets[0]?.label !== "Total")
              ?.map((item, index) => (
                <Col md={4} key={index}>
                  <TrendCard chartData={item} />
                </Col>
              ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default HistoryReports;
