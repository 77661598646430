// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

td,
th {
  text-align: center;
  padding: 16px;
}

th {
  background-color: #f9f9f9;
  font-size: 18px;
  color: #375ead;
  border-bottom: 2px solid #0f0f0f;
}

.ColSpan td {
  font-size: 16px;
  color: rgb(152, 5, 5);
  font-weight: bold;
}

.fixTableHead {
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Reports/styles/ReportTable.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["table {\n  font-family: arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n  height: 100%;\n}\n\ntd,\nth {\n  text-align: center;\n  padding: 16px;\n}\n\nth {\n  background-color: #f9f9f9;\n  font-size: 18px;\n  color: #375ead;\n  border-bottom: 2px solid #0f0f0f;\n}\n\n.ColSpan td {\n  font-size: 16px;\n  color: rgb(152, 5, 5);\n  font-weight: bold;\n}\n\n.fixTableHead {\n  overflow-y: hidden;\n  overflow-x: auto;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
