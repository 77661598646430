import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Meter from "./Components/Meter";
import { format } from "date-fns";
import MultiAxisLineChart from "./Components/MultiAxisLineChart";
import Data from "./Utils/Electricity.json";
import { Loader } from "../Loader/Loader";
import axios from "axios";
import CardsDateFilter from "../Reports/Components/CardsDateFilter";
import toast from "react-hot-toast";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  SolarTotal: {
    background: "#008000",
    width: "200px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    borderRadius: "15px",
  },
  GridTotal: {
    background: "#1565C0",
    width: "200px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "10px",
    borderRadius: "15px",
  },
};

const Electricity = () => {
  const [loading, setLoading] = useState(true);
  const [EleData, setEleData] = useState();
  const [EleData1, setEleData1] = useState();
  const URL = process.env.REACT_APP_URL;
  const formatDate = (dateString) => {
    const dateOnly = dateString.split("T")[0]; // Extracts the date part
    const [year, month, day] = dateOnly.split("-");
    const date = new Date(`${year}-${month}-${day}`);
    return format(date, "dd");
  };

  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: " ",
    enddate: " ",
  });

  ///////////
  const fetchAllData = useCallback(async () => {
    let url = `${URL}ecd-chart`;
    try {
      const response = await axios.get(url);
      // const response = await axios.get(url, {
      //   headers: {
      //     Authorization: `abc ${token}`,
      //   },
      // });
      console.log("response--", response);
      setEleData1(response?.data?.data || []);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      console.log("response", response?.data?.data);
      toast.success("Data Updated");
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchData = useCallback(async () => {
    let url = `${URL}ecd-chart?days=${dateFilter.days}&startdate=${dateFilter.startdate}&enddate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        // headers: {
        //   Authorization: `abc ${token}`,
        // },
      });
      console.log("res1--", res);
      setEleData1(res?.data?.data || []);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  }, [dateFilter]);

  const getTotal = (key) => {
    return Math.round(
      EleData1?.reduce(
        (accumulator, item) => addValues(item, key, accumulator),
        0
      )
    );
  };

  const addValues = (item, key, accumulator) => {
    if (item && item[key]) {
      return accumulator + parseFloat(item[key]);
    }
    return accumulator;
  };

  const labels1 = EleData1?.map((item) => formatDate(item?.date));
  const labels = [...new Set(labels1)];

  const CardData1 = [
    {
      title: "TCF - 1",
      Grid: EleData1?.map((item) => item?.TCF1_R1_Grid),
      Solar: EleData1?.map((item) => item?.TCF1_R1_Solar),
      GridTotal: getTotal("TCF1_R1_Grid"),
      SolarTotal: getTotal("TCF1_R1_Solar"),
      GridSolarTotal: getTotal("TCF1_R1_Grid") + getTotal("TCF1_R1_Solar"),
    },
    {
      title: "TCF - 2",
      Grid: EleData1?.map((item) => item?.TCF2_R2_Grid),
      Solar: EleData1?.map((item) => item?.TCF2_R2_Solar),
      GridTotal: getTotal("TCF2_R2_Grid"),
      SolarTotal: getTotal("TCF2_R2_Solar"),
      GridSolarTotal: getTotal("TCF2_R2_Total"),
    },
    {
      title: "Paint Shop (South) (P1)",
      Grid: EleData1?.map((item) => item?.PaintShop_South_P1),
      Solar: 0,
      GridTotal: getTotal("PaintShop_South_P1"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("PaintShop_South_P1"),
    },
    {
      title: "Paint Shop (South) (P2)",
      Grid: EleData1?.map((item) => item?.PaintShop_South_P2),
      Solar: 0,
      GridTotal: getTotal("PaintShop_South_P2"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("PaintShop_South_P2"),
    },
    {
      title: "Paint Shop (South) (P3)",
      Grid: EleData1?.map((item) => item?.PaintShop_South_P3_Grid),
      Solar: EleData1?.map((item) => item?.PaintShop_South_P3_Solar),
      GridTotal: getTotal("PaintShop_South_P3_Grid"),
      SolarTotal: getTotal("PaintShop_South_P3_Solar"),
      GridSolarTotal: getTotal("PaintShop_South_P3_Total"),
    },
    {
      title: "Paint House (P6)",
      Grid: EleData1?.map((item) => item?.PentHouse_P6_Grid),
      Solar: EleData1?.map((item) => item?.PentHouse_P6_Solar),
      GridTotal: getTotal("PentHouse_P6_Grid"),
      SolarTotal: getTotal("PentHouse_P6_Solar"),
      GridSolarTotal: getTotal("PentHouse_P6_Total"),
    },
    {
      title: "Paint House (P7)",
      Grid: EleData1?.map((item) => item?.PentHouse_P7_Grid),
      Solar: EleData1?.map((item) => item?.PentHouse_P7_Solar),
      GridTotal: getTotal("PentHouse_P7_Grid"),
      SolarTotal: getTotal("PentHouse_P7_Solar"),
      GridSolarTotal: getTotal("PentHouse_P7_Total"),
    },
    {
      title: "Paint House (P8)",
      Grid: EleData1?.map((item) => item?.PentHouse_P8),
      Solar: 0,
      GridTotal: getTotal("PentHouse_P8"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("PentHouse_P8"),
    },
    {
      title: "Paint Shop (North)(P4)",
      Grid: EleData1?.map((item) => item?.PaintShop_North_P4),
      Solar: 0,
      GridTotal: getTotal("PaintShop_North_P4"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("PaintShop_North_P4"),
    },
    {
      title: "Paint Shop (North)(P5)",
      Grid: EleData1?.map((item) => item?.PaintShop_North_P5),
      Solar: 0,
      GridTotal: getTotal("PaintShop_North_P5"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("PaintShop_North_P5"),
    },
    {
      title: "CED (Rect 1)",
      Grid: EleData1?.map((item) => item?.CED_Rect1),
      Solar: 0,
      GridTotal: getTotal("CED_Rect1"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CED_Rect1"),
    },
    {
      title: "CED (Rect 2)",
      Grid: EleData1?.map((item) => item?.CED_Rect2),
      Solar: 0,
      GridTotal: getTotal("CED_Rect2"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CED_Rect2"),
    },
    {
      title: "CED (Rect 3)",
      Grid: EleData1?.map((item) => item?.CED_Rect3),
      Solar: 0,
      GridTotal: getTotal("CED_Rect3"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CED_Rect3"),
    },
    {
      title: "LPG",
      Grid: EleData1?.map((item) => item?.LPG),
      Solar: 0,
      GridTotal: getTotal("LPG"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("LPG"),
    },
    {
      title: "Weld Shop (K-Block N1)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N1),
      Solar: 0,
      GridTotal: getTotal("K_WeldShop_N1"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("K_WeldShop_N1"),
    },
    {
      title: "Weld Shop (K-Block N2)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N2),
      Solar: 0,
      GridTotal: getTotal("K_WeldShop_N2"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("K_WeldShop_N2"),
    },
    {
      title: "Weld Shop (K-Block N3)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N3),
      Solar: 0,
      GridTotal: getTotal("K_WeldShop_N3"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("K_WeldShop_N3"),
    },
    {
      title: "Weld Shop (K-Block N6)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N6_Grid),
      Solar: EleData1?.map((item) => item?.K_WeldShop_N6_Solar),
      GridTotal: getTotal("K_WeldShop_N6_Grid"),
      SolarTotal: getTotal("K_WeldShop_N6_Solar"),
      GridSolarTotal: getTotal("K_WeldShop_N6_Total"),
    },
    {
      title: "Weld Shop (K-Block N7)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N7_Grid),
      Solar: EleData1?.map((item) => item?.K_WeldShop_N7_Solar),
      GridTotal: getTotal("K_WeldShop_N7_Grid"),
      SolarTotal: getTotal("K_WeldShop_N7_Solar"),
      GridSolarTotal: getTotal("K_WeldShop_N7_Total"),
    },
    {
      title: "Weld Shop (K-Block N8)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N8),
      Solar: 0,
      GridTotal: getTotal("K_WeldShop_N8"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("K_WeldShop_N8"),
    },

    {
      title: "Weld Shop (K-Block N9)",
      Grid: EleData1?.map((item) => item?.K_WeldShop_N9_Grid),
      Solar: EleData1?.map((item) => item?.K_WeldShop_N9_Solar),
      Target: EleData1?.map((item) => item?.K_WeldShop_N9_Total),
      GridTotal: getTotal("K_WeldShop_N9_Grid"),
      SolarTotal: getTotal("K_WeldShop_N9_Solar"),
      GridSolarTotal: getTotal("K_WeldShop_N9_Total"),
    },
    {
      title: "X4- Weld Shop (Altroze Weld Shop)(34)",
      Grid: EleData1?.map((item) => item?.X4_WeldShop_34),
      Solar: 0,
      GridTotal: getTotal("X4_WeldShop_34"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("X4_WeldShop_34"),
    },

    {
      title: "X4- Weld Shop (Altroze Weld Shop)(68)",
      Grid: EleData1?.map((item) => item?.X4_WeldShop_68),
      Solar: 0,
      GridTotal: getTotal("X4_WeldShop_68"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("X4_WeldShop_68"),
    },

    {
      title: "X4- Weld Shop (Altroze Weld Shop)(69)",
      Grid: EleData1?.map((item) => item?.X4_WeldShop_69_Grid),
      Solar: EleData1?.map((item) => item?.X4_WeldShop_69_Solar),
      GridTotal: getTotal("X4_WeldShop_69_Grid"),
      SolarTotal: getTotal("X4_WeldShop_69_Solar"),
      GridSolarTotal: getTotal("X4_WeldShop_69_Total"),
    },
    {
      title: "X4- Weld Shop (Altroze Weld Shop)(70)",
      Grid: EleData1?.map((item) => item?.X4_WeldShop_70),
      Solar: 0,
      GridTotal: getTotal("X4_WeldShop_70"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("X4_WeldShop_70"),
    },

    {
      title: "Q5- Weld Shop (Harrier Weld Shop)(33)",
      Grid: EleData1?.map((item) => item?.Q5_WeldShop_33),
      Solar: 0,
      GridTotal: getTotal("Q5_WeldShop_33"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("Q5_WeldShop_33"),
    },
    {
      title: "Q5- Weld Shop (Harrier Weld Shop)(35)",
      Grid: EleData1?.map((item) => item?.Q5_WeldShop_35),
      Solar: 0,
      GridTotal: getTotal("Q5_WeldShop_35"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("Q5_WeldShop_35"),
    },
    {
      title: "Q5- Weld Shop (Harrier Weld Shop)(71)",
      Grid: EleData1?.map((item) => item?.Q5_WeldShop_71),
      Solar: 0,
      GridTotal: getTotal("Q5_WeldShop_71"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("Q5_WeldShop_71"),
    },
    {
      title: "Q5- Weld Shop (Harrier Weld Shop) (72)",
      Grid: EleData1?.map((item) => item?.Q5_WeldShop_72_Grid),
      Solar: EleData1?.map((item) => item?.Q5_WeldShop_72_Solar),
      GridTotal: getTotal("Q5_WeldShop_72_Grid"),
      SolarTotal: getTotal("Q5_WeldShop_72_Solar"),
      GridSolarTotal: getTotal("Q5_WeldShop_72_Total"),
    },
    {
      title: "EV Shop (LWB)",
      Grid: EleData1?.map((item) => item?.EVShop_LWB_Grid),
      Solar: EleData1?.map((item) => item?.EVShop_LWB_Solar),
      GridTotal: getTotal("EVShop_LWB_Grid"),
      SolarTotal: getTotal("EVShop_LWB_Solar"),
      GridSolarTotal: getTotal("EVShop_LWB_Total"),
    },
    {
      title: "Press Shop N4",
      Grid: EleData1?.map((item) => item?.PressShop_N4_Grid),
      Solar: EleData1?.map((item) => item?.PressShop_N4_Solar),
      GridTotal: getTotal("PressShop_N4_Grid"),
      SolarTotal: getTotal("PressShop_N4_Solar"),
      GridSolarTotal: getTotal("PressShop_N4_Total"),
    },
    {
      title: "Press Shop N5",
      Grid: EleData1?.map((item) => item?.PressShop_N5_Grid),
      Solar: EleData1?.map((item) => item?.PressShop_N5_Solar),
      GridTotal: getTotal("PressShop_N5_Grid"),
      SolarTotal: getTotal("PressShop_N5_Solar"),
      GridSolarTotal: getTotal("PressShop_N5_Total"),
    },
    {
      title: "Comp House(L.T.)",
      Grid: EleData1?.map((item) => item?.CompHouse_LT),
      Solar: 0,
      GridTotal: getTotal("CompHouse_LT"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CompHouse_LT"),
    },
    {
      title: "Comp House(6.6SEC I)",
      Grid: EleData1?.map((item) => item?.CompHouse_LT_6_6SEC_1),
      Solar: 0,
      GridTotal: getTotal("CompHouse_LT_6_6SEC_1"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CompHouse_LT_6_6SEC_1"),
    },
    {
      title: "Comp House(6.6SEC II)",
      Grid: EleData1?.map((item) => item?.CompHouse_LT_6_6SEC_2),
      Solar: 0,
      GridTotal: getTotal("CompHouse_LT_6_6SEC_2"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("CompHouse_LT_6_6SEC_2"),
    },
    // {
    //   title: "Fabrication Yard",
    //   Grid: EleData1?.Q5WeldShopHarrier.map((item) => item?.Grid),
    //   Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
    //   GridTotal: 30721,
    //   SolarTotal: 15780,
    //   GridSolarTotal: 46501,
    // },
    {
      title: "Fabrication Yard (Amenity)",
      Grid: EleData1?.map((item) => item?.FabricationYard_Amenity),
      Solar: 0,
      GridTotal: getTotal("FabricationYard_Amenity"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("FabricationYard_Amenity"),
    },
    {
      title: "Transaxle M/C Shop (M3)",
      Grid: EleData1?.map((item) => item?.TransaxleM_C_Shop_M3_Grid),
      Solar: EleData1?.map((item) => item?.TransaxleM_C_Shop_M3_Solar),
      GridTotal: getTotal("TransaxleM_C_Shop_M3_Grid"),
      SolarTotal: getTotal("TransaxleM_C_Shop_M3_Solar"),
      GridSolarTotal: getTotal("TransaxleM_C_Shop_M3_Total"),
    },
    {
      title: "Transaxle M/C Shop (M6)",
      Grid: EleData1?.map((item) => item?.TransaxleM_C_Shop_M6_Grid),
      Solar: EleData1?.map((item) => item?.TransaxleM_C_Shop_M6_Solar),
      GridTotal: getTotal("TransaxleM_C_Shop_M6_Grid"),
      SolarTotal: getTotal("TransaxleM_C_Shop_M6_Solar"),
      GridSolarTotal: getTotal("TransaxleM_C_Shop_M6_Total"),
    },
    {
      title: "Transaxle M/C Shop (M10)",
      Grid: EleData1?.map((item) => item?.TransaxleM_C_Shop_M10),
      Solar: 0,
      GridTotal: getTotal("TransaxleM_C_Shop_M10"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("TransaxleM_C_Shop_M10"),
    },
    {
      title: "Heat Treatment (M4)",
      Grid: EleData1?.map((item) => item?.HeatTreatment_M4_Grid),
      Solar: EleData1?.map((item) => item?.HeatTreatment_M4_Solar),
      GridTotal: getTotal("HeatTreatment_M4_Grid"),
      SolarTotal: getTotal("HeatTreatment_M4_Solar"),
      GridSolarTotal: getTotal("HeatTreatment_M4_Total"),
    },
    {
      title: "Heat Treatment (M5)",
      Grid: EleData1?.map((item) => item?.HeatTreatment_M5_Grid),
      Solar: EleData1?.map((item) => item?.HeatTreatment_M5_Solar),
      GridTotal: getTotal("HeatTreatment_M5_Grid"),
      SolarTotal: getTotal("HeatTreatment_M5_Solar"),
      GridSolarTotal: getTotal("HeatTreatment_M5_Total"),
    },
    {
      title: "Eng M/C Testing & Assembly (M1)",
      Grid: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M1_Grid),
      Solar: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M1_Solar),
      GridTotal: getTotal("Eng_M_C_Testing_Assembly_M1_Grid"),
      SolarTotal: getTotal("Eng_M_C_Testing_Assembly_M1_Solar"),
      GridSolarTotal: getTotal("Eng_M_C_Testing_Assembly_M1_Total"),
    },
    {
      title: "Eng M/C Testing & Assembly (M2)",
      Grid: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M2_Grid),
      Solar: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M2_Solar),
      GridTotal: getTotal("Eng_M_C_Testing_Assembly_M2_Grid"),
      SolarTotal: getTotal("Eng_M_C_Testing_Assembly_M2_Solar"),
      GridSolarTotal: getTotal("Eng_M_C_Testing_Assembly_M2_Total"),
    },
    {
      title: "Eng M/C Testing & Assembly (M7)",
      Grid: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M7_Grid),
      Solar: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M7_Solar),
      GridTotal: getTotal("Eng_M_C_Testing_Assembly_M7_Grid"),
      SolarTotal: getTotal("Eng_M_C_Testing_Assembly_M7_Solar"),
      GridSolarTotal: getTotal("Eng_M_C_Testing_Assembly_M7_Total"),
    },
    {
      title: "Eng M/C Testing & Assembly (M8)",
      Grid: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M8_Grid),
      Solar: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M8_Solar),
      GridTotal: getTotal("Eng_M_C_Testing_Assembly_M8_Grid"),
      SolarTotal: getTotal("Eng_M_C_Testing_Assembly_M8_Solar"),
      GridSolarTotal: getTotal("Eng_M_C_Testing_Assembly_M8_Total"),
    },
    {
      title: "Eng M/C Testing & Assembly (M9)",
      Grid: EleData1?.map((item) => item?.Eng_M_C_Testing_Assembly_M9),
      Solar: 0,
      GridTotal: getTotal("Eng_M_C_Testing_Assembly_M9"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("Eng_M_C_Testing_Assembly_M9"),
    },

    {
      title: "JLR Assembly",
      Grid: EleData1?.map((item) => item?.JLR_Assembly_Grid),
      Solar: EleData1?.map((item) => item?.JLR_Assembly_Solar),
      GridTotal: getTotal("JLR_Assembly_Grid"),
      SolarTotal: getTotal("JLR_Assembly_Solar"),
      GridSolarTotal: getTotal("JLR_Assembly_Total"),
    },
    {
      title: "JLR Engine",
      Grid: EleData1?.map((item) => item?.JLR_Engine_Grid),
      Solar: EleData1?.map((item) => item?.JLR_Engine_Solar),
      GridTotal: getTotal("JLR_Engine_Grid"),
      SolarTotal: getTotal("JLR_Engine_Solar"),
      GridSolarTotal: getTotal("JLR_Engine_Total"),
    },
    {
      title: "Office Block (Office Block + Service Block + Canteen)",
      Grid: EleData1?.map((item) => item?.Office_Service_Canteen_Kitchen_Total),
      Solar: 0,
      GridTotal: getTotal("Office_Service_Canteen_Kitchen_Total"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("Office_Service_Canteen_Kitchen_Total"),
    },
    {
      title: "Tool Engineering (ERC_Total)",
      Grid: EleData1?.map((item) => item?.ERC_Total),
      Solar: 0,
      GridTotal: getTotal("ERC_Total"),
      SolarTotal: 0,
      GridSolarTotal: getTotal("ERC_Total"),
    },
    {
      title: "Tool Consumption Of Plant (X4 + Q5 + JLR + K Block)",
      Grid: EleData1?.map((item) => item?.TotalConsumptionPlant_Grid_Total),
      Solar: EleData1?.map((item) => item?.TotalConsumptionPlant_Solar_Total),
      GridTotal: getTotal("TotalConsumptionPlant_Grid_Total"),
      SolarTotal: getTotal("TotalConsumptionPlant_Solar_Total"),
      GridSolarTotal: getTotal("TotalConsumptionPlant_Total"),
    },
  ];
  ///////////////////////////
  const CardData = [
    {
      title: "TCF - 1",
      Grid: EleData?.TCF1Data.map((item) => item?.Grid),
      Solar: EleData?.TCF1Data.map((item) => item?.Solar),
      Target: EleData?.TCF1Data.map((item) => item?.Target),
      GridTotal: 316690,
      SolarTotal: 75460,
      GridSolarTotal: 392150,
    },
    {
      title: "TCF - 2",
      Grid: EleData?.TCF2Data.map((item) => item?.Grid),
      Solar: EleData?.TCF2Data.map((item) => item?.Solar),
      Target: EleData?.TCF2Data.map((item) => item?.Target),
      GridTotal: 76271,
      SolarTotal: 45392,
      GridSolarTotal: 121663,
    },
    {
      title: "Paint Shop (P3)",
      Grid: EleData?.PaintShopP3Data.map((item) => item?.Grid),
      Solar: EleData?.PaintShopP3Data.map((item) => item?.Solar),
      Target: EleData?.PaintShopP3Data.map((item) => item?.Target),
      GridTotal: 86081,
      SolarTotal: 14523,
      GridSolarTotal: 100604,
    },
    {
      title: "Paint Shop (P6)",
      Grid: EleData?.PaintShopP6Data.map((item) => item?.Grid),
      Solar: EleData?.PaintShopP6Data.map((item) => item?.Solar),
      Target: EleData?.PaintShopP6Data.map((item) => item?.Target),
      GridTotal: 236909,
      SolarTotal: 31381,
      GridSolarTotal: 268290,
    },
    {
      title: "Paint Shop (P7)",
      Grid: EleData?.PaintShopP7Data.map((item) => item?.Grid),
      Solar: EleData?.PaintShopP7Data.map((item) => item?.Solar),
      Target: EleData?.PaintShopP7Data.map((item) => item?.Target),
      GridTotal: 251046,
      SolarTotal: 23053,
      GridSolarTotal: 274099,
    },
    {
      title: "CED",
      Grid: EleData?.WeldShopKBlockN6.map((item) => item?.Grid),
      Solar: EleData?.WeldShopKBlockN6.map((item) => item?.Solar),
      Target: EleData?.WeldShopKBlockN6.map((item) => item?.Target),
      GridTotal: 93585,
      SolarTotal: 23053,
      GridSolarTotal: 107409,
    },
    {
      title: "LPG",
      Grid: EleData?.PaintShopP3Data.map((item) => item?.Grid),
      Solar: EleData?.PaintShopP3Data.map((item) => item?.Solar),
      Target: EleData?.PaintShopP3Data.map((item) => item?.Target),
      GridTotal: 86081,
      SolarTotal: 14523,
      GridSolarTotal: 100604,
    },
    {
      title: "Weld Shop (K-Block N6)",
      Grid: EleData?.WeldShopKBlockN6.map((item) => item?.Grid),
      Solar: EleData?.WeldShopKBlockN6.map((item) => item?.Solar),
      Target: EleData?.WeldShopKBlockN6.map((item) => item?.Target),
      GridTotal: 93585,
      SolarTotal: 23053,
      GridSolarTotal: 107409,
    },
    {
      title: "Weld Shop (K-Block N7)",
      Grid: EleData?.WeldShopKBlockN7.map((item) => item?.Grid),
      Solar: EleData?.WeldShopKBlockN7.map((item) => item?.Solar),
      Target: EleData?.WeldShopKBlockN7.map((item) => item?.Target),
      GridTotal: 46978,
      SolarTotal: 23053,
      GridSolarTotal: 107409,
    },
    {
      title: "Weld Shop (K-Block N9)",
      Grid: EleData?.WeldShopKBlockN9.map((item) => item?.Grid),
      Solar: EleData?.WeldShopKBlockN9.map((item) => item?.Solar),
      Target: EleData?.WeldShopKBlockN9.map((item) => item?.Target),
      GridTotal: 103830,
      SolarTotal: 5527,
      GridSolarTotal: 109357,
    },
    {
      title: "X4- Weld Shop (Altroze Weld Shop)",
      Grid: EleData?.X4WeldShopAltroz.map((item) => item?.Grid),
      Solar: EleData?.X4WeldShopAltroz.map((item) => item?.Solar),
      Target: EleData?.X4WeldShopAltroz.map((item) => item?.Target),
      GridTotal: 51168,
      SolarTotal: 18093,
      GridSolarTotal: 69261,
    },

    {
      title: "Q5- Weld Shop (Harrier Weld Shop)",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 30721,
      SolarTotal: 15780,
      GridSolarTotal: 46501,
    },
    {
      title: "EV Shop",
      Grid: EleData?.EVShop.map((item) => item?.Grid),
      Solar: EleData?.EVShop.map((item) => item?.Solar),
      Target: EleData?.EVShop.map((item) => item?.Target),
      GridTotal: 5184,
      SolarTotal: 11413,
      GridSolarTotal: 16597,
    },
    {
      title: "Press Shop",
      Grid: EleData?.PressShopN5.map((item) => item?.Grid),
      Solar: EleData?.PressShopN5.map((item) => item?.Solar),
      Target: EleData?.PressShopN5.map((item) => item?.Target),
      GridTotal: 151507,
      SolarTotal: 14814,
      GridSolarTotal: 166321,
    },
    {
      title: "Comp House",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 30721,
      SolarTotal: 15780,
      GridSolarTotal: 46501,
    },
    {
      title: "Fabrication Yard",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 30721,
      SolarTotal: 15780,
      GridSolarTotal: 46501,
    },
    {
      title: "Transaxle M/C Shop (M3)",
      Grid: EleData?.TransaxleMCShopM3.map((item) => item?.Grid),
      Solar: EleData?.TransaxleMCShopM3.map((item) => item?.Solar),
      Target: EleData?.TransaxleMCShopM3.map((item) => item?.Target),
      GridTotal: 76971,

      SolarTotal: 35497,
      GridSolarTotal: 112468,
    },
    {
      title: "Transaxle M/C Shop (M6)",
      Grid: EleData?.TransaxleMCShopM6.map((item) => item?.Grid),
      Solar: EleData?.TransaxleMCShopM6.map((item) => item?.Solar),
      Target: EleData?.TransaxleMCShopM6.map((item) => item?.Target),
      GridTotal: 94965,

      SolarTotal: 16026,
      GridSolarTotal: 110991,
    },
    {
      title: "Heat Treatment (M4)",
      Grid: EleData?.HeatTreatmentM4.map((item) => item?.Grid),
      Solar: EleData?.HeatTreatmentM4.map((item) => item?.Solar),
      Target: EleData?.HeatTreatmentM4.map((item) => item?.Target),
      GridTotal: 83952,
      SolarTotal: 21981,
      GridSolarTotal: 105933,
    },
    {
      title: "Heat Treatment (M5)",
      Grid: EleData?.HeatTreatmentM5.map((item) => item?.Grid),
      Solar: EleData?.HeatTreatmentM5.map((item) => item?.Solar),
      Target: EleData?.HeatTreatmentM5.map((item) => item?.Target),
      GridTotal: 149890,
      SolarTotal: 43159,
      GridSolarTotal: 193049,
    },
    {
      title: "Eng M/C Testing & Assembly (M1)",
      Grid: EleData?.EngMCTAM1.map((item) => item?.Grid),
      Solar: EleData?.EngMCTAM1.map((item) => item?.Solar),
      Target: EleData?.EngMCTAM1.map((item) => item?.Target),
      GridTotal: 36812,
      SolarTotal: 29427,
      GridSolarTotal: 66239,
    },
    {
      title: "Eng M/C Testing & Assembly (M2)",
      Grid: EleData?.EngMCTAM2.map((item) => item?.Grid),
      Solar: EleData?.EngMCTAM2.map((item) => item?.Solar),
      Target: EleData?.EngMCTAM2.map((item) => item?.Target),
      GridTotal: 75502,
      SolarTotal: 39449,
      GridSolarTotal: 114951,
    },

    {
      title: "Eng M/C Testing & Assembly (M7)",
      Grid: EleData?.EngMCTAM7.map((item) => item?.Grid),
      Solar: EleData?.EngMCTAM7.map((item) => item?.Solar),
      Target: EleData?.EngMCTAM7.map((item) => item?.Target),
      GridTotal: 86980,
      SolarTotal: 39393,
      GridSolarTotal: 126373,
    },
    {
      title: "Eng M/C Testing & Assembly (M8)",
      Grid: EleData?.EngMCTAM8.map((item) => item?.Grid),
      Solar: EleData?.EngMCTAM8.map((item) => item?.Solar),
      Target: EleData?.EngMCTAM8.map((item) => item?.Target),
      GridTotal: 82980,
      SolarTotal: 9382,
      GridSolarTotal: 92362,
    },

    {
      title: "JLR Assembly",
      Grid: EleData?.JLRAssembly.map((item) => item?.Grid),
      Solar: EleData?.JLRAssembly.map((item) => item?.Solar),
      Target: EleData?.JLRAssembly.map((item) => item?.Target),
      GridTotal: 29391,
      SolarTotal: 53473,
      GridSolarTotal: 82864,
    },
    {
      title: "JLR Engine",
      Grid: EleData?.JLREngine.map((item) => item?.Grid),
      Solar: EleData?.JLREngine.map((item) => item?.Solar),
      Target: EleData?.JLREngine.map((item) => item?.Target),
      GridTotal: 8078,

      SolarTotal: 23571,
      GridSolarTotal: 31649,
    },
    {
      title: "Office Block (Office Block + Service Block + Canteen",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier?.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 30721,
      SolarTotal: 15780,
      GridSolarTotal: 82341,
    },
    {
      title: "Tool Engineering",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 30721,
      SolarTotal: 15780,
      GridSolarTotal: 20610,
    },
    {
      title: "Tool Consumption Of Plant (X4 + Q5 + JLR + K Block",
      Grid: EleData?.Q5WeldShopHarrier.map((item) => item?.Grid),
      Solar: EleData?.Q5WeldShopHarrier.map((item) => item?.Solar),
      Target: EleData?.Q5WeldShopHarrier.map((item) => item?.Target),
      GridTotal: 6467249,
      SolarTotal: 882386,
      GridSolarTotal: 7349635,
    },
  ];

  useEffect(() => {
    setEleData(Data?.Data);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div style={styles.MainDiv}>
      <div style={{ marginTop: "20px" }}>
        <CardsDateFilter
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          fetchData={fetchData}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container fluid style={styles.container}>
            <Row>
              {CardData1.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Meter
                              value={item?.GridSolarTotal}
                              width={280}
                              height={160}
                            />
                            <div style={styles.SolarTotal}>
                              {item?.SolarTotal} KWh
                            </div>
                            <div style={styles.GridTotal}>
                              {item?.GridTotal} KWh
                            </div>
                          </div>
                          <div>
                            <MultiAxisLineChart
                              data={{
                                labels: labels,
                                datasets: [
                                  {
                                    type: "bar",
                                    label: "Grid (kWh)",
                                    data: item?.Grid,
                                    borderColor: "#B3E5FC",
                                    backgroundColor: "#B3E5FC",
                                    yAxisID: "y",
                                  },
                                  {
                                    type: "bar",
                                    label: "Solar (kWh)",
                                    data: item?.Solar,
                                    borderColor: "#4CAF50",
                                    backgroundColor: "#4CAF50",
                                  },
                                ],
                              }}
                              width={360}
                              height={240}
                              titleX={"Target (kWh)"}
                              titleY={"Consumption (kWh)"}
                              min={0}
                              max={1000}
                              stepSize={100}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default Electricity;
