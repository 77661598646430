import React from "react";
import TrendChart from "./TrendChart";
import { Card } from "react-bootstrap";
import LineChart from "./LineChart";

const styles = {
  titleDiv: { width: "100%", marginBottom: 20 },
  title: {
    color: "#375EAD",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
const LineChartCard = ({ title, chartData }) => {
  return (
    <Card
      style={{
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "#f2f2f2",
        border: "0px",
      }}
    >
      <Card.Body>
        {/* <Card.Title>{chartData?.datasets[0]?.label}</Card.Title> */}
        <div style={styles.titleDiv}>
          <div style={styles.title}>
            {chartData?.datasets[0]?.label === "Total"
              ? "ETP INLET + STP INLET Water"
              : `${chartData?.datasets[0]?.label
                  .replaceAll("_", " ")
                  .replaceAll("water", "Water")}
                 `}
          </div>
        </div>

        <LineChart chartData={chartData} suggestedMaxProps={100} />
      </Card.Body>
    </Card>
  );
};

export default LineChartCard;
