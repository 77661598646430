import React, { createContext, useState } from "react";
import axios from "axios";
import { useAuth } from "../Context/AuthContext";
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const url = process.env.REACT_APP_URL;
  const { token } = useAuth();
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataContext.Provider
      value={{
        fetchData,
        data,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
