import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Tabs.css";
import { Button } from "react-bootstrap";
const styles = {
  activeTab: {
    borderBottom: "3px solid #375EAD",
    color: "#375EAD",
    fontWeight: "bold",
    fontSize: "14px",
  },

  deactiveTab: {
    color: "rgba(30, 30, 30, 0.53)",
    fontWeight: "600",
    fontSize: "14px",
  },
  backButton: {
    background: "transparent",
    border: "none",
    fontWeight: "bold",
    color: "#000078",
    cursor: "pointer",
    alignItems: "center",
    padding: "15px 00px",
    boxShadow: "none",
  },
};
function WaterNetTabs(props) {
  const path = useLocation();

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  console.log("Bill for", props.tabsfor);
  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "50px",
        }}
      >
        {/* <Link to="/waternetwork/processwaterline">
          <Button style={styles.backButton} onClick={handleButtonClick}>
            <img
              className="img"
              alt="Vector"
              src={require("../../Assets/arrowLeft.png")}
              height={20}
              width={20}
            />
          </Button>
        </Link> */}
        <Link to="/waternetwork/paintshop">
          <Button style={styles.backButton}>
            <img
              className="img"
              alt="Vector"
              onClick={handleBackClick}
              src={require("../../Assets/arrowLeft.png")}
              height={20}
              width={20}
            />
          </Button>
        </Link>
        {/*  */}

        {/*  */}
        <div
          style={{ color: "#375EAD", fontWeight: "600", marginLeft: "20px" }}
        >
          Paint Shop View
        </div>
      </div>
      <div
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Nav
          id="water-tabs"
          variant="tabs"
          justify
          defaultActiveKey={
            path.pathname === "/waternetwork/paintshopcxo"
              ? "/waternetwork/paintshopcxo"
              : path.pathname === "/waternetwork/paintshop"
              ? "/waternetwork/paintshop"
              : path.pathname.includes("/waternetwork/bill")
              ? `/waternetwork/bill/${props.tabsfor}`
              : null
          }
        >
          <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === "/waternetwork/paintshopcxo"
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              eventKey="/waternetwork/paintshopcxo"
              href="/waternetwork/paintshopcxo"
            >
              CXO Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === "/waternetwork/paintshop"
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              eventKey="/waternetwork/paintshop"
              href="/waternetwork/paintshop"
            >
              Water Network
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="navItem">
            <Nav.Link
              id="navLink"
              style={
                path.pathname === `/waternetwork/bill/${props.tabsfor}`
                  ? styles.activeTab
                  : styles.deactiveTab
              }
              eventKey={`/waternetwork/bill/${props.tabsfor}`}
              href={`/waternetwork/bill/${props.tabsfor}`}
            >
              Bill
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
}

export default WaterNetTabs;
