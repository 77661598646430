// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button {
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 10px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 180px;
}

.button1 {
    background-color: navy;
}

.button1:hover {
    box-shadow: 0px 0px 4px 4px #b8cdf2;
}

.button1:active {
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/User Control/styles/styles.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,yCAAyC;IACzC,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.button {\n    color: white;\n    border: none;\n    cursor: pointer;\n    font-weight: bold;\n    font-size: 18px;\n    font-family: Arial, Helvetica, sans-serif;\n    margin-right: 10px;\n    padding: 5px 5px;\n    border-radius: 4px;\n    width: 180px;\n}\n\n.button1 {\n    background-color: navy;\n}\n\n.button1:hover {\n    box-shadow: 0px 0px 4px 4px #b8cdf2;\n}\n\n.button1:active {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
