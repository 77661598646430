import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "../Layout/Tabs";
import BarChart from "../Charts/BarChart";
import WaterNetTabs from "../Water Networks/Components/WaterNetTabs";

const styles = {
  MainDiv: {
    width: "100vw",
    height: `calc(100vh - 66.66-42px)`,
  },
  card: {
    background: "#F9F9F9",
    height: "120px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card2: {
    backgroundColor: "#F9F9F9",
    height: "400px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  card3: {
    backgroundColor: "#F9F9F9",
    height: "400px",
    border: "none",
    margin: "",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  card4: {
    backgroundColor: "#F9F9F9",
    height: "300px",
    border: "none",
    margin: "",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subDiv: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
  ImgDiv: { borderRight: "solid black 3px" },
  Img: { marginRight: "30px", height: "50px", width: "50px" },
  TextDiv: { textAlign: "left", marginRight: "30px", color: "navy" },
  MainFont: { fontWeight: "bold" },
};

const PaintShopCXO = () => {
  const DetailsChart = [
    {
      data: 0.9,
      day: "Mon",
    },
    {
      data: 0.6,
      day: "Tue",
    },
    {
      data: 0.8,
      day: "Wed",
    },
    {
      data: 0.3,
      day: "Thu",
    },
    {
      data: 0.6,
      day: "Fri",
    },
    {
      data: 0.7,
      day: "Sat",
    },
  ];

  const labels = DetailsChart.map((data) => data?.day);
  const data = {
    labels: labels,
    datasets: [
      {
        data: DetailsChart.map((data) => data?.data),
        barThickness: 23,
        maxBarThickness: 23,
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };
  return (
    <div style={styles.MainDiv}>
      <Tabs />
      <WaterNetTabs />
      <Container fluid style={{ padding: "40px" }}>
        <Row>
          <Col xs={12} md={12} style={{ padding: "20px" }}>
            <Link to="/detailedcharts">
              <div className="card" style={styles.card3}>
                <BarChart
                  data={data}
                  width={900}
                  height={350}
                  titleX={"Consumption (Last 6 Month)"}
                  titleY={"Neutrality"}
                  color={"#E00000"}
                />
              </div>
            </Link>
          </Col>
          <Col xs={12} md={6} style={{ padding: "20px" }}>
            <div className="card" style={styles.card}>
              <h2 style={{ color: "#375EAD", fontWeight: "bold" }}>3500 KL</h2>
              <h5>TOTAL CONSUMPTION (Last 24 hrs)</h5>
            </div>
          </Col>

          <Col xs={12} md={6} style={{ padding: "20px" }}>
            <div className="card" style={styles.card}>
              <h2 style={{ color: "Green", fontWeight: "bold" }}>270 KL</h2>
              <h5>RECYCLED WATER (Last 24 hrs)</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    
  );
};

export default PaintShopCXO;
